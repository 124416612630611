import { IPedidoCreditoResponse } from "../../../interfaces/responses/clientes/IPedidoCreditoResponse.1"
import GridPadrao from "../GridPadrao"

interface IPedidoGridProps {
    pedidos: IPedidoCreditoResponse[],
}


export default function PedidoGrid({
                                    pedidos
                                }: IPedidoGridProps) 
{
    const colunas = [
        {
            name: '# Código',
            selector: (row: IPedidoCreditoResponse) => row['codigo'],
            sortable: true,
            wrap: true,
        },
        {
            name: 'CNPJ',
            selector: (row: IPedidoCreditoResponse) => row['cnpjCliente'],
            sortable: true,
            wrap: true
        },
        {
            name: 'Filial',
            selector: (row: IPedidoCreditoResponse) => row['nomeCliente'],
            sortable: true,
            wrap: true,
            grow: 3
        },
        {
            name: 'Data Disponibilização',
            selector: (row: IPedidoCreditoResponse) => row['dataDisponibilizacaoFormatada'],
            sortable: false,
            wrap: true,
        },
        {
        name: 'Valor',
            selector: (row: IPedidoCreditoResponse) => row['valorFormatado'],
            sortable: true,
            wrap: true,
        }
    ]

    return <>
     <GridPadrao colunas={colunas} tipo='Pedidos' itens={pedidos} />
    </>
}