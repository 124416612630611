/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { AsideMenuItem } from '../AsideMenuItem'

export function AsideMenuAdministrador() {
  return (
    <>
      <AsideMenuItem
        to='/administrador/credenciados'
        icon='/media/icons/duotune/abstract/abs006.svg'
        title="Credenciados"
        fontIcon='bi-app-indicator'
      />

      <AsideMenuItem
        to='/administrador/clientes'
        icon='/media/icons/duotune/abstract/abs006.svg'
        title="Clientes"
        fontIcon='bi-app-indicator'
      />
    
      <AsideMenuItem
        to='/administrador/pedidos'
        icon='/media/icons/duotune/abstract/abs006.svg'
        title="Pedidos"
        fontIcon='bi-app-indicator'
      />
      
      <AsideMenuItem
        to='/administrador/lotes'
        icon='/media/icons/duotune/abstract/abs006.svg'
        title="Lotes"
        fontIcon='bi-app-indicator'
      />

      <AsideMenuItem
        to='/administrador/validar/arquivo/pedido'
        icon='/media/icons/duotune/abstract/abs006.svg'
        title="Validar Arquivo Pedido"
        fontIcon='bi-app-indicator'
      />

      <AsideMenuItem
        to='/administrador/notificacoes'
        icon='/media/icons/duotune/abstract/abs006.svg'
        title="Notificações"
        fontIcon='bi-app-indicator'
      />
      
      <AsideMenuItem
        to='/administrador/grupos'
        icon='/media/icons/duotune/abstract/abs006.svg'
        title="Grupos"
        fontIcon='bi-app-indicator'
      />
      
      <AsideMenuItem
        to='/administrador/usuarios'
        icon='/media/icons/duotune/abstract/abs006.svg'
        title="Usuários"
        fontIcon='bi-app-indicator'
      />

    </>
  )
}