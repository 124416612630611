import { IPedidoCreditoDetalheResponse } from "../../../interfaces/responses/clientes/IPedidoCreditoDetalheResponse"
import { IPedidoCreditoResponse } from "../../../interfaces/responses/clientes/IPedidoCreditoResponse.1"
import GridPadrao from "../GridPadrao"

interface IPedidoDetalheGridProps {
    pedidos: IPedidoCreditoDetalheResponse[],
}


export default function PedidoDetalheGrid({
                                    pedidos
                                }: IPedidoDetalheGridProps) 
{
    const colunas = [
        {
          name: 'Nome',
          selector: (row: IPedidoCreditoDetalheResponse) => row['nome'],
          sortable: true,
          wrap: true,
        },
        {
          name: 'CPF',
          selector: (row: IPedidoCreditoDetalheResponse) => row['cpf'].replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4"),
          sortable: true,
          wrap: true,
        },
        {
            name: 'Cartão',
            selector: (row: IPedidoCreditoDetalheResponse) => row['numero'],
            sortable: true,
            wrap: true,
        },
        {
            name: 'Valor',
            selector: (row: IPedidoCreditoDetalheResponse) => row['valorFormatado'],
            sortable: true,
            wrap: true,
        }
    ]

    return <>
     <GridPadrao colunas={colunas} tipo='Pedidos Funcionários' itens={pedidos} />
    </>
}