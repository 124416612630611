import React, { ReactElement } from 'react';
import ModalHeader from './ModalHeader';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';

interface ModalContentProps {
    children: ReactElement[];
}

const ModalContent: React.FC<ModalContentProps> = ({ children }) => {
    const isCorrectOrder = 
        children.length === 3 &&
        children[0].type === ModalHeader &&
        children[1].type === ModalBody &&
        children[2].type === ModalFooter;

    if (!isCorrectOrder) {
        throw new Error("ModalContent deve conter ModalHeader, ModalBody e ModalFooter nesta ordem.");
    }

    return <div className="modal-content">{children}</div>;
};

export default ModalContent;