import React, { useEffect, useState } from "react";

import ClienteService from "../../../services/ClienteService";
import Swal from "sweetalert2";
import { Modal, Spinner } from "react-bootstrap-v5";
import GridPadrao from "../../components/GridPadrao";
import { IPedidoCreditoResponse } from "../../../interfaces/responses/clientes/IPedidoCreditoResponse.1";
import { IPedidoCreditoDetalheResponse } from "../../../interfaces/responses/clientes/IPedidoCreditoDetalheResponse";

interface IPedidoCreditoModalConfirmarEmLoteProps {
    toggleModal: () => void,
    exibirModal: boolean,
}

const PedidoCreditoModalConfirmarEmLote = ({toggleModal, exibirModal}: IPedidoCreditoModalConfirmarEmLoteProps) =>{
    useEffect(() => {
    }, [exibirModal])

    const [loadingInicial, setLoadingInicial] = useState<boolean>(false)
    const [pedidos, setPedidos] = useState<IPedidoCreditoResponse[]>([])
    const [atualizandoPedidos, setAtualizandoPedidos] = useState<boolean>(false);

    const colunas = [
        {
            name: '# Código',
            selector: (row: IPedidoCreditoResponse) => row['codigo'],
            sortable: true,
            wrap: true,
        },
        {
            name: 'CNPJ',
            selector: (row: IPedidoCreditoResponse) => row['cnpjCliente'],
            sortable: true,
            wrap: true
        },
        {
            name: 'Filial',
            selector: (row: IPedidoCreditoResponse) => row['nomeCliente'],
            sortable: true,
            wrap: true,
            grow: 3
        },
        {
            name: 'Data Disponibilização',
            selector: (row: IPedidoCreditoResponse) => row['dataDisponibilizacaoFormatada'],
            sortable: false,
            wrap: true,
        },
        {
        name: 'Valor',
            selector: (row: IPedidoCreditoResponse) => row['valorFormatado'],
            sortable: true,
            wrap: true,
        }
    ]

    function confirmarPedidos(){
        setAtualizandoPedidos(true);
        Swal.fire({
            title: 'Confirmar todos os pedidos?',
            html: `Confirmar os <strong>${pedidos.length}</strong> pedidos? Após confirmados os pedidos <span style='color: #dc3545'><strong>não podem ser alterados</strong><span>`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'VOLTAR',
            confirmButtonText: 'CONFIRMAR',
            reverseButtons: true,
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            }
          }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                  const { data  } = await ClienteService.confirmarPedidosEmLote();
                  
                  Swal.fire({
                    icon: 'success',
                    text: `${pedidos.length} pedidos confirmados com sucesso!`,
                  });
                  
                  toggleModal();

                } catch (error) {
                  Swal.fire(
                    Array.isArray(error) ? error[0] : `Não foi possivel confirmar os pedidos`
                    );
                }
                finally{
                    setAtualizandoPedidos(false);
                }
            }
            else{
                setAtualizandoPedidos(false);
            }
          })
    }
    async function carregarPedidoCreditoDetalhe(){
        try {
            setLoadingInicial(true)
            const { data  } = await ClienteService.obterPedidosCredito('', '', 'Rascunho', '');
            setPedidos(data)
          
          } catch (error) {
            Swal.fire({
              icon: 'error',
              title: 'Não foi possivel carregar os Pedidos',
              text: 'Atualize a pagina, caso o erro continue entre em contato com o administrador.',
              showConfirmButton: true,
            })
          } finally {
            setLoadingInicial(false)
          } 
    }
    return <>
         <Modal size='xl' centered={true} show={exibirModal} onHide={toggleModal} onShow={carregarPedidoCreditoDetalhe}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title titulo-modal">Pedidos em Rascunho</h5>
                    <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                        <i className="fas fa-times" style={{ color: '#5E6397' }}></i>
                    </div>
                </div>
                <div className="modal-body" >
                    <div>
                        {loadingInicial ? (
                            <Spinner animation='border' />
                        ) : (
                        <div>
                           <div>
                            {
                                pedidos.length > 0 ? 
                                (
                                    <>
                                        <button 
                                            className="btn btn-sm btn-flex btn-success btn-active-primary fw-bolder me-3"
                                            onClick={() => confirmarPedidos()}
                                            disabled={atualizandoPedidos}
                                            >
                                            <i style={{fontSize: 18, marginRight: 4}} className="bi bi-box-arrow-up"></i>
                                            {!atualizandoPedidos && <> Confirmar {pedidos.length} Pedidos </>}
                                            {atualizandoPedidos && (
                                                <span className='indicator-progress' style={{ display: 'block' }}>
                                                    Confirmando...
                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                </span>
                                            )}
                                            
                                            
                                        </button>    
                                    </>
                                ) : (<></>)
                            }
                            </div>
                            <GridPadrao colunas={colunas} tipo='Pedidos' itens={pedidos} />
                        </div>
                        )}
                    </div>
                </div>
                <div className="modal-footer" style={{ margin: '0 auto' }}>
                
                </div>
            </div>
        </Modal>
    </>
}

export default PedidoCreditoModalConfirmarEmLote;