import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap-v5";
import { IAdminCredenciado } from "../../../../interfaces/responses/administrador/IAdminCredenciado";
import GridPadrao from "../../../components/GridPadrao";
import HeaderContent from "../../../components/HeaderContent";
import { IAdminUsuario } from "../../../../interfaces/responses/administrador/IAdminUsuario";
import UsuarioGrid from "../../../components/usuario/UsuarioGrid";
import { IAdminCliente } from "../../../../interfaces/responses/administrador/IAdminCliente";
import { useLocation, useParams } from "react-router-dom";
import { PERFIS } from "../../../../utils/Constants";
import UsuarioVincularModalCliente from "./UsuarioVincularModalCliente";
import Swal from "sweetalert2";
import AdministradorService from "../../../../services/AdministradorService";
import UsuarioVincularModalCredenciado from "./UsuarioVincularModalCredenciado";
import TrocarSenhaUsuarioModal from "./UsuarioTrocarSenhaModal";


type Params = {
  idUsuario: string; 
};

export function UsuarioDetalhe() {
    const [exibirModalVincularCliente, SetExibirModalVincularCliente] = useState<boolean>(false)
    const [exibirModalVincularCredenciado, SetExibirModalVincularCredenciado] = useState<boolean>(false)
    const [loadingInicial, setLoadingInicial] = useState<boolean>(false)
    const [usuarioAtual, setUsuarioAtual] = useState<IAdminUsuario>({} as IAdminUsuario);
    const [exibirModalUsuarioSenha, setExibirModalUsuarioSenha] = useState<boolean>(false)

    let { idUsuario } = useParams<Params>();

    function toggleModalVincularCliente(refresh: boolean): void {
        SetExibirModalVincularCliente(!exibirModalVincularCliente);
        if(refresh){
          carregarUsuario();
        }
    }

    useEffect(() => {
      carregarUsuario();
    }, [idUsuario])

    async function carregarUsuario() {
      try {
        
        setLoadingInicial(true)
        
        const { data } = await AdministradorService.obterUsuarioPorId(idUsuario)
    
        setUsuarioAtual(data)
    
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Não foi possivel carregar os usuários',
          text: 'Atualize a pagina, caso o erro continue entre em contato com o administrador.',
          showConfirmButton: true,
        })
      } finally {
        setLoadingInicial(false)
      } 
    }

    function toggleModalVincularCredenciado(refresh: boolean){
        SetExibirModalVincularCredenciado(!exibirModalVincularCredenciado)
        if(refresh){
          carregarUsuario();
        }
    }
    const colunasCliente = [
        {
          name: 'CNPJ',
          selector: (row: IAdminCliente) => row['cnpj'],
          sortable: false,
          wrap: true,
          grow: 2
        },
        {
          name: 'Nome',
          selector: (row: IAdminCliente) => row['nome'],
          sortable: false,
          wrap: true,
          grow: 2
        },
        {
          name: 'Nome Fantasia',
          selector: (row: IAdminCliente) => row['nomeFantasia'],
          sortable: false,
          wrap: true,
          grow: 2
        },
        {
            name: 'Grupo',
            selector: (row: IAdminCliente) => row['nomeGrupoEmpresarial'],
            sortable: false,
            wrap: true,
            grow: 2
          },
          {
            name: 'Ativo',
            selector: (row: IAdminCliente) => row['ativo'] ? 'SIM' : 'NÃO',
            sortable: false,
            wrap: true,
            grow: 2
          }
    ]

    const colunasCredenciado = [
        {
          name: 'CNPJ',
          selector: (row: IAdminCredenciado) => row['cnpj'],
          sortable: false,
          wrap: true,
          grow: 2
        },
        {
          name: 'Nome',
          selector: (row: IAdminCredenciado) => row['nome'],
          sortable: false,
          wrap: true,
          grow: 2
        },
        {
          name: 'Nome Fantasia',
          selector: (row: IAdminCredenciado) => row['nomeFantasia'],
          sortable: false,
          wrap: true,
          grow: 2
        },
        {
            name: 'Grupo',
            selector: (row: IAdminCredenciado) => row['nomeGrupoEmpresarial'],
            sortable: false,
            wrap: true,
            grow: 2
          },
          {
            name: 'Ativo',
            selector: (row: IAdminCredenciado) => row['ativo'] ? 'SIM' : 'NÃO',
            sortable: false,
            wrap: true,
            grow: 2
          }
    ]    

    function exibirModalVincular(){
        if(usuarioAtual.perfil === PERFIS.CLIENTE){
            SetExibirModalVincularCliente(true)
        }
        if(usuarioAtual.perfil === PERFIS.CREDENCIADO){
            SetExibirModalVincularCredenciado(true)
        }
    }

     
  function toggleModalUsuarioSenha(): void {
    setExibirModalUsuarioSenha(!exibirModalUsuarioSenha);
  }

    
  return (
    <>
       <UsuarioVincularModalCliente
          exibirModal={exibirModalVincularCliente}
          perfil={usuarioAtual.perfil}
          usuario={usuarioAtual}
          toggleModal={toggleModalVincularCliente}
        />
      
      <UsuarioVincularModalCredenciado
          exibirModal={exibirModalVincularCredenciado}
          perfil={usuarioAtual.perfil}
          usuario={usuarioAtual}
          toggleModal={toggleModalVincularCredenciado}
        />

      <TrocarSenhaUsuarioModal 
            usuario={usuarioAtual}
            exibirModal={exibirModalUsuarioSenha}
            toggleModal={toggleModalUsuarioSenha}
        />

      <HeaderContent title="Usuário Detalhe" >
        <button onClick={() => {
            exibirModalVincular()
          }}  className='btn btn-sm btn-primary me-2'>
            <i className="icon-lg text-white fas fa-plus"></i>
            <span style={{ verticalAlign: 'middle' }}> Vincular {usuarioAtual.perfil}</span>
        </button>  
        
        <button onClick={() => {
            toggleModalUsuarioSenha()
          }}  className='btn btn-sm btn-primary me-2'>
            <i className="icon-lg text-white bi bi-lock-fill"></i>
            <span style={{ verticalAlign: 'middle' }}> Trocar Senha</span>
        </button>  
      </HeaderContent>
        
        {loadingInicial ? (
          <Spinner animation='border' />
        ) : (
          <div>
            <UsuarioGrid 
                exibirBotaoDetalhe={false}
                exibirTitulo={true}
                usuarios={[usuarioAtual]}
            />
            {
                        usuarioAtual.clientes && usuarioAtual.clientes.length > 0 &&
                        <>
                            <h2 className="mt-4">Clientes Vinculados</h2>
                            <GridPadrao colunas={colunasCliente} tipo='Clientes' itens={usuarioAtual.clientes} />
                        </>
                    }
                    {
                        usuarioAtual.credenciados && usuarioAtual.credenciados.length > 0 && 
                        <>
                           <h2 className="mt-4">Credenciados Vinculados</h2>
                            <GridPadrao colunas={colunasCredenciado} tipo='Credenciados' itens={usuarioAtual.credenciados} />
                        </>
                    }
          </div>
        )}
    
    </>
  )
}
