import { useFormik } from "formik";
import React, { useState } from "react";
import { Spinner } from "react-bootstrap-v5";
import Swal from "sweetalert2";
import GridPadrao from "../../components/GridPadrao";
import HeaderContent from "../../components/HeaderContent";
import InputDatePicker from "../../components/InputDatePicker";
import * as Yup from 'yup';
import clsx from "clsx";
import * as XLSX from 'xlsx'
import PainelFiltro from "../../components/PainelFiltro";
import { IRelatorioMovimentoCredenciadoResponse } from "../../../interfaces/responses/clientes/IRelatorioMovimentoCredenciadoResponse";
import { IRelatorioMovimentoClienteResponse } from "../../../interfaces/responses/clientes/IRelatorioMovimentoClienteResponse";
import { IClienteRelatorioMovimentoResponse } from "../../../interfaces/responses/clientes/IClienteRelatorioMovimentoResponse";
import ClienteService from "../../../services/ClienteService";
import CardTotalizador from "../../components/CardTotalizador";

export function RelatorioUtilizacao(){
    const [movimentoRelatorioBase, setMovimentosRelatorioBase] = useState<IClienteRelatorioMovimentoResponse>({ cliente: [], credenciado: [], totalizadores: []} as IClienteRelatorioMovimentoResponse)
    const [loadingInicial, setLoadingInicial] = useState<boolean>(false)
    const [exportarCSV, setExportarCSV] = useState<boolean>(false)
    
    const valoresIniciaisFiltro = {
        dataInicio: '',
        dataFim: '',
        tipo: 'Cliente',
      } as any;
    
      const filtroValidacoes = Yup.object().shape({
        dataInicio: Yup.date().required('Data Inicio é obrigatória').test('validacao-range', 'Data Inicio deve ser menor que o Data Fim', async (value) => {
            if (!value || !formikFiltro.values.dataFim) {
                return true;
            }

            if (value.getTime() <= formikFiltro.values.dataFim.getTime()) {
                return true;
            }

            return false;
        }),
        dataFim: Yup.date().required('Data Fim é obrigatória').test('validacao-range', 'Data Fim deve ser maior que o Data Inicio', async (value) => {
            if (!formikFiltro.values.dataInicio || !value) {
                return true;
            }

            if (formikFiltro.values.dataInicio.getTime() <= value.getTime()) {
                return true;
            }

            return false;
        })
    })

    const formikFiltro = useFormik({
        initialValues: valoresIniciaisFiltro,
        validationSchema: filtroValidacoes,
        onSubmit: async ({dataInicio, dataFim}) => {
          try {
            
            setLoadingInicial(true)
            
            const { data } = await ClienteService.relatorioUtilizacaoPorPeriodo(new Date(dataInicio).toLocaleDateString('pt-br'), new Date(dataFim).toLocaleDateString('pt-br'));
            
            setMovimentosRelatorioBase(data);
            setLoadingInicial(false);
            setExportarCSV(true)
          } catch (error) {
                Swal.fire(
                    Array.isArray(error) ? error[0] : `Não foi possivel carregar o relatório`
                );
          } finally {
            setLoadingInicial(false)
          } 
        }
      });
      
    const colunasCliente = [
        {
            name: 'Filial',
            selector: (row: IRelatorioMovimentoClienteResponse) => row['descricao'],
            sortable: true,
            wrap: true,
            grow: 2
        },
        {
            name: 'CNPJ',
            selector: (row: IRelatorioMovimentoClienteResponse) => row['cnpj'].replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5"),
            sortable: true,
            wrap: true,
        },
        {
            name: 'Data Último Crédito',
            selector: (row: IRelatorioMovimentoClienteResponse) => row['dataUltimoPedidoFormatado'],
            sortable: true,
            wrap: true,
        },
        {
            name: 'Valor Último Crédito',
            selector: (row: IRelatorioMovimentoClienteResponse) => row['valorUltimoPedidoFormatado'],
            sortable: true,
            wrap: true,
        },
        {
            name: 'Saldo Cartão',
            selector: (row: IRelatorioMovimentoClienteResponse) => row['saldoCartaoFormatado'],
            sortable: true,
            wrap: true,
        },
        {
            name: 'Valor Utilizado',
            selector: (row: IRelatorioMovimentoClienteResponse) => row['valorFormatado'],
            sortable: true,
            wrap: true,
        },
        {
            name: 'Percentual Utilizado',
            selector: (row: IRelatorioMovimentoClienteResponse) => row['percentualUtilizacaoFormatado']+'%',
            sortable: true,
            wrap: true,
        }
    ]

    const colunasCredenciado = [
        {
            name: 'Credenciado',
            selector: (row: IRelatorioMovimentoCredenciadoResponse) => row['descricao'],
            sortable: true,
            wrap: true,
            grow: 2
        },
        {
            name: 'CNPJ',
            selector: (row: IRelatorioMovimentoCredenciadoResponse) => row['cnpj'].replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5"),
            sortable: true,
            wrap: true,
        },
        {
            name: 'Valor',
            selector: (row: IRelatorioMovimentoCredenciadoResponse) => row['valorFormatado'],
            sortable: true,
            wrap: true,
        }
    ]

    function DownloadFile(){
        let dataCliente = movimentoRelatorioBase.cliente;
        let dataCredenciado = movimentoRelatorioBase.credenciado;
        let dataParaPlanilha: any[] = [];
       
        if(formikFiltro.values.tipo === 'Cliente'){
            dataCliente.forEach(item => {
                dataParaPlanilha.push({
                    Filial: item.descricao,
                    Cnpj: item.cnpj,
                    DataUltimoCredito: item.dataUltimoPedidoFormatado,
                    ValorUltimoCredito: item.valorUltimoPedido,
                    SaldoCartao: item.saldoCartao,
                    ValorUtilizado: item.valor,
                    Percentual: item.percentualUtilizacao
                })
            });
        }

        if(formikFiltro.values.tipo === 'Credenciado'){
            dataCredenciado.forEach(item => {
                dataParaPlanilha.push({
                    Credenciado: item.descricao,
                    Cnpj: item.cnpj,
                    ValorUtilizado: item.valor,
                })
            });
        }

        let worksheet = XLSX.utils.json_to_sheet(dataParaPlanilha);
        let workbook = XLSX.utils.book_new();
        let fileName = `relatorio-utilizacao-
                        ${formikFiltro.values.tipo.toLowerCase()}-
                        ${new Date(formikFiltro.values.dataInicio).toLocaleDateString('pt-br')}-
                        ${new Date(formikFiltro.values.dataFim).toLocaleDateString('pt-br')}
                        .ods`;

        XLSX.utils.book_append_sheet(workbook, worksheet, "relatorio-utilizacao");

        XLSX.writeFile(workbook, fileName, { bookType: "ods" });
    }

    return (
        <>
            <HeaderContent title={"Relatório de Utilização"} />
            <PainelFiltro>
                {
                    <>
                        <div className="col col-md-2">
                            <label className='form-label fw-bold required'>Data Inicio</label>
                            <InputDatePicker selected={formikFiltro.values.dataInicio} onChange={async (date: any) => {
                                setExportarCSV(false)
                                if (!date) {
                                    await formikFiltro.setFieldValue('dataInicio', undefined);
                                } else {
                                    await formikFiltro.setFieldValue('dataInicio', date);
                                }

                                await formikFiltro.setFieldTouched('dataInicio');

                            }} className={clsx(
                                'form-control',

                                {

                                    'is-invalid': formikFiltro.touched.dataInicio && formikFiltro.errors.dataInicio,

                                },

                                {

                                    'is-valid': formikFiltro.touched.dataInicio && !formikFiltro.errors.dataInicio,

                                }

                            )} id="form-data-inicio" />
                            {formikFiltro.touched.dataInicio && formikFiltro.errors.dataInicio && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formikFiltro.errors.dataInicio}</span>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="col col-md-2">
                        <label className='form-label fw-bold required'>Data Fim</label>
                            <InputDatePicker selected={formikFiltro.values.dataFim} onChange={async (date: any) => {
                                    setExportarCSV(false)
                                    if (!date) {
                                        await formikFiltro.setFieldValue('dataFim', undefined);
                                    } else {
                                        await formikFiltro.setFieldValue('dataFim', date);
                                    }

                                    await formikFiltro.setFieldTouched('dataFim');

                                }}  className={clsx(
                                    'form-control',
    
                                    {
    
                                        'is-invalid': formikFiltro.touched.dataFim && formikFiltro.errors.dataFim,
    
                                    },
    
                                    {
    
                                        'is-valid': formikFiltro.touched.dataFim && !formikFiltro.errors.dataFim,
    
                                    }
    
                                )} id="form-data-fim" />
                                {formikFiltro.touched.dataFim && formikFiltro.errors.dataFim && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formikFiltro.errors.dataFim}</span>
                                        </div>
                                    </div>
                                )}
                        </div>

                        <div className="col col-md-2">
                            <label htmlFor="form-tipo" className="form-label">Por</label>
                            <select 
                                className="form-select" 
                                aria-label="form-tipo"
                                name="tipo"
                                value={formikFiltro.values.tipo}
                                onChange={formikFiltro.handleChange}
                                onBlur={formikFiltro.handleBlur}
                            >
                                <option value='Cliente' label="Cliente" />
                                <option value='Credenciado' label="Credenciado" />
                            </select>
                        </div>
                    </>
                }
                {
                    <>
                        <button data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                            data-kt-menu-flip='top-end'
                            className='btn btn-sm btn-flex btn-primary btn-active-primary fw-bolder me-3'
                            onClick={formikFiltro.submitForm}
                            disabled={formikFiltro.isSubmitting || !formikFiltro.isValid}>
                            <i className="icon-lg fa fa-filter"></i>
                            Filtrar
                        </button>
                        {movimentoRelatorioBase.cliente.length > 0  && exportarCSV &&
                            <button 
                                className='btn btn-sm btn-icon btn-secondary my-1'
                                onClick={() => DownloadFile()}
                            >
                                <i style={{fontSize: 24}} className="bi bi-printer-fill">
                            </i></button>
                        }
                    </>
                }
            </PainelFiltro>
          {loadingInicial ? (
                    <Spinner animation='border' />
                ) : (
                    <>
                        <div className="row mb-2">
                            <CardTotalizador
                                totalizadores={movimentoRelatorioBase?.totalizadores}
                            />
                        </div>
                        <GridPadrao 
                            colunas={formikFiltro.values.tipo === 'Cliente' ? colunasCliente : colunasCredenciado} 
                            tipo='Movimentos' 
                            itens={formikFiltro.values.tipo === 'Cliente' ? movimentoRelatorioBase.cliente : movimentoRelatorioBase.credenciado}  
                        />
                    </>
                )
            }
        </>
    )
}
