/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { AsideMenuItem } from '../AsideMenuItem'

export function AsideMenuCliente() {
  return (
    <>
      <AsideMenuItem
        to='/cliente/funcionarios'
        icon='/media/icons/duotune/abstract/abs006.svg'
        title="Funcionarios"
        fontIcon='bi-app-indicator'
      />

      <AsideMenuItem
        to='/cliente/pedidos-credito'
        icon='/media/icons/duotune/abstract/abs006.svg'
        title="Pedido de Crédito"
        fontIcon='bi-app-indicator'
      />

      <AsideMenuItem
        to='/cliente/lotes-cartao'
        icon='/media/icons/duotune/abstract/abs006.svg'
        title="Emissão de Cartões"
        fontIcon='bi-app-indicator'
      />

      <AsideMenuItem
        to='/relatorio-utilizacao'
        icon='/media/icons/duotune/abstract/abs006.svg'
        title="Relatório Utilização"
        fontIcon='bi-app-indicator'
      />

      <AsideMenuItem
        to='/relatorio-recarga'
        icon='/media/icons/duotune/abstract/abs006.svg'
        title="Relatorio Recarga"
        fontIcon='bi-app-indicator'
      />

      <AsideMenuItem
        to='/cliente-credenciado'
        icon='/media/icons/duotune/abstract/abs006.svg'
        title="Rede Credenciada"
        fontIcon='bi-app-indicator'
      />
    </>
  )
}