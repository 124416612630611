import React, { useEffect, useState } from "react";
import { IAdminUsuario } from "../../../../interfaces/responses/administrador/IAdminUsuario";
import { Modal, Spinner } from "react-bootstrap-v5";
import GridPadrao from "../../../components/GridPadrao";
import { IAdminCliente } from "../../../../interfaces/responses/administrador/IAdminCliente";
import AdministradorService from "../../../../services/AdministradorService";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import { IAdminVincularUsuarioClientes } from "../../../../interfaces/request/administrador/IAdminVincularUsuarioClientes";
import { IAdminCredenciado } from "../../../../interfaces/responses/administrador/IAdminCredenciado";
import { IAdminVincularUsuarioCredenciados } from "../../../../interfaces/request/administrador/IAdminVincularUsuarioCredenciados";


interface UsuarioVincularProps {
    toggleModal: (refresh: boolean) => void,
    exibirModal: boolean,
    usuario: IAdminUsuario,
    perfil: string
}

const UsuarioVincularModalCliente = ({ toggleModal, exibirModal, usuario, perfil }: UsuarioVincularProps) =>{
    const [todosCredenciados, setCredenciados] = useState<IAdminCredenciado[]>([]);
    const [credenciadosFiltrados, setCredenciadosFiltrados] = useState<IAdminCredenciado[]>([]);
    const [loading, setLoading] = useState(true);
    const [selecionados, setSelecionados] = useState<IAdminCredenciado[]>([]);

    useEffect(() => {
        async function carregarDados() {
            try {
                setLoading(true);
                const credenciados = await buscarCredenciados();
                const credenciadosUsuario = usuario.credenciados
          
                setCredenciados(credenciados);    
                setCredenciadosFiltrados(credenciados);            
                setSelecionados(credenciadosUsuario);

              } catch (error) {
                Swal.fire(
                    Array.isArray(error) ? error[0] : `Erro ao carregar dados`
                );
              } finally {
                setLoading(false);
              }
            }
          
            if (exibirModal) {
              carregarDados();
            }
    
    }, [exibirModal, usuario]);
    

    function toggleSelecionarTodos() {
        if (selecionados.length !== todosCredenciados.length) {
          setSelecionados(credenciadosFiltrados);
        } else {
          setSelecionados([]);
        }
    }

    async function buscarCredenciados(){
      const { data } = await AdministradorService.obterCredenciados('', '', '');
      
      return data;
    }

    function handleCheckboxChange(e: React.ChangeEvent<HTMLInputElement>, credenciado: IAdminCredenciado){
        if (e.target.checked) {
          setSelecionados([...selecionados, credenciado]);
        } else {
          setSelecionados(selecionados.filter((item) => item.id !== credenciado.id));
        }
    };
      
     const colunas = [
        {
          name: '',
          cell: (credenciado: IAdminCredenciado) => (
            <div className="form-check form-check-custom form-check-solid">
              <input
                className="form-check-input"
                type="checkbox"
                value={credenciado.id}
                onChange={(e) => handleCheckboxChange(e, credenciado)}
                checked={selecionados.some((selecionado) => selecionado.id === credenciado.id)}
              />
            </div>
          ),
        },
        {
          name: 'Nome',
          selector: (row: IAdminCliente) => row.nome,
          sortable: true,
          wrap: true,
          grow: 3,
        },
        {
            name: 'Fantasia',
            selector: (row: IAdminCliente) => row.nomeFantasia,
            sortable: true,
            wrap: true,
            grow: 3,
          },
        {
          name: 'CNPJ',
          selector: (row: IAdminCliente) => row.cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5"),
          sortable: true,
          wrap: true,
          grow: 2,
        },
        {
          name: 'Cidade',
          selector: (row: IAdminCliente) => row.cidade,
          sortable: true,
          wrap: true,
          grow: 2,
        },
        {
          name: 'Grupo',
          selector: (row: IAdminCliente) => row.nomeGrupoEmpresarial,
          sortable: true,
          wrap: true,
          grow: 2,
        },
     ];

     const formik = useFormik({
         initialValues: {},
         onSubmit: async (values) => {
             try {
                let credenciados = {
                    idUsuario: usuario.id,
                    idsCredenciados: selecionados.map(c => c.id)
                } as IAdminVincularUsuarioCredenciados;

                await AdministradorService.vincularUsuarioCredenciados(usuario.id, credenciados);

                Swal.fire({
                    icon: 'success',
                    text: `Operação realizada com sucesso`,
                    showConfirmButton: false,
                    timer: 3000
                })

                toggleModal(true);
             } catch (error) {
 
                 Swal.fire(
                     Array.isArray(error) ? error[0] : `Não foi possivel vincular os clientes`
                 );
             }
         }
     });
    return <>
         <Modal size='xl' centered={true} show={exibirModal} onHide={toggleModal}>
             <div className="modal-content">
                 <div className="modal-header">
                     <h5 className="modal-title titulo-modal">Vincular Credenciado</h5>
                     <div onClick={() => toggleModal(false)} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                         <i className="fas fa-times" style={{ color: '#5E6397' }}></i>
                     </div>
                 </div>
                 <div className="modal-body">
                    
                    {loading ? (
                    <Spinner animation='border' />
                    ) : (
                        <>
                        <button onClick={toggleSelecionarTodos} className="btn btn-primary">
                            Selecionar todos
                        </button>
                        <div className="col col-md-4 mt-2">
                          <label className='form-label fw-bold'>Filtrar</label>
                          <input onChange={async (e) =>{ 
                                   const valorFiltro = e.target.value;
                                   if (!valorFiltro) {
                                       // Se o campo de filtro estiver vazio, resetamos a lista filtrada para a lista original.
                                       setCredenciadosFiltrados(todosCredenciados);
                                   } else {
                                       // Caso contrário, filtramos a lista original e armazenamos o resultado na lista filtrada.
                                       const novaListaFiltrada = todosCredenciados.filter(item =>
                                           item.nome.toLowerCase().includes(valorFiltro.toLowerCase()) ||
                                           item.cnpj.toLowerCase().includes(valorFiltro.toLowerCase()) ||
                                           item.cidade?.toLowerCase().includes(valorFiltro.toLowerCase()) ||
                                           item.nomeGrupoEmpresarial?.toLowerCase().includes(valorFiltro.toLowerCase())
                                       );
                                       setCredenciadosFiltrados(novaListaFiltrada);
                                   }
                              }} 
                              // value={}
                              name="filtro" 
                              type="text"
                              placeholder="Busque por Nome, CNPJ, Cidade ou Grupo"
                              className="form-control"
                              />

                        </div>
                        <form className="row g-3">
                            <GridPadrao colunas={colunas} tipo='Clientes' itens={credenciadosFiltrados} />
                        </form>
                        </>
                    )}
               
                </div>
                 <div className="modal-footer" style={{ margin: '0 auto' }}>
                     <button disabled={formik.isSubmitting} onClick={() => toggleModal(false)} type="button" className="btn btn-danger me-5"> CANCELAR </button>
                     <button disabled={formik.isSubmitting || selecionados.length == 0} onClick={() => { formik.submitForm() }} type="button" className="btn btn-primary ms-5">
                         {!formik.isSubmitting && <> CONFIRMAR ({selecionados.length})</>}
                         {formik.isSubmitting && (
                             <span className='indicator-progress' style={{ display: 'block' }}>
                                 Salvando...
                                 <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                             </span>
                         )}
                     </button>
                 </div>
             </div>
         </Modal>
     </>
}

export default UsuarioVincularModalCliente;