import React, { useState, ChangeEvent, useEffect } from "react"
import { InputGroup, Modal, Spinner, Table } from "react-bootstrap-v5";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from "clsx";
import GridPadrao from "../../components/GridPadrao";

import ClienteService from "../../../services/ClienteService";
import Swal from "sweetalert2";
import ModalAltearValorFuncionario from "./ModalAltearValorFuncionario";
import ModalAdiconarFuncionario from "./ModalAdicionarFuncionario";
import InputMonetario from "../../components/InputMonetario";
import InputDatePicker from "../../components/InputDatePicker";
import { ICriarPedidoCreditoDetalhe } from "../../../interfaces/request/clientes/ICriarPedidoCreditoDetalhe";
import { ICriarPedidoCredito, IEditarPedidoCredito } from "../../../interfaces/request/clientes/ICriarPedidoCredito";
import { IPedidoCreditoPainel } from "../../../interfaces/responses/clientes/IPedidoCreditoPainel";
import { IPedidoCreditoResponse } from "../../../interfaces/responses/clientes/IPedidoCreditoResponse.1";
import { ICartaoResponse } from "../../../interfaces/responses/clientes/ICartaoResponse";
import { useAuth } from "../../../contexts/AuthProvider";

interface IPedidoCreditoCriarModalProps {
    toggleModal: () => void,
    exibirModal: boolean,
    pedidoCreditoParaEdicao: IPedidoCreditoResponse
}

const PedidoCreditoModalEditar = ({ toggleModal, exibirModal, pedidoCreditoParaEdicao }: IPedidoCreditoCriarModalProps) => {
    const [listaPedidoCreditoPainel, setListaPedidoCreditoPainel] = useState<IPedidoCreditoPainel[]>([])
    const [pedidoCreditoPainelSelecionado, setPedidoCredito] = useState<IPedidoCreditoPainel>({} as IPedidoCreditoPainel)
    const [exibirModalAlterarValor, setExibirModalAlterarValor] = useState<boolean>(false)
    const [exibirModalAdicionarFuncionario, setExibirModalAdicionarFuncionario] = useState<boolean>(false)
    const [loadingInicial, setLoadingInicial] = useState<boolean>(false)
    const { clientes } = useAuth();
    
    useEffect(() => {
        // eslint-disable-next-line
    }, [pedidoCreditoParaEdicao]);

    function removerFuncionario(pedidoCredito: IPedidoCreditoPainel){
        Swal.fire({
            title: 'Remover funcionário?',
            text: `Remover ${pedidoCredito.nome} do pedido?`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'CANCELAR',
            confirmButtonText: 'EXCLUIR',
            reverseButtons: true,
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            }
          }).then((result) => {
            if (result.isConfirmed) {
                const temp = [...listaPedidoCreditoPainel];

                var index = temp.findIndex(c=> c.idFuncionario === pedidoCredito.idFuncionario);
        
                temp.splice(index, 1);
        
                setListaPedidoCreditoPainel(temp)
            }
        })
    }

    const colunas = [
        {
          name: 'Nome',
          selector: (row: IPedidoCreditoPainel) => row['nome'],
          sortable: true,
          wrap: true,
        },
        {
          name: 'CPF',
          selector: (row: IPedidoCreditoPainel) => row['cpf'].replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4"),
          sortable: true,
          wrap: true,
        },
        {
            name: 'Cartão',
            selector: (row: IPedidoCreditoPainel) => row['numero'],
            sortable: true,
            wrap: true,
        },
        {
            name: 'Valor',
            selector: (row: IPedidoCreditoPainel) => row['valor'].toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}),
            sortable: true,
            wrap: true,
        },
        {
            name: 'Ações',
            cell: (funcionario: IPedidoCreditoPainel) => {
              return (
                <>
                    <div className="py-2">
                    <button
                        title="alterar Valor"
                        className='btn btn-sm btn-icon btn-secondary me-2'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                        onClick={() => {
                            setPedidoCredito(funcionario);
                            setExibirModalAlterarValor(true);
                        }}
                    >
                        <i style={{fontSize: 24}} className="bi bi-pencil-square"></i>
                    </button>
                    </div>
                    <div className="">
                    <button
                        title="remover funcionário"
                        className='btn btn-sm btn-icon btn-secondary'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                        onClick={() => {
                            removerFuncionario(funcionario)
                        }}
                    >
                        <i style={{fontSize: 24}} className="bi bi-trash-fill"></i>
                    </button>
                </div>
              </>
            )
            },
          }        
    ]

    function toggleModalAlterarValorFuncionario(): void {
        setExibirModalAlterarValor(!exibirModalAlterarValor);
    }

    function toggleModalAdicionarFuncionario(): void {
        setExibirModalAdicionarFuncionario(!exibirModalAdicionarFuncionario);
    }

    function adicionarFuncionario(pedidoCredito: IPedidoCreditoPainel){
        if(funcionarioExiste(pedidoCredito)){
            Swal.fire({
                        icon: 'warning',
                        text: `Funcionário já adicionado`,
                        showConfirmButton: false,
                        timer: 3000
                    });
            return;
        }

        const temp = [...listaPedidoCreditoPainel];
        temp.push(pedidoCredito);
        setListaPedidoCreditoPainel(temp);
    }

    function funcionarioExiste(pedidoCredito : IPedidoCreditoPainel){
        let pedidoCreditoAdicionado = listaPedidoCreditoPainel.find(c => c.idFuncionario === pedidoCredito.idFuncionario);
        if(pedidoCreditoAdicionado)
            return true;
        
            return false;
    }

    function valorTotalPedido(){
        let valor = 0;
        listaPedidoCreditoPainel.forEach(element => {
            valor += element.valor
        });

        return valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
    }

    const alterarValorValidacoes = Yup.object().shape({        
        valor: Yup.number()
                    .min(0, 'Valor deve ser maior ou igual a 0')
                    .required('Valor é obrigatório'),

    })
    
    const valoresIniciais = {
        valor: 0,
    } as any;

    const formik = useFormik({
        initialValues: valoresIniciais,
        validationSchema: alterarValorValidacoes,
        onSubmit: (values) => {
            listaPedidoCreditoPainel.forEach(pedidoCreditoPainel => {
                pedidoCreditoPainel.valor = values.valor
            });
            Swal.fire({
                        icon: 'success',
                        text: `Valor aplicado para todos`,
                        showConfirmButton: false,
                        timer: 2000
                    })
            formik.resetForm();
        }
    });

    const pedidoCreditoValidacoes = Yup.object().shape({
        dataDisponibilizacao: Yup.date().required('Data disponibilização é obrigatória'),
    })

    const pedidoCreditoValoresIniciais = {
        dataDisponibilizacao: undefined
    } as any;

    const formikPedidoCredito = useFormik({
        initialValues: pedidoCreditoValoresIniciais,
        validationSchema: pedidoCreditoValidacoes,
        onSubmit: async (values) => {
            try {
                let valorTotal = 0;
                let pedidoCreditoDetalhe: ICriarPedidoCreditoDetalhe[] = [];
                listaPedidoCreditoPainel.forEach(element => {
                    valorTotal += element.valor
                    pedidoCreditoDetalhe.push({
                        idCartao: element.idCartao,
                        idFuncionario: element.idFuncionario,
                        valor: element.valor
                    } as ICriarPedidoCreditoDetalhe)
                });

                
                let pedidoCredito = {
                    id: pedidoCreditoParaEdicao.id,
                    idCliente: pedidoCreditoParaEdicao.idCliente,
                    dataDisponibilizacao: values.dataDisponibilizacao,
                    valor: valorTotal,
                    pedidoCreditoDetalhe: pedidoCreditoDetalhe
                } as IEditarPedidoCredito
                
                await ClienteService.editarPedidoCredito(pedidoCreditoParaEdicao.idCliente, pedidoCredito)

                Swal.fire({
                            icon: 'success',
                            text: `Pedido de crédito cadastrado`,
                            showConfirmButton: true,
                        })
                
                LimparEFecharModal()
                    
            } catch (error) {
                Swal.fire(
                    Array.isArray(error) ? error[0] : `Não foi possivel cadastrar o Pedido de crédito`
                    );
            }
        }
    });

    function closeModal(){
        if(listaPedidoCreditoPainel.length === 0){
            LimparEFecharModal()
            return;
        }

        Swal.fire({
            title: 'Deseja fechar a tela de pedido?',
            text: `Ao sair você perderá todas as alterações pendentes`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'CONTINUAR PEDIDO',
            confirmButtonText: 'SAIR',
            reverseButtons: true,
            customClass: {
                confirmButton: 'btn btn-danger',
                cancelButton: 'btn btn-primary'
            }
            
          }).then((result) => {
            if (result.isConfirmed) {
                LimparEFecharModal()
            }
        })
    }

    function LimparEFecharModal(){
        setListaPedidoCreditoPainel([]);
        formik.resetForm();
        formikPedidoCredito.resetForm();
        toggleModal();
    }

    async function carregarPedidoCreditoDetalhes(){
        
        if (pedidoCreditoParaEdicao.id !== undefined) {
            try {
                setLoadingInicial(true);
                let { data } = await ClienteService.obterPedidoCreditoDetalhes(pedidoCreditoParaEdicao.idCliente, pedidoCreditoParaEdicao.id);;
                let pedidoCreditoDetalhes = data;
                let pedidoCreditoPainel: IPedidoCreditoPainel[] = [];

                pedidoCreditoDetalhes.forEach(item => {
                    pedidoCreditoPainel.push({
                        idFuncionario: item.idFuncionario,
                        cpf: item.cpf,
                        nome: item.nome,
                        valor: item.valor,
                        numero: item.numero,
                        idCartao: item.idCartao
                    } as IPedidoCreditoPainel);
                });

                await formikPedidoCredito.setFieldValue('dataDisponibilizacao', new Date(pedidoCreditoParaEdicao.dataDisponibilizacao));

                setListaPedidoCreditoPainel(pedidoCreditoPainel);   

            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Não foi possivel carregar os Funcionários',
                    text: 'Atualize a pagina, caso o erro continue entre em contato com o administrador.',
                    showConfirmButton: true,
                  })
            } finally {
                setLoadingInicial(false)
            }
        }
    }

    function obterDescricaoCliente(){
        return clientes?.find(c => c.id == pedidoCreditoParaEdicao.idCliente)?.descricao;
    }
    return <>
        <ModalAltearValorFuncionario 
            toggleModal={toggleModalAlterarValorFuncionario}
            pedidoCreditoPainel={pedidoCreditoPainelSelecionado}
            exibirModal={exibirModalAlterarValor}
        />

        <ModalAdiconarFuncionario 
            exibirModal={exibirModalAdicionarFuncionario}
            idCliente={pedidoCreditoParaEdicao.idCliente}
            toggleModal={toggleModalAdicionarFuncionario}
            adicionar={adicionarFuncionario} 
            existe={funcionarioExiste}
        />
        <Modal  fullscreen centered={true} show={exibirModal} onHide={closeModal} onShow={carregarPedidoCreditoDetalhes}>
            <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title titulo-modal">{obterDescricaoCliente()} - Pedido # {pedidoCreditoParaEdicao.codigo} - Alterar pedido de crédito</h1>
                    <div onClick={() => closeModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                        <i className="fas fa-times" style={{ color: '#5E6397' }}></i>
                    </div>
                </div>
                <div className="modal-body">
                    <div className="mb-5">
                        <h4>Funcionários</h4>
                        <div className="">
                            <button onClick={() => setExibirModalAdicionarFuncionario(true)} className="btn btn-primary btn-sm"> 
                                <i className="icon-lg text-white fas fa-plus"></i>
                                <span style={{ verticalAlign: 'middle' }}> Funcionário </span>
                            </button>   
                        </div>
                        <div className="mt-2 col-md-5 col-sm-6 col-lg-3">
                            <form className="d-flex flex-column" autoComplete="off" onSubmit={e => e.preventDefault()}>
                            <label htmlFor="form-valor" className="form-label">Valor de crédito</label>
                                <InputGroup className="">
                                    <InputMonetario 
                                        handlechange={async (e: ChangeEvent<HTMLSelectElement>, value: number, maskedValue: string) => {
                                        await formik.setFieldValue(e.target.name, value);
                                        await formik.setFieldTouched(e.target.name);
                                    }} name="valor" value={formik.values.valor} placeholder="R$ 9,99" className={clsx(
                                        'form-control',
                                        {
                                            'is-invalid': formik.touched.valor && formik.errors.valor,
                                        },
                                        {
                                            'is-valid': formik.touched.valor && !formik.errors.valor,
                                        }
                                    )} id="form-valor" />
                                    {formik.touched.valor && formik.errors.valor && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{formik.errors.valor}</span>
                                            </div>
                                        </div>
                                    )}
                                    <button disabled={!formik.isValid || listaPedidoCreditoPainel.length === 0} 
                                            onClick={() => { formik.submitForm() }}  
                                            className="btn btn-success btn-sm">APLICAR PARA TODOS</button>
                                </InputGroup>
                                
                            </form>
                        </div>
                    </div>
                    <div>
                    {loadingInicial ? (
                    <Spinner animation='border' />
                    ) : (
                    <div className="col-md-12 mb-2">
                        <GridPadrao colunas={colunas} tipo='Funcionários' itens={listaPedidoCreditoPainel} />
                    </div>
                    )}
                    {
                        listaPedidoCreditoPainel.length > 0 ? (
                            <>
                                <div className="col-12 d-flex flex-column  align-items-center">
                                        <div className="col-md-10 col-sm-12 col-lg-6 border-top d-flex flex-column  align-items-center">
                                            <div className="mt-4 ">
                                                <h4>Resumo do pedido</h4>
                                            </div>
                                            <div className="col-12">
                                                <Table bordered striped responsive>
                                                    <thead>
                                                        <tr>
                                                            <th className="p-2">Funcionários</th>
                                                            <th>Valor do pedido</th>
                                                            <th className="required" style={{backgroundColor: '#f3cad6'}}>Data disponibilização</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="p-2">{listaPedidoCreditoPainel.length}</td>
                                                            <td>{valorTotalPedido()}</td>
                                                            <td className="bg-danger col-md-3">
                                                                <div className="col-10">
                                                                    <InputDatePicker selected={formikPedidoCredito.values.dataDisponibilizacao} onChange={async (date?: Date) => {
                                                                        if (!date) {
                                                                            await formikPedidoCredito.setFieldValue('dataDisponibilizacao', undefined);
                                                                        } else {
                                                                            await formikPedidoCredito.setFieldValue('dataDisponibilizacao', date);
                                                                        }
                                                                    }} className={clsx(
                                                                        'form-control',
                                                                        {
                                                                            'is-invalid': formikPedidoCredito.touched.dataDisponibilizacao && formikPedidoCredito.errors.dataDisponibilizacao,
                                                                        },
                                                                        {
                                                                            'is-valid': formikPedidoCredito.touched.dataDisponibilizacao && !formikPedidoCredito.errors.dataDisponibilizacao,
                                                                        }
                                                                    )} id="form-data-dataDisponibilizacao" />
                                                                    {formikPedidoCredito.touched.dataDisponibilizacao && formikPedidoCredito.errors.dataDisponibilizacao && (
                                                                        <div className='fv-plugins-message-container'>
                                                                            <div className='fv-help-block'>
                                                                                <span role='alert'>{formikPedidoCredito.errors.dataDisponibilizacao}</span>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>
                                </div>
                            </>
                        ) : (<></>)
                    }
                    </div>
                </div>
                <div className="modal-footer" style={{ margin: '0 auto' }}>
                    <button disabled={formikPedidoCredito.isSubmitting} onClick={() => closeModal()} type="button" className="btn btn-danger me-5"> CANCELAR </button>
                    <button disabled={formikPedidoCredito.isSubmitting || !formikPedidoCredito.isValid} onClick={() => { formikPedidoCredito.submitForm() }} type="button" className="btn btn-primary ms-5">
                        {!formikPedidoCredito.isSubmitting && <> SALVAR </>}
                        {formikPedidoCredito.isSubmitting && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                Salvando...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                </div>
            </div>
        </Modal>
    </>
}

export default PedidoCreditoModalEditar;