import React from 'react'

interface ModalFiltrosProps {
    children: any
}

export default function ModalFiltros(props: ModalFiltrosProps) {
    return <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
        <div className='px-7 py-5'>
            <div className='fs-5 text-dark fw-bolder'>Opções de Filtros</div>
        </div>

        <div className='separator border-gray-200'></div>

        <div className='px-7 py-5'>
            {props.children}
        </div>
    </div>
}