import React, { ChangeEvent } from "react"
// @ts-ignore
import IntlCurrencyInput from "react-intl-currency-input"

const currencyConfig = {
    locale: "pt-BR",
    formats: {
        number: {
            BRL: {
                style: "currency",
                currency: "BRL",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
        },
    },
};

interface inputMonetarioType {
    className: string;
    handlechange: (event: ChangeEvent<HTMLSelectElement>, value: number, maskedValue: string) => void,
    id: string,
    name: string,
    placeholder: string,
    value: number,
    disabled?: boolean
    
}

const InputMonetario = (configs: inputMonetarioType) => {
    return (
        <IntlCurrencyInput
            // autoFocus={true}
            className={configs.className}
            onChange={configs.handlechange}
            id={configs.id}
            name={configs.name}
            placeholder={configs.placeholder}
            value={configs.value}
            currency="BRL"
            config={currencyConfig}
            type="text"
            disabled={configs.disabled}
        />
    );
}

export default InputMonetario;