import { FormikProps } from 'formik';
import React from 'react'

interface BotaoProps {
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    className?: string;
    label?: string;
    disabled: boolean
}


export default function BotaoCancelar({
                                    className = "btn btn-danger me-5",
                                    label = "CANCELAR",
                                    disabled,
                                    onClick
                                }: BotaoProps) 
{
    return <button 
            disabled={disabled} 
            onClick={onClick} 
            type="button" 
            className={className}>
                {label}
        </button>
}