import { useFormik } from "formik";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap-v5";
import Swal from "sweetalert2";
import GridPadrao from "../../components/GridPadrao";
import HeaderContent from "../../components/HeaderContent";
import InputCNPJ from "../../components/InputCNPJ";
import PainelFiltro from "../../components/PainelFiltro";
import clsx from "clsx";
import * as Yup from 'yup';
import FinanceiroService from "../../../services/FinanceiroService";
import { IFinanceiroCliente } from "../../../interfaces/responses/financeiro/IFinanceiroCliente";
import FormInputSelect from "../../components/FormInputSelect";
import { IFinanceiroGrupoEmpresarial } from "../../../interfaces/responses/financeiro/IFinanceiroGrupoEmpresarial";
import { TipoGrupoEmpresarial } from "../../../utils/Constants";

                  
export function Clientes() {
  const [loadingInicial, setLoadingInicial] = useState<boolean>(true);
  const [clientes, setClientes] = useState<IFinanceiroCliente[]>([]);
  const [gruposEmpresariais, setGruposEmpresarias] = useState<IFinanceiroGrupoEmpresarial[]>([]);
  
  useEffect(() => {
    formikFiltro.submitForm()
    carregarGrupos();
  }, [])

  async function carregarGrupos(){
    try {
       
        setLoadingInicial(true)
        
        const { data } = await FinanceiroService.obterGruposEmpresariais()

        setGruposEmpresarias(data);

      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Não foi possivel carregar os dados',
          text: 'Atualize a pagina, caso o erro continue entre em contato com o administrador.',
          showConfirmButton: true,
        })
      } finally {
        setLoadingInicial(false)
      } 
  }
  const valoresIniciaisFiltro = {
    cnpj: '',
    codigo: '',
    idGrupoEmpresarial:''
  } as any;
  
  const filtroValidacoes = Yup.object().shape({
    cnpj: Yup.string().min(14, "CNPJ deve possuir exatamente 14 caracteres").max(14, "CNPJ deve possuir exatamente 14 caracteres"),
  })
  
  const formikFiltro = useFormik({
    initialValues: valoresIniciaisFiltro,
    validationSchema: filtroValidacoes,
    onSubmit: async ({cnpj, codigo, idGrupoEmpresarial}) => {
      try {
        setLoadingInicial(true)
        
        const { data } = await FinanceiroService.obterClientes(cnpj, codigo, idGrupoEmpresarial)

        setClientes(data)

      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Não foi possivel carregar os Clientes',
          text: 'Atualize a pagina, caso o erro continue entre em contato com o administrador.',
          showConfirmButton: true,
        })
      } finally {
        setLoadingInicial(false)
      } 
    }
  });

  const colunas = [
    {
      name: 'CNPJ',
      selector: (row: IFinanceiroCliente) => row['cnpj']?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5"),
      sortable: false,
      wrap: true,
      grow: 3
    },
    {
      name: 'Nome',
      selector: (row: IFinanceiroCliente) => row['nome'],
      sortable: false,
      wrap: true,
      grow: 5
    },
    {
      name: 'Nome Fantasia',
      selector: (row: IFinanceiroCliente) => row['nomeFantasia'],
      sortable: false,
      wrap: true,
      grow: 5
    },
    {
      name: 'Grupo',
      selector: (row: IFinanceiroCliente) => row['nomeGrupoEmpresarial'],
      sortable: false,
      wrap: true,
      grow: 3
    },
    {
      name: 'Cidade',
      selector: (row: IFinanceiroCliente) => row['cidade'],
      sortable: false,
      wrap: true,
      grow: 2
    },
    {
      name: 'Ativo',
      selector: (row: IFinanceiroCliente) => row['ativo'] === true ? 'SIM' : 'NÃO',
      sortable: false,
      wrap: true,
      grow: 1
    },
  ]

  return (
    <>
       <HeaderContent title="Clientes" >
        
      </HeaderContent>

      <PainelFiltro>
              {
                  <>
                        <div className="col col-md-3">
                          <label className='form-label fw-bold'>CNPJ</label>
                          <InputCNPJ 
                            id="form-cnpj"
                            handlechange={async (e: ChangeEvent<HTMLInputElement>) => {
                              e.target.focus()
                              await formikFiltro.setFieldValue(e.target.name, e.target.value.replace(/[^\d]/g, ''));
                              await formikFiltro.setFieldTouched(e.target.name);
                              }}
                              value={formikFiltro.values.cnpj}
                              name="cnpj"
                              placeholder="Busque por CNPJ"
                              className={clsx(
                                'form-control',
                                {
                                    'is-invalid': formikFiltro.touched.cnpj && formikFiltro.errors.cnpj,
                                },
                                {
                                    'is-valid': formikFiltro.touched.cnpj && !formikFiltro.errors.cnpj,
                                }
                            )}
                            />
                            {formikFiltro.touched.cnpj && formikFiltro.errors.cnpj && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formikFiltro.errors.cnpj}</span>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="col col-md-2">
                          <label className='form-label fw-bold'>Código</label>
                          <input onChange={async (e) =>{ 
                                await formikFiltro.setFieldValue(e.target.name, e.target.value);
                                await formikFiltro.setFieldTouched(e.target.name);
                            }} 
                            value={formikFiltro.values.codigo}
                            name="codigo" 
                            type="text"
                            placeholder="Busque por código"
                            className="form-control"
                            />
                        </div>
                        
                        <FormInputSelect disabled={formikFiltro.isSubmitting} values={
                                gruposEmpresariais
                                .filter(v=> v.tipo !== TipoGrupoEmpresarial.CREDENCIADO)
                                .map(grupo => ({
                                  value: grupo.id,
                                  label: grupo.nome,
                              }))
                            } formik={formikFiltro} className="col col-md-4" propName='idGrupoEmpresarial' label='Grupo' />
                  </>
              }
              {
                  <>
                      <button data-kt-menu-trigger='click'
                          data-kt-menu-placement='bottom-end'
                          data-kt-menu-flip='top-end'
                          className='btn btn-sm btn-flex btn-primary btn-active-primary fw-bolder me-3'
                          onClick={formikFiltro.submitForm}
                          disabled={formikFiltro.isSubmitting || !formikFiltro.isValid}>
                          <i className="icon-lg fa fa-filter"></i>
                          Filtrar
                      </button>
                  </>
              }
      </PainelFiltro>
      {loadingInicial ? (
          <Spinner animation='border' />
        ) : (
          <div>
            <GridPadrao colunas={colunas} tipo='Clientes' itens={clientes} />
          </div>
        )}
    
    </>
  )
}