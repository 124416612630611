import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap-v5";
import Swal from "sweetalert2";
import { useAuth } from "../../../contexts/AuthProvider";
import GridPadrao from "../../components/GridPadrao";
import HeaderContent from "../../components/HeaderContent";
import PainelFiltro from "../../components/PainelFiltro";
import clsx from "clsx";
import * as Yup from 'yup';
import ClienteService from "../../../services/ClienteService";
import { IClienteCredenciado } from "../../../interfaces/responses/clientes/IClienteCredenciado";
import CredenciadoVincularModal from "./CredenciadoVincularModal";
import FormInputSelect, { FormInputMultiSelectItems } from "../../components/FormInputSelect";

export function Credenciados() {
  const [itens, setItens] = useState<FormInputMultiSelectItems[]>([])
  const [loadingInicial, setLoadingInicial] = useState<boolean>(true)
  const { clientes } = useAuth();
  const [credenciados, setCredenciados] = useState<IClienteCredenciado[]>([])
  const [exibirModalCriar, setExibirModalCriar] = useState<boolean>(false)

  useEffect(() => {
    carregarComboBox();
  }, [])

  async function carregarComboBox() {
    clientes?.map((p) => itens.push({ value: p.id, label: p.descricao }));
    setItens(itens);
    setLoadingInicial(false);
  }

  const valoresIniciaisFiltro = {
    idCliente: '',
  } as any;

  const filtroValidacoes = Yup.object().shape({
    idCliente: Yup.string().required('Selecione um cliente')
  });

  const formikFiltro = useFormik({
    initialValues: valoresIniciaisFiltro,
    validationSchema: filtroValidacoes,
    onSubmit: async ({ idCliente }) => {
      try {
        setLoadingInicial(true);
        const { data } = await ClienteService.obterCredenciadosPorCliente(idCliente);
        setCredenciados(data);
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Não foi possível carregar os Credenciados',
          text: 'Atualize a página, caso o erro continue, entre em contato com o administrador.',
          showConfirmButton: true,
        });
      } finally {
        setLoadingInicial(false);
      }
    },
  });

  const colunas = [
    {
      name: 'CNPJ',
      selector: (row: IClienteCredenciado) => row['cnpj'].replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5"),
      sortable: true,
      wrap: true,
    },
    {
      name: 'Nome',
      selector: (row: IClienteCredenciado) => row['nome'],
      sortable: true,
      wrap: true,
      grow: 2
    },
    {
      name: 'Nome Fantasia',
      selector: (row: IClienteCredenciado) => row['nomeFantasia'],
      sortable: true,
      wrap: true,
      grow: 2
    },
    {
      name: 'Logradouro',
      selector: (row: IClienteCredenciado) => row['logradouro'],
      sortable: true,
      wrap: true,
      grow:2
    },
    {
      name: 'Cidade',
      selector: (row: IClienteCredenciado) => row['cidade'],
      sortable: true,
      wrap: true,
    },
    {
      name: 'UF',
      selector: (row: IClienteCredenciado) => row['uf'],
      sortable: true,
      wrap: true,
      grow: 1
    }
  ];

  function toggleModalCriar(refresh: boolean): void {
    setExibirModalCriar(!exibirModalCriar);
    if(refresh){
      formikFiltro.submitForm();
    }
  }

  return (
    <>
    <CredenciadoVincularModal
        exibirModal={exibirModalCriar}
        toggleModal={toggleModalCriar}
        idCliente={formikFiltro.values.idCliente}
      />
      <HeaderContent title={'Rede Credenciada'} >
        <button
          className="btn btn-sm btn-flex btn-primary btn-active-primary fw-bolder"
          onClick={() => toggleModalCriar(false)}
          disabled={!formikFiltro.values.idCliente}
        >
          <i className="icon-lg fa fa-plus"></i>
          Vincular/Desvincular Credenciados
        </button>

      </HeaderContent>
      <PainelFiltro>
        <>
          <FormInputSelect 
            disabled={formikFiltro.isSubmitting} 
            values={itens} 
            formik={formikFiltro} 
            className="col col-md-4 mb-2 " 
            propName='idCliente' label='Cliente' />
            {formikFiltro.touched.idCliente && formikFiltro.errors.idCliente && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert' className="text-danger">{formikFiltro.errors.idCliente}</span>
                </div>
              </div>
            )}
        </>
        {
          <>
            <button data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
              className='btn btn-sm btn-flex btn-primary btn-active-primary fw-bolder me-3'
              onClick={formikFiltro.submitForm}
              disabled={formikFiltro.isSubmitting || !formikFiltro.isValid}>
              <i className="icon-lg fa fa-filter"></i>
              Filtrar
            </button>
          </>
        }
      </PainelFiltro>
      {loadingInicial ? (
        <Spinner animation='border' />
      ) : (
        <div>
          <GridPadrao colunas={colunas} tipo='Credenciados' itens={credenciados} />
        </div>
      )}
    </>
  );
}
