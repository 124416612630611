interface ComboxProps {
    value: any,

    items: ComboboxItem[],

    className?: string,

    disabled?: boolean,
    
    name?: string,

    onChange: (item: React.ChangeEvent<HTMLSelectElement>) => void,

}

export interface ComboboxItem {

    value: string,

    text: string
}

export default function Combobox(props: ComboxProps) {

    return <>
        <select
            className={props.className}
            onChange={props.onChange}
            value={props.value}
            disabled={props.disabled}
            name={props.name}
        >
            <option value='' label='Selecione'/>
            {
                props.items && props.items.map((i, index) => {
                    return <  option value={i.value} label={i.text} key={'combobox-' + index + i.value} />
                })
            }
        </select>
    </>
}