import React, { useEffect, useState } from "react";
import { INotificacao } from "../../../../interfaces/responses/administrador/INotificacao";
import FormInputSelect, { FormInputMultiSelectItems } from "../../../components/FormInputSelect";
import AdministradorService from "../../../../services/AdministradorService";
import { TipoGrupoEmpresarial } from "../../../../utils/Constants";
import HeaderContent from "../../../components/HeaderContent";
import PainelFiltro from "../../../components/PainelFiltro";
import * as Yup from 'yup';
import { useFormik } from "formik";
import NotificacaoService from "../../../../services/NotificacaoService";
import Swal from "sweetalert2";
import GridPadrao from "../../../components/GridPadrao";
import NotificacaoCriarModal from "../modal/NotificacaoCriarModal";

export function Notificacoes() {
    const [notificacoes, setNotificacoes] = useState<INotificacao[]>([])
    const [loadingInicial, setLoadingInicial] = useState<boolean>(true)
    const [grupoItems, setGrupoItems] = useState<FormInputMultiSelectItems[]>([])
    const [clienteItems, setClienteItems] = useState<FormInputMultiSelectItems[]>([])
    const [exibirModalCriar, setExibirModalCriar] = useState<boolean>(false)

    useEffect(() => {
        carregarGrupos()
        carregarClientes()
      }, [])

      async function carregarGrupos() {
        setLoadingInicial(true)
        
        const { data } = await AdministradorService.obterGruposEmpresarial()

        const grupos = data.filter(v=> v.tipo !== TipoGrupoEmpresarial.CREDENCIADO);

        grupos?.map((p) => grupoItems.push({ value: p.id, label: p.nome }));
        
        setGrupoItems(grupoItems);
      }

      async function carregarClientes(){
        setLoadingInicial(true);
    
        const { data } = await AdministradorService.obterClientes('', '', '');
    
        data?.map((p) => clienteItems.push({ value: p.id, label: p.nomeFantasia }));
        
        setClienteItems(clienteItems);
        setLoadingInicial(false);
      }

      const valoresIniciaisFiltro = {
        idGrupo: '',
        idCliente: '',
      } as any;
      
      const filtroValidacoes = Yup.object().shape({
        idCliente: Yup.string(),
        idGrupo: Yup.string(),
      });

      const formikFiltro = useFormik({
        initialValues: valoresIniciaisFiltro,
        validationSchema: filtroValidacoes,
        onSubmit: async ({idCliente, idGrupo}) => {
          try {
           
            setLoadingInicial(true)
            
            let idReferencia;
            if(idCliente){
                idReferencia = idCliente
            }
            else{
                idReferencia = idGrupo
            }
            const { data } = await NotificacaoService.obterTodos(idReferencia);
    
            setNotificacoes(data)
    
          } catch (error) {
            Swal.fire({
              icon: 'error',
              title: 'Não foi possível carregar as notificações',
              text: 'Atualize a pagina, caso o erro continue entre em contato com o administrador.',
              showConfirmButton: true,
            })
          } finally {
            setLoadingInicial(false)
          } 
        }
      });

      const colunas = [
        {
          name: 'Evento',
          selector: (row: INotificacao) => row['evento'],
          sortable: false,
          wrap: true,
          grow: 3
        },
        {
          name: 'Forma',
          selector: (row: INotificacao) => row['forma'],
          sortable: false,
          wrap: true,
        },
        {
          name: 'Contato',
          selector: (row: INotificacao) => row['contato'],
          sortable: false,
          wrap: true,
          grow: 2
        },
        {
          name: 'Cliente',
          selector: (row: INotificacao) => row['clienteDescricao'],
          sortable: false,
          wrap: true,
          grow: 2
        },
        {
            name: 'Grupo',
            selector: (row: INotificacao) => row['grupo'],
            sortable: false,
            wrap: true,
            grow: 2
        },
        {
          name: 'Ações',
          cell: (notificacao: INotificacao) => {
            return (
                <button
                      title="Remover Notificação"
                      className='btn btn-sm btn-icon btn-secondary me-1'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                      onClick={async () => {
                        await removerNotificacao(notificacao);
                      }}
                  >
                    
                      <i style={{fontSize: 24}} className="bi bi-trash-fill"></i>
                  </button>
            )
          },
        }
      ]

      async function removerNotificacao(notificacao: INotificacao){
        Swal.fire({
          title: 'Deletar a notificação?',
          html: `<div style='text-align: start;'>
                  <p>Evento: <strong>${notificacao.evento}</strong></p> 
                  <p><strong>${notificacao.clienteDescricao}</strong></p>
                  <p>Forma: <strong>${notificacao.forma}</strong></p>
                  </div>`,
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'VOLTAR',
          confirmButtonText: 'DELETAR',
          reverseButtons: true,
          customClass: {
              confirmButton: 'btn btn-success',
              cancelButton: 'btn btn-danger'
          }
        }).then(async (result) => {
          if (result.isConfirmed) {
              try {
                
                const result = await NotificacaoService.deletarNotificacao(notificacao.id);
                
                if(!result){
                  Swal.fire({
                    icon: 'error',
                    text: `Ocorreu um problema ao deletar notificação`,
                  });
                }
                else{
                  Swal.fire({
                    icon: 'success',
                    text: `Notificação deletada com sucesso`,
                  });
                }
                
                formikFiltro.submitForm();
              } catch (error) {
                Swal.fire(
                  Array.isArray(error) ? error[0] : `Não foi possivel deletar a notificação`
                  );
              } 
          }
        })
      }

      function toggleModalCriar(refresh: boolean): void {
        setExibirModalCriar(!exibirModalCriar);
        if(refresh){
          formikFiltro.submitForm();
        }
      }

    return (<>
      <NotificacaoCriarModal
        exibirModal={exibirModalCriar}
        toggleModal={toggleModalCriar}
      />

      <HeaderContent title="Notificações" >
        <button onClick={() => {
            setExibirModalCriar(true)
          }}  className='btn btn-sm btn-primary me-2'>
            <i className="icon-lg text-white fas fa-plus"></i>
            <span style={{ verticalAlign: 'middle' }}> Nova Notificação</span>
        </button>  
      </HeaderContent>

      <PainelFiltro>
              {
                  <>
                    <FormInputSelect 
                        disabled={formikFiltro.isSubmitting || formikFiltro.values.idCliente } 
                        values={grupoItems} 
                        formik={formikFiltro} 
                        className="col col-md-4" 
                        propName='idGrupo' label='Grupos' />

                    <FormInputSelect 
                        disabled={formikFiltro.isSubmitting || formikFiltro.values.idGrupo} 
                        values={clienteItems} 
                        formik={formikFiltro} 
                        className="col col-md-4" 
                        propName='idCliente' label='Clientes' />
                  </>
              }
              {
                  <>
                      <button data-kt-menu-trigger='click'
                          data-kt-menu-placement='bottom-end'
                          data-kt-menu-flip='top-end'
                          className='btn btn-sm btn-flex btn-primary btn-active-primary fw-bolder me-3'
                          onClick={formikFiltro.submitForm}
                          disabled={formikFiltro.isSubmitting || !formikFiltro.isValid || (!formikFiltro.values.idCliente && !formikFiltro.values.idGrupo)}>
                          <i className="icon-lg fa fa-filter"></i>
                          Filtrar
                      </button>
                  </>
              }
      </PainelFiltro>

      <GridPadrao colunas={colunas} tipo='Notificacoes' itens={notificacoes} />
    </>)
}