import React, { FC } from 'react'
import { Switch, Route } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { AuthPage } from '../modules/auth'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { MasterInit } from '../../_metronic/layout/MasterInit'
import { useAuth } from '../contexts/AuthProvider'

import { ClienteRoutes } from './ClienteRoutes'
import { CredenciadoRoutes } from './CredenciadoRoutes'
import { PERFIS } from '../utils/Constants'
import { AdministradorRoutes } from './AdministradorRoutes'
import { FinanceiroRoutes } from './FinanceiroRoutes'

const Routes: FC = () => {
  const { isAuthorized, loading, usuario } = useAuth();

  return (
    <>
      <Switch>
        <Route path='/error' component={ErrorsPage} />

        {!loading && (
          !isAuthorized ? (
            <Route>
              <AuthPage />
            </Route>
          ) : (
            <MasterLayout>
              {usuario?.perfil === PERFIS.CREDENCIADO && <CredenciadoRoutes />}
              {usuario?.perfil === PERFIS.CLIENTE && <ClienteRoutes />}
              {usuario?.perfil === PERFIS.ADMINISTRADOR && <AdministradorRoutes />}
              {usuario?.perfil === PERFIS.FINANCEIRO && <FinanceiroRoutes />}
            </MasterLayout>
          )
        )}

      </Switch>
      <MasterInit />
    </>
  )
}

export { Routes }
