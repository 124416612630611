import { IMovimento } from "../../interfaces/responses/credenciados/IMovimento"

interface ReciboProps {
    data: IMovimento;
}

export default function ReciboImpressao(props: ReciboProps) {
    return <>
    <div className="container-printer">
       <div className="print-receipt">
            <p>Comprovante - QbCard - <b>Via Cliente</b></p>
            <p>Loja: {props.data?.descricao}</p>
            <p>Data: {props.data?.dataFormatada}</p>
            <p>Valor: {props.data?.valorFormatado}</p>
            <p>NSU: {props.data?.nsu}</p>
            <p>Ref: {props.data?.referencia}</p>
            {   props.data?.saldoDisponivel ? 
            (<>
                <p>Saldo disponível: {props.data?.saldoDisponivel}</p>
            </>) : 
            (<></>)
            }
        </div>
        <div className="divider">
            ----------------------------------------------------
        </div>
        <div className="print-receipt-2">
            <p>Comprovante - QbCard - Via Empresa</p>
            <p>Loja: {props.data?.descricao}</p>
            <p>Data: {props.data?.dataFormatada}</p>
            <p>Valor: {props.data?.valorFormatado}</p>
            <p>NSU: {props.data?.nsu}</p>
            <p>Ref: {props.data?.referencia}</p>
        </div>
        
    </div>
    </>
}