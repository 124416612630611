import { IApiResult } from "../interfaces/responses/common/IApiResult";
import api from "./Api";
import { INotificacao } from "../interfaces/responses/administrador/INotificacao";
import { IEvento } from "../interfaces/responses/administrador/IEvento";
import { ICriarNotificacao } from "../interfaces/request/administrador/ICriarNotificacao";

class NotificacaoService {

    async criarNotificacao(request: ICriarNotificacao): Promise<void> {
        let { data } = await api.post<void>(`notificacao`, request);
    }
    
    async obterTodos(idReferencia: string): Promise<IApiResult<INotificacao[]>> {
        let { data: result } = await api.get<IApiResult<INotificacao[]>>(`notificacao/${idReferencia}`);
        
        return result;
    }

    async obterEventos(): Promise<IApiResult<IEvento[]>> {
        let { data: result } = await api.get<IApiResult<IEvento[]>>(`notificacao/eventos`);
        
        return result;
    }
    
    async deletarNotificacao(id: string): Promise<boolean>  {
        let { data } = await api.delete(`notificacao/${id}`)
        
        return data.success;
    }
}

export default new NotificacaoService();