import clsx from "clsx";
import { FormikProps } from "formik";
import React, { ChangeEvent } from "react"
import InputMask from "react-input-mask";
import FormikErrorText from "./FormikErrorText";

interface InputCpfProps {
    formik: FormikProps<any>;
    className?: string;
    label?: string;
    name: string;
    placeholder?: string;
    required: boolean;
}

export default function InputCPF({
                                    formik,
                                    className,
                                    label,
                                    name,
                                    placeholder,
                                    required
                                }: InputCpfProps) 
{
    return  <div className={className}>
                <label 
                    htmlFor={`form-${name}`} 
                    className={clsx('form-label', { 'required': required })}>
                        {label}
                </label>
                <InputMask 
                    className={clsx(
                        'form-control',
                        {
                            'is-invalid': formik.touched[name] && formik.errors[name],
                        },
                        {
                            'is-valid': formik.touched[name] && !formik.errors[name],
                        }
                    )}
                    onChange={async (e: ChangeEvent<HTMLInputElement>) => {
                        e.target.focus()
                        await formik.setFieldValue(e.target.name, e.target.value.replace(/[^\d]/g, ''));
                        await formik.setFieldTouched(e.target.name);
                        }} 
                    id={`form-${name}`}
                    name={name}
                    placeholder={placeholder}
                    value={formik.values[name]} 
                    mask="999.999.999-99" 
                />
                
                <FormikErrorText 
                formik={formik}
                name={name} />
        </div>
}