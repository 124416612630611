import React from 'react'

interface ModalFiltrosProps {
    children: any,
}

export default function PainelFiltro(props: ModalFiltrosProps) {
    return (
        <>
            <div className="card card mb-5">
                <div className="card-header">
                    <h3 className="card-title">Filtros</h3>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            {props.children[0]}
                        </div>
                    </div>
                    <div className="card-footer">
                        {props.children[1]}
                    </div>
                </div>
        </>
    )
}