import React, { useState, ChangeEvent, useEffect } from "react"
import { InputGroup, Modal, Spinner, Table } from "react-bootstrap-v5";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from "clsx";
import GridPadrao from "../../components/GridPadrao";

import ClienteService from "../../../services/ClienteService";
import Swal from "sweetalert2";
import ModalAltearValorFuncionario from "./ModalAltearValorFuncionario";
import ModalAdiconarFuncionario from "./ModalAdicionarFuncionario";
import InputMonetario from "../../components/InputMonetario";
import InputDatePicker from "../../components/InputDatePicker";
import { ICriarPedidoCreditoDetalhe } from "../../../interfaces/request/clientes/ICriarPedidoCreditoDetalhe";
import { ICriarPedidoCredito } from "../../../interfaces/request/clientes/ICriarPedidoCredito";
import { IPedidoCreditoPainel } from "../../../interfaces/responses/clientes/IPedidoCreditoPainel";
import ComboBox, { ComboboxItem } from "../../components/ComboBox";
import { useAuth } from "../../../contexts/AuthProvider";
import { ICliente } from "../../../interfaces/responses/IAutenticacao";
import FormInputSelect, { FormInputMultiSelectItems } from "../../components/FormInputSelect";

interface IPedidoCreditoCriarModalProps {
    toggleModal: () => void,
    exibirModal: boolean,
}
const PedidoCreditoModalCriar = ({ toggleModal, exibirModal }: IPedidoCreditoCriarModalProps) => {
    const [listaPedidoCreditoPainel, setListaPedidoCredito] = useState<IPedidoCreditoPainel[]>([])
    const [pedidoCreditoPainelSelecionado, setPedidoCreditoPainel] = useState<IPedidoCreditoPainel>({} as IPedidoCreditoPainel)
    const [exibirModalAlterarValor, setExibirModalAlterarValor] = useState<boolean>(false)
    const [exibirModalAdicionarPedidoCredito, setExibirModalAdicionarPedidoCredito] = useState<boolean>(false)
    const [loadingInicial, setLoadingInicial] = useState<boolean>(false)
    
    const [itens, setItens] = useState<FormInputMultiSelectItems[]>([])
    const { clientes } = useAuth();

    //const [clienteSelecionado, setCliente] = useState<ICliente>({} as ICliente)
    
    useEffect(() => {
        carregarComboBox();
      }, [])

    async function carregarComboBox(){
        clientes?.map((p) => itens.push({ value: p.id, label: p.descricao }));
        setItens(itens);
        setLoadingInicial(false);
    }

    function removerPedidoCreditoPainel(pedidoCreditoPainel: IPedidoCreditoPainel){
        Swal.fire({
            title: 'Remover funcionário?',
            text: `Remover ${pedidoCreditoPainel.nome} do pedido?`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'CANCELAR',
            confirmButtonText: 'EXCLUIR',
            reverseButtons: true,
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            }
          }).then((result) => {
            if (result.isConfirmed) {
                const temp = [...listaPedidoCreditoPainel];

                var index = temp.findIndex(c=> c.idFuncionario === pedidoCreditoPainel.idFuncionario);
        
                temp.splice(index, 1);
        
                setListaPedidoCredito(temp)
            }
        })
    }

    const colunas = [
        {
          name: 'Nome',
          selector: (row: IPedidoCreditoPainel) => row['nome'],
          sortable: true,
          wrap: true,
        },
        {
          name: 'CPF',
          selector: (row: IPedidoCreditoPainel) => row['cpf'].replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4"),
          sortable: true,
          wrap: true,
        },
        {
            name: 'Cartão',
            selector: (row: IPedidoCreditoPainel) => row['numero'],
            sortable: true,
            wrap: true,
        },
        {
            name: 'Valor',
            selector: (row: IPedidoCreditoPainel) => row['valor'].toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}),
            sortable: true,
            wrap: true,
        },
        {
            name: 'Ações',
            cell: (pedidoCreditoPainel: IPedidoCreditoPainel) => {
              return (
                <>
                    <div className="py-2">
                    <button
                        title="alterar Valor"
                        className='btn btn-sm btn-icon btn-secondary me-2'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                        onClick={() => {
                            setPedidoCreditoPainel(pedidoCreditoPainel);
                            setExibirModalAlterarValor(true);
                        }}
                    >
                        <i style={{fontSize: 24}} className="bi bi-pencil-square"></i>
                    </button>
                    </div>
                    <div className="">
                    <button
                        title="remover funcionário"
                        className='btn btn-sm btn-icon btn-secondary'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                        onClick={() => {
                            removerPedidoCreditoPainel(pedidoCreditoPainel)
                        }}
                    >
                        <i style={{fontSize: 24}} className="bi bi-trash-fill"></i>
                    </button>
                </div>
              </>
            )
            },
          }        
    ]

    
    async function carregarTodosPedidoCreditoPainel(){
        try {
            setLoadingInicial(true)
            
            if(!formikPedidoCredito.values?.idCliente)
            {
                Swal.fire("Selecione um cliente");
                return;
            }

            const { data  } = await ClienteService.obterCartoes(formikPedidoCredito.values?.idCliente, '', '')
            let funcionariosCriar: IPedidoCreditoPainel[] = [];
            data.forEach(item => {
                funcionariosCriar.push({
                    idCartao: item.id,
                    idFuncionario: item.funcionario.id,
                    numero: item.numero,
                    cpf: item.funcionario.cpf,
                    nome: item.funcionario.nome,
                    valor: 0
                } as IPedidoCreditoPainel);
            });
            setListaPedidoCredito(funcionariosCriar)
          
          } catch (error) {
            Swal.fire({
              icon: 'error',
              title: 'Não foi possivel carregar os Funcionários',
              text: 'Atualize a pagina, caso o erro continue entre em contato com o administrador.',
              showConfirmButton: true,
            })
          } finally {
            setLoadingInicial(false)
          } 
    }

    function toggleModalAlterarValorPedidoCreditoPainel(): void {
        setExibirModalAlterarValor(!exibirModalAlterarValor);
    }

    function toggleModalAdicionarPedidoCredito(): void {
        setExibirModalAdicionarPedidoCredito(!exibirModalAdicionarPedidoCredito);
    }

    function adicionarPedidoCreditoPainel(pedidoCredito: IPedidoCreditoPainel){
        if(funcionarioExiste(pedidoCredito)){
            Swal.fire({
                        icon: 'warning',
                        text: `Funcionário já adicionado`,
                        showConfirmButton: false,
                        timer: 3000
                    });
            return;
        }

        const temp = [...listaPedidoCreditoPainel];
        temp.push(pedidoCredito);
        setListaPedidoCredito(temp);
    }

    function funcionarioExiste(pedidoCredito : IPedidoCreditoPainel){
        let pedidoCreditoJaaAdicionado = listaPedidoCreditoPainel.find(c => c.idFuncionario === pedidoCredito.idFuncionario);
        if(pedidoCreditoJaaAdicionado)
            return true;
        
            return false;
    }

    function valorTotalPedido(){
        let valor = 0;
        listaPedidoCreditoPainel.forEach(element => {
            valor += element.valor
        });

        return valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
    }

    const alterarValorValidacoes = Yup.object().shape({        
        valor: Yup.number()
                    .min(0, 'Valor deve ser maior ou igual a 0')
                    .required('Valor é obrigatório'),

    })
    
    const valoresIniciais = {
        valor: 0,
    } as any;

    const formik = useFormik({
        initialValues: valoresIniciais,
        validationSchema: alterarValorValidacoes,
        onSubmit: (values) => {
            listaPedidoCreditoPainel.forEach(funcionario => {
                funcionario.valor = values.valor
            });
            Swal.fire({
                        icon: 'success',
                        text: `Valor aplicado para todos`,
                        showConfirmButton: false,
                        timer: 2000
                    })
            formik.resetForm();
        }
    });

    const pedidoCreditoValidacoes = Yup.object().shape({
        dataDisponibilizacao: Yup.date().required('Data disponibilização é obrigatória'),
    })

    const pedidoCreditoValoresIniciais = {
        dataDisponibilizacao: undefined,
        idCliente: ''
    } as any;

    const formikPedidoCredito = useFormik({
        initialValues: pedidoCreditoValoresIniciais,
        validationSchema: pedidoCreditoValidacoes,
        onSubmit: async (values) => {
            try {
                let valorTotal = 0;
                let pedidoCreditoDetalhe: ICriarPedidoCreditoDetalhe[] = [];
                listaPedidoCreditoPainel.forEach(element => {
                    valorTotal += element.valor
                    pedidoCreditoDetalhe.push({
                        idCartao: element.idCartao,
                        idFuncionario: element.idFuncionario,
                        valor: element.valor
                    } as ICriarPedidoCreditoDetalhe)
                });

                
                let pedidoCredito = {
                    idCliente: values.idCliente,
                    dataDisponibilizacao: values.dataDisponibilizacao,
                    valor: parseFloat(valorTotal.toFixed(2)),
                    pedidoCreditoDetalhe: pedidoCreditoDetalhe
                } as ICriarPedidoCredito
                
                await ClienteService.criarPedidoCredito(values.idCliente, pedidoCredito)

                Swal.fire({
                            icon: 'success',
                            text: `Pedido de crédito cadastrado`,
                            showConfirmButton: true,
                        })
                
                LimparEFecharModal()
                    
            } catch (error) {
                Swal.fire(
                    Array.isArray(error) ? error[0] : `Não foi possivel cadastrar o Pedido de crédito`
                    );
            }
        }
    });

    function closeModal(){
        if(listaPedidoCreditoPainel.length === 0){
            LimparEFecharModal()
            return;
        }

        Swal.fire({
            title: 'Deseja fechar a tela de pedido?',
            text: `Ao sair você perderá todas as alterações pendentes`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'CONTINUAR PEDIDO',
            confirmButtonText: 'SAIR',
            reverseButtons: true,
            customClass: {
                confirmButton: 'btn btn-danger',
                cancelButton: 'btn btn-primary'
            }
            
          }).then((result) => {
            if (result.isConfirmed) {
                LimparEFecharModal()
            }
        })
    }

    function LimparEFecharModal(){
        setListaPedidoCredito([]);
        formik.resetForm();
        formikPedidoCredito.resetForm();
        toggleModal();
    }

    return <>
        <ModalAltearValorFuncionario 
            toggleModal={toggleModalAlterarValorPedidoCreditoPainel}
            pedidoCreditoPainel={pedidoCreditoPainelSelecionado}
            exibirModal={exibirModalAlterarValor}
        />

        <ModalAdiconarFuncionario 
            exibirModal={exibirModalAdicionarPedidoCredito}
            idCliente={formikPedidoCredito.values.idCliente}
            toggleModal={toggleModalAdicionarPedidoCredito}
            adicionar={adicionarPedidoCreditoPainel} 
            existe={funcionarioExiste}
        />
        <Modal  fullscreen centered={true} show={exibirModal} onHide={closeModal}>
            <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title titulo-modal">{clientes?.find(v=>v.id === formikPedidoCredito.values.idCliente)?.descricao} - Novo pedido de crédito</h1>
                    <div onClick={() => closeModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                        <i className="fas fa-times" style={{ color: '#5E6397' }}></i>
                    </div>
                </div>
                <div className="modal-body">
                    <FormInputSelect 
                                disabled={formikPedidoCredito.isSubmitting || formikPedidoCredito.values.idCliente} 
                                values={itens} 
                                formik={formikPedidoCredito} 
                                className="col col-md-3 mb-3" 
                                propName='idCliente' label='Selecione o Cliente' />
                    {
                        clientes?.find(v=>v.id === formikPedidoCredito.values.idCliente)?.descricao ?
                        (<>
                            <div className="mb-5">
                                <h4>Funcionários</h4>
                                <div className="">
                                    <button onClick={() => carregarTodosPedidoCreditoPainel()} disabled={listaPedidoCreditoPainel.length > 0} className="btn btn-primary btn-sm me-2">Carregar todos</button>
                                    <button onClick={() => setExibirModalAdicionarPedidoCredito(true)} className="btn btn-primary btn-sm"> 
                                        <i className="icon-lg text-white fas fa-plus"></i>
                                        <span style={{ verticalAlign: 'middle' }}> Funcionário </span>
                                    </button>   
                                </div>
                                <div className="mt-2 col-md-5 col-sm-6 col-lg-3">
                                    <form className="d-flex flex-column" autoComplete="off" onSubmit={e => e.preventDefault()}>
                                    <label htmlFor="form-valor" className="form-label">Valor de crédito</label>
                                        <InputGroup className="">
                                            <InputMonetario 
                                                handlechange={async (e: ChangeEvent<HTMLSelectElement>, value: number, maskedValue: string) => {
                                                await formik.setFieldValue(e.target.name, value);
                                                await formik.setFieldTouched(e.target.name);
                                            }} name="valor" value={formik.values.valor} placeholder="R$ 9,99" className={clsx(
                                                'form-control',
                                                {
                                                    'is-invalid': formik.touched.valor && formik.errors.valor,
                                                },
                                                {
                                                    'is-valid': formik.touched.valor && !formik.errors.valor,
                                                }
                                            )} id="form-valor" />
                                            {formik.touched.valor && formik.errors.valor && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert'>{formik.errors.valor}</span>
                                                    </div>
                                                </div>
                                            )}
                                            <button disabled={!formik.isValid || listaPedidoCreditoPainel.length === 0} 
                                                    onClick={() => { formik.submitForm() }}  
                                                    className="btn btn-success btn-sm">APLICAR PARA TODOS</button>
                                        </InputGroup>
                                        
                                    </form>
                                </div>
                            </div>
                            <div>
                            {loadingInicial ? (
                            <Spinner animation='border' />
                            ) : (
                            <div className="col-md-12 mb-2">
                                <GridPadrao colunas={colunas} tipo='Funcionários' itens={listaPedidoCreditoPainel} />
                            </div>
                            )}
                            {
                                listaPedidoCreditoPainel.length > 0 ? (
                                    <>
                                        <div className="col-12 d-flex flex-column  align-items-center">
                                                <div className="col-md-10 col-sm-12 col-lg-6 border-top d-flex flex-column  align-items-center">
                                                    <div className="mt-4 ">
                                                        <h4>Resumo do pedido</h4>
                                                    </div>
                                                    <div className="col-12">
                                                        <Table bordered striped responsive>
                                                            <thead>
                                                                <tr>
                                                                    <th className="p-2">Funcionários</th>
                                                                    <th>Valor do pedido</th>
                                                                    <th className="required" style={{backgroundColor: '#f3cad6'}}>Data disponibilização</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td className="p-2">{listaPedidoCreditoPainel.length}</td>
                                                                    <td>{valorTotalPedido()}</td>
                                                                    <td className="bg-danger col-md-3">
                                                                        <div className="col-10">
                                                                            <InputDatePicker selected={formikPedidoCredito.values.dataDisponibilizacao} onChange={async (date?: Date) => {
                                                                                if (!date) {
                                                                                    await formikPedidoCredito.setFieldValue('dataDisponibilizacao', undefined);
                                                                                } else {
                                                                                    await formikPedidoCredito.setFieldValue('dataDisponibilizacao', date);
                                                                                }
                                                                            }} className={clsx(
                                                                                'form-control',
                                                                                {
                                                                                    'is-invalid': formikPedidoCredito.touched.dataDisponibilizacao && formikPedidoCredito.errors.dataDisponibilizacao,
                                                                                },
                                                                                {
                                                                                    'is-valid': formikPedidoCredito.touched.dataDisponibilizacao && !formikPedidoCredito.errors.dataDisponibilizacao,
                                                                                }
                                                                            )} id="form-data-dataDisponibilizacao" />
                                                                            {formikPedidoCredito.touched.dataDisponibilizacao && formikPedidoCredito.errors.dataDisponibilizacao && (
                                                                                <div className='fv-plugins-message-container'>
                                                                                    <div className='fv-help-block'>
                                                                                        <span role='alert'>{formikPedidoCredito.errors.dataDisponibilizacao}</span>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </div>
                                        </div>
                                    </>
                                ) : (<></>)
                            }
                            </div>
                        </>)
                        : (<></>)
                    }
                </div>
                <div className="modal-footer" style={{ margin: '0 auto' }}>
                    <button disabled={formikPedidoCredito.isSubmitting} onClick={() => closeModal()} type="button" className="btn btn-danger me-5"> CANCELAR </button>
                    <button disabled={formikPedidoCredito.isSubmitting || !formikPedidoCredito.isValid} onClick={() => { formikPedidoCredito.submitForm() }} type="button" className="btn btn-primary ms-5">
                        {!formikPedidoCredito.isSubmitting && <> CADASTRAR </>}
                        {formikPedidoCredito.isSubmitting && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                Salvando...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                </div>
            </div>
        </Modal>
    </>
}

export default PedidoCreditoModalCriar;