import React, { useState } from "react"
import { Modal, Spinner } from "react-bootstrap-v5";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useEffect } from "react";
import clsx from "clsx";

import Swal from "sweetalert2";
import { ICartaoResponse } from "../../../../interfaces/responses/clientes/ICartaoResponse";
import AdministradorService from "../../../../services/AdministradorService";
import GridPadrao from "../../../components/GridPadrao";


interface LotesCartaoModalDetalheProps {
    toggleModal: () => void,
    exibirModal: boolean,
    idLoteCartao: string,
}

const LoteCartaoDetalhe = ({ toggleModal, exibirModal, idLoteCartao }: LotesCartaoModalDetalheProps) => {
    const [loadingInicial, setLoadingInicial] = useState<boolean>(true)
    const [cartoes, setCartoes] = useState<ICartaoResponse[]>([])

    async function carregarCartoes(){
        try {
            setLoadingInicial(true)
            const { data } = await AdministradorService.obterCartoesPorLote(idLoteCartao);
            setCartoes(data);
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Não foi possivel carregar os dados',
                text: 'Atualize a pagina, caso o erro continue entre em contato com o administrador.',
                showConfirmButton: true,
              })
        }finally {
            setLoadingInicial(false)
        } 
    }

    const colunas = [
        {
          name: 'Nome',
          selector: (row: ICartaoResponse) => row.funcionario['nome'],
          sortable: true,
          wrap: true,
        },
        {
          name: 'CPF',
          selector: (row: ICartaoResponse) => row.funcionario['cpf'].replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4"),
          sortable: true,
          wrap: true,
        },
        {
          name: 'Filial Nome',
          selector: (row: ICartaoResponse) => row.cliente['nome'],
          sortable: true,
          wrap: true,
        },
        {
          name: 'Filial CNPJ',
          selector: (row: ICartaoResponse) => row.cliente['cnpj'].replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5"),
          sortable: true,
          wrap: true,
        },
        {
          name: 'Número Cartão',
          selector: (row: ICartaoResponse) => row['numero'],
          sortable: true,
          wrap: true,
        }
    ]

    return <>
           <Modal fullscreen centered={true} show={exibirModal} onHide={toggleModal} onShow={carregarCartoes}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title titulo-modal">Novo Lote</h5>
                    <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                        <i className="fas fa-times" style={{ color: '#5E6397' }}></i>
                    </div>
                </div>
                <div className="modal-body">
                {loadingInicial ? (
                    <Spinner animation='border' />
                    ) : (
                    <div className="col-md-12 mb-2">
                        <GridPadrao colunas={colunas} tipo='Funcionários' itens={cartoes} />
                    </div>
                    )}
                </div>
                <div className="modal-footer" style={{ margin: '0 auto' }}>
                    <button onClick={() => toggleModal()} type="button" className="btn btn-danger me-5"> VOLTAR </button>
                </div>
            </div>
        </Modal>
    </>
}

export default LoteCartaoDetalhe;