import { Modal } from "react-bootstrap-v5";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useEffect } from "react";
import clsx from "clsx";
import Swal from "sweetalert2";
import CredenciadoService from "../../../services/CredenciadoService";

interface PagamentoModalProps {
    toggleModal: () => void,
    exibirModal: boolean,
    titulo: string 
}

const PagamentoModalConsultarSaldo = ({ toggleModal, exibirModal, titulo }: PagamentoModalProps) => {
    var inputNumeroCartaoRef : HTMLInputElement;
    var inputSenhaCartaoRef : HTMLInputElement;

    useEffect(() =>{
        
    });

    function reset(){
        formik.resetForm();
        toggleModal();
    }

    const pagamentoValidacoes = Yup.object().shape({
        numeroCartao: Yup.string()
                        .required('Número cartão é obrigatório'),

        senhaCartao: Yup.string()
                        .min(4, 'Senha deve possuir no mínimo 4 caracteres')
                        .max(6, 'Senha deve possuir no máximo 6 caracteres')
                        .required('Senha é obrigatório'),
    })
    
    const valoresIniciais = {
        numeroCartao: '',
        senhaCartao: ''
    } as any;

    const formik = useFormik({
        initialValues: valoresIniciais,
        validationSchema: pagamentoValidacoes,
        onSubmit: async (values) => {
            try {
                
                    let numCartao = values.numeroCartao;

                    if(values.numeroCartao.length > 18){
                        //;6398670000000002700=26030000000000000?
                        numCartao = values.numeroCartao.split('=')[0].substring(1,19);
                    }

                    var result = await CredenciadoService.obterSaldoCartao(numCartao, values.senhaCartao);
                    
                    Swal.fire({
                        title: 'Sucesso',
                        text: `Saldo atual ${result.data}`,
                        icon: 'success',
                      })
            } catch (error) {
                Swal.fire(
                    Array.isArray(error) ? error[0] : `Não foi possivel cadastrar o Pagamento`
                    );
                reset();
            }
        }
    });

    function setFocusNumeroCartao(){
        inputNumeroCartaoRef.focus()
    }

    function setFocusSenhaCartao(){
        inputSenhaCartaoRef.focus()
    }

    return <>
        <Modal size='lg' centered={true} show={exibirModal} onHide={reset} onShow={() => setFocusNumeroCartao()}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title titulo-modal">{titulo}</h5>
                    <div onClick={() => reset()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                        <i className="fas fa-times" style={{ color: '#5E6397' }}></i>
                    </div>
                </div>
                <div className="modal-body" style={{margin: '0 auto'}}>
                    <form className="row g-3" autoComplete="off">
                        <div className="alert alert-info">
                            Verifique se o LED <span style={{fontWeight: 700}} className="text-success ">VERDE</span> está ligado no PINPAD. Pressione <span style={{fontWeight: 700}}>SCROLL LOCK</span> para ativá-lo.
                        </div>
                        <div className="col-md-6 mb-5">
                            <label htmlFor="form-numeroCartao" className="form-label required">Número Cartão</label>
                            <input disabled={(formik.touched.numeroCartao && !formik.errors.numeroCartao)} ref={c => (inputNumeroCartaoRef  = c!)} 
                                    {...formik.getFieldProps('numeroCartao')} 
                                    // onBlur={e => cryptoText(e, 'numeroCartao')}
                                    placeholder="Número Cartão" type="password" 
                                    className={clsx(
                                'form-control',
                                {
                                    'is-invalid': formik.touched.numeroCartao && formik.errors.numeroCartao,
                                },
                                {
                                    'is-valid': formik.touched.numeroCartao && !formik.errors.numeroCartao,
                                }
                            )} id="form-numeroCartao" />
                            {formik.touched.numeroCartao && formik.errors.numeroCartao && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.numeroCartao}</span>
                                    </div>
                                </div>
                            )}
                            <button 
                                disabled={(formik.touched.numeroCartao && !formik.errors.numeroCartao)} 
                                type="button" 
                                style={{width: '100%'}}
                                className="border border-success btn btn-outline-success d-grid mt-2" 
                                onClick={setFocusNumeroCartao}>
                                    {<>{formik.getFieldProps('numeroCartao').value != '' ? 'CONFIRMAR CARTAO' : 'SOLICITAR CARTÃO'}</>} 
                            </button>
                        </div>
                        <div className="col-md-6 mb-5">
                            <label htmlFor="form-senhaCartao" className="form-label required">Senha Cartão</label>
                            <input disabled={!!formik.errors.numeroCartao} ref={c => (inputSenhaCartaoRef  = c!)} 
                                    {...formik.getFieldProps('senhaCartao')} 
                                    // onBlur={e => cryptoText(e, 'senhaCartao')}
                                    placeholder="Senha Cartão" 
                                    type="password" 
                                    className={clsx(
                                'form-control',
                                {
                                    'is-invalid': formik.touched.senhaCartao && formik.errors.senhaCartao,
                                },
                                {
                                    'is-valid': formik.touched.senhaCartao && !formik.errors.senhaCartao,
                                }
                            )} id="form-senhaCartao" />
                            {formik.touched.senhaCartao && formik.errors.senhaCartao && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.senhaCartao}</span>
                                    </div>
                                </div>
                            )}
                            <button 
                                type="button"                            
                                disabled={!!formik.errors.numeroCartao} 
                                style={{width: '100%'}}
                                className="border border-success btn btn-outline-success d-grid mt-2" 
                                onClick={setFocusSenhaCartao}>
                                     {<>{formik.getFieldProps('senhaCartao').value != '' ? 'CONFIRMAR SENHA' : 'SOLICITAR SENHA'}</>} 
                            </button>
                        </div>
                    </form>
                </div>
                <div className="modal-footer" style={{ margin: '0 auto' }}>
                    <button disabled={formik.isSubmitting} onClick={() => reset()} type="button" className="btn btn-danger me-5"> VOLTAR </button>
                    <button disabled={formik.isSubmitting || !formik.isValid} onClick={() => { formik.submitForm() }} type="button" className="btn btn-success ms-5">
                        {!formik.isSubmitting && <> CONSULTAR SALDO </>}
                        {formik.isSubmitting && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                Consultando...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                </div>
            </div>
        </Modal>
    </>
}

export default PagamentoModalConsultarSaldo;