/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { AsideMenuItem } from '../AsideMenuItem'

export function AsideMenuCredenciado() {
  return (
    <>
      <AsideMenuItem
        to='/movimentos'
        icon='/media/icons/duotune/abstract/abs006.svg'
        title="Movimentos"
        fontIcon='bi-app-indicator'
      />

      <AsideMenuItem
        to='/pagamentos'
        icon='/media/icons/duotune/abstract/abs006.svg'
        title="Pagamentos"
        fontIcon='bi-app-indicator'
      />

      <AsideMenuItem
        to='/relatorio-movimento'
        icon='/media/icons/duotune/abstract/abs006.svg'
        title="Relatório Movimento"
        fontIcon='bi-app-indicator'
      />
    </>
  )
}