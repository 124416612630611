import React, { useEffect, useState } from "react";
import { IAdminUsuario } from "../../../interfaces/responses/administrador/IAdminUsuario";
import { Modal } from "react-bootstrap-v5";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { ITrocarSenhaUsuarioRequest } from "../../../interfaces/request/administrador/ITrocarSenhaUsuarioRequest";
import AdministradorService from "../../../services/AdministradorService";
import Swal from "sweetalert2";
import clsx from "clsx";
import { ICriarUsuarioRequest } from "../../../interfaces/request/administrador/ICriarUsuarioRequest";
import FormInputSelect from "../../components/FormInputSelect";
import { FormInputMultiSelectItems } from "../../components/FormInputMultiSelect";
import BotaoSubmitFormulario from "../../components/BotaoSubmitFormulario";


interface UsuarioCriarModalProps {
    toggleModal: () => void,
    exibirModal: boolean,
}

const UsuarioCriarModal = ({ toggleModal, exibirModal }: UsuarioCriarModalProps) =>{
    const [isLoading, setLoading] = useState<boolean>(false)
    const [items, setItems] = useState<FormInputMultiSelectItems[]>([]);

    
    useEffect(() => {
        carregarPerfis()
    }, [])
    
    async function carregarPerfis(){
        setLoading(true);

        const { data } = await AdministradorService.obterPerfis();
  
        data?.map((p) => items.push({ value: p.id, label: p.descricao }));
        
        setItems(items);
        setLoading(false);
    }

    const usuarioCriarValidations = Yup.object().shape({
        senha: Yup.string().min(4, 'Senha deve possuir no mínimo 4 caracteres').required('Senha é obrigatória'),
        login: Yup.string().min(4, 'Login deve possuir no mínimo 4 caracteres').required('Login é obrigatório'),
        idPerfil: Yup.string().required('Login é obrigatório')
    })

    const valoresIniciais = {
        senha: '',
        login: '',
        idPerfil: ''
    } as any;

    const formik = useFormik({
        initialValues: valoresIniciais,
        validationSchema: usuarioCriarValidations,
        onSubmit: async (values) => {
            try {
                setLoading(true);
                
                let criarUsuario: ICriarUsuarioRequest =
                {
                    senha: values.senha,
                    login: values.login,
                    idPerfil: values.idPerfil
                }

                let data = await AdministradorService.criarUsuario(criarUsuario);
                
                Swal.fire({
                            icon: 'success',
                            text: `Usuário criado com sucesso`,
                            showConfirmButton: true,
                        });
            
                limparFechar();

            } catch (error) {
                Swal.fire(
                     Array.isArray(error) ? error[0] : `Falha ao criar usuario, tente novamente`
                );
            }
            finally {
                setLoading(false);
            } 
        }
    });

    function limparFechar() {
        formik.resetForm();
        toggleModal();
    }

    return (
        <>
        <Modal size='xl' centered={true} show={exibirModal} onHide={toggleModal}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title titulo-modal">Criar Usuario</h5>
                    <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                        <i className="fas fa-times" style={{ color: '#5E6397' }}></i>
                    </div>
                </div>
                <div className="modal-body">
                    <form className="row mt-2">
                            <h4>Criar Usuario</h4>
                            <div className="row mt-2">
                                <div className="col-md-4 mb-5">
                                <label htmlFor="form-login" className="form-label required">Login</label>
                                    <input {...formik.getFieldProps('login')} placeholder="Login" type="text" 
                                    className={clsx(
                                        'form-control',
                                        {
                                            'is-invalid': formik.touched.login && formik.errors.login,
                                        },
                                        {
                                            'is-valid': formik.touched.login && !formik.errors.login,
                                        }
                                    )} id="form-login" />
                                    {formik.touched.login && formik.errors.login && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{formik.errors.login}</span>
                                            </div>
                                        </div>
                                )}  
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-4 mb-5">
                                <label htmlFor="form-senha" className="form-label required">Senha</label>
                                    <input {...formik.getFieldProps('senha')} placeholder="Senha" type="text" className={clsx(
                                        'form-control',
                                        {
                                            'is-invalid': formik.touched.senha && formik.errors.senha,
                                        },
                                        {
                                            'is-valid': formik.touched.senha && !formik.errors.senha,
                                        }
                                    )} id="form-senha" />
                                    {formik.touched.senha && formik.errors.senha && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{formik.errors.senha}</span>
                                            </div>
                                        </div>
                                )}  
                                </div>
                            </div>
                            <FormInputSelect 
                                disabled={formik.isSubmitting} 
                                values={items} 
                                formik={formik} 
                                className="col col-md-4" 
                                propName='idPerfil' label='Selecione o perfil' 
                                required={true}/>

                    </form>
                </div>
                <div className="modal-footer" style={{ margin: '0 auto' }}>
                    <button  onClick={() => toggleModal()} type="button" className="btn btn-secondary me-5"> VOLTAR </button>
                    
                    <BotaoSubmitFormulario 
                            formik={formik}
                       />
                </div>
            </div>
        </Modal>
        </>
    )
}

export default UsuarioCriarModal;