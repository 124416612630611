import React, { useEffect, useState } from "react";

import Swal from "sweetalert2";
import { Modal, Spinner } from "react-bootstrap-v5";
import ModalHeader from "../../../components/modal/ModalHeader";
import Loading from "../../../components/loading/Loading";
import BotaoCancelar from "../../../components/BotaoCancelar";
import ModalContent from "../../../components/modal/ModalContent";
import ModalBody from "../../../components/modal/ModalBody";
import ModalFooter from "../../../components/modal/ModalFooter";
import { IEvento } from "../../../../interfaces/responses/administrador/IEvento";
import NotificacaoService from "../../../../services/NotificacaoService";
import FormInputSelect, { FormInputMultiSelectItems } from "../../../components/FormInputSelect";
import * as Yup from 'yup';
import { useFormik } from "formik";
import AdministradorService from "../../../../services/AdministradorService";
import { TipoGrupoEmpresarial } from "../../../../utils/Constants";
import InputText from "../../../components/InputText";
import { ICriarNotificacao } from "../../../../interfaces/request/administrador/ICriarNotificacao";
import { IFormaNotificacao } from "../../../../interfaces/request/administrador/IFormaNotificacao";
import BotaoSubmitFormulario from "../../../components/BotaoSubmitFormulario";

interface IModalProps {
    toggleModal: (refresh: boolean) => void,
    exibirModal: boolean
}

const NotificacaoCriarModal = ({toggleModal, exibirModal}: IModalProps) =>{
    useEffect(() => {
        
    }, [])

    const [loadingInicial, setLoadingInicial] = useState<boolean>(false)
    const [eventos, setEventos] = useState<IEvento[]>([])
    const [grupoItems, setGrupoItems] = useState<FormInputMultiSelectItems[]>([])
    const [clienteItems, setClienteItems] = useState<FormInputMultiSelectItems[]>([])
    const [eventoItems, setEventoItems] = useState<FormInputMultiSelectItems[]>([])

    async function carregarEventos(){
        try {
            setLoadingInicial(true)
            const { data  } = await NotificacaoService.obterEventos()
            
            setEventos(data)
            
            let array: FormInputMultiSelectItems[] = [];

            data?.map((p) => array.push({ value: p.id, label: p.evento }));
        
            setEventoItems(array);
            
            carregarGrupos();
            carregarClientes();
          
          } catch (error) {
            Swal.fire({
              icon: 'error',
              title: 'Não foi possivel carregar os Eventos',
              text: 'Atualize a pagina, caso o erro continue entre em contato com o administrador.',
              showConfirmButton: true,
            })
          } finally {
            setLoadingInicial(false)
          } 
    }

    async function carregarGrupos() {
        setLoadingInicial(true)
        
        const { data } = await AdministradorService.obterGruposEmpresarial()

        const grupos = data.filter(v=> v.tipo !== TipoGrupoEmpresarial.CREDENCIADO);

        grupos?.map((p) => grupoItems.push({ value: p.id, label: p.nome }));
        
        setGrupoItems(grupoItems);
      }

    async function carregarClientes(){
        setLoadingInicial(true);
    
        const { data } = await AdministradorService.obterClientes('', '', '');
    
        data?.map((p) => clienteItems.push({ value: p.id, label: p.nomeFantasia }));
        
        setClienteItems(clienteItems);
        setLoadingInicial(false);
      }

    const valoresIniciaisFiltro = {
        idReferencia: '',
        idEvento: '',
        contato: ''
      } as any;
      
    const filtroValidacoes = Yup.object().shape({
        idReferencia: Yup.string().required(),
        idEvento: Yup.string().required(),
        contato: Yup.string().required().email(),
    });

    const formikFiltro = useFormik({
        initialValues: valoresIniciaisFiltro,
        validationSchema: filtroValidacoes,
        onSubmit: async ({idReferencia, idEvento, contato}) => {
          try {
           
            setLoadingInicial(true)
            let evento = eventos.find(c => c.id === idEvento);
            
            let request = {
                contato: contato,
                evento: evento?.evento,
                forma: IFormaNotificacao.Email,
                idReferencia: idReferencia
            } as ICriarNotificacao

            await NotificacaoService.criarNotificacao(request);

            limparFechar(true);

          } catch (error) {
            Swal.fire(
                Array.isArray(error) ? error[0] : `Falha ao cadastrar, tente novamente`
           );
          } finally {
            setLoadingInicial(false)
          } 
        }
      });

      function limparFechar(refresh: boolean) {
        formikFiltro.resetForm();
        toggleModal(refresh);
    }

    return <>
        <Modal size='lg' centered={true} show={exibirModal} onHide={toggleModal} onShow={carregarEventos}>
            <ModalContent>
                <ModalHeader 
                    title='Nova notificação'
                    closeFunction={() => toggleModal(false)}
                />
                <ModalBody>
                    <Loading isLoading={loadingInicial}>
                        <form>
                            <div className="row">
                                <FormInputSelect 
                                    disabled={formikFiltro.isSubmitting} 
                                    values={eventoItems} 
                                    formik={formikFiltro} 
                                    className="col col-md-4" 
                                    propName='idEvento' 
                                    label='Evento' />

                                    { eventos.find(c => c.id === formikFiltro.values.idEvento)?.eventoPorCliente ? 
                                        (
                                            <FormInputSelect 
                                                disabled={formikFiltro.isSubmitting} 
                                                values={clienteItems} 
                                                formik={formikFiltro} 
                                                className="col col-md-4" 
                                                propName='idReferencia' 
                                                label='Cliente' />
                                        )
                                        :
                                        (
                                            <FormInputSelect 
                                                disabled={formikFiltro.isSubmitting} 
                                                values={grupoItems} 
                                                formik={formikFiltro} 
                                                className="col col-md-4" 
                                                propName='idReferencia' 
                                                label='Grupo' />
                                        )}

                                 <InputText
                                    formik={formikFiltro}
                                    name="contato"
                                    required={true}
                                    label="Email"
                                    placeholder="email"
                                    className="col-md-4" />
                            </div>
                        </form>
                    </Loading>
                </ModalBody>
                <ModalFooter>
                    <BotaoCancelar 
                        onClick={() => toggleModal(false)}
                        disabled={loadingInicial}
                    />
                   <BotaoSubmitFormulario 
                        formik={formikFiltro}
                    />
                </ModalFooter>
            </ModalContent>
        </Modal>
    </>
}

export default NotificacaoCriarModal;