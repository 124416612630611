import React, { useState } from "react"
import { Modal } from "react-bootstrap-v5";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useEffect } from "react";
import clsx from "clsx";
import Swal from "sweetalert2";
import AuthService from "../../services/AuthService";


interface TrocarSenhaModalProps {
    toggleModal: () => void,
    exibirModal: boolean,
}

const TrocarSenhaModal = ({ toggleModal, exibirModal }: TrocarSenhaModalProps) => {
    
    const trocarSenhaValidacao = Yup.object().shape({
        senha: Yup.string()
                .min(6, 'Senha deve possuir no mínimo 6 caracteres')
                .max(20, 'Senha deve possuir no máximo 20 caracteres')
                .required('Número cartão é obrigatório'),

        confirmacaoSenha: Yup.string()
                            .oneOf([Yup.ref('senha'), null], 'Senha e confirmação devem ser iguais')
    })
    
    const valoresIniciais = {
        senha: '',
        confirmacaoSenha: ''
    } as any;

    function reset(){
        formik.resetForm();
        toggleModal();
    }

    const formik = useFormik({
        initialValues: valoresIniciais,
        validationSchema: trocarSenhaValidacao,
        onSubmit: async (values) => {
            try {
                    await AuthService.trocarSenha(values.senha, values.confirmacaoSenha);
                    
                    Swal.fire({
                        title: 'Sucesso',
                        text: `Senha alterada com sucesso`,
                        icon: 'success',
                      });
                    reset();
                }   catch (error) {
                        Swal.fire(
                        Array.isArray(error) ? error[0] : `Não foi possivel cadastrar o Pagamento`);
                        reset();
                    }  
            }
        });
    return <>
        <Modal size='lg' centered={true} show={exibirModal} onHide={toggleModal}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title titulo-modal">Trocar senha</h5>
                    <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                        <i className="fas fa-times" style={{ color: '#5E6397' }}></i>
                    </div>
                </div>
                <div className="modal-body">
                    <form className="row g-3" autoComplete="off">
                        <div className="col-md-6 mb-5">
                            <label htmlFor="form-senha" className="form-label">Senha</label>
                            <input {...formik.getFieldProps('senha')} placeholder="senha" type="password" className={clsx(
                                'form-control',
                                {
                                    'is-invalid': formik.touched.senha && formik.errors.senha,
                                },
                                {
                                    'is-valid': formik.touched.senha && !formik.errors.senha,
                                }
                            )} id="form-senha" />
                            {formik.touched.senha && formik.errors.senha && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.senha}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="col-md-6 mb-5">
                            <label htmlFor="form-confirmacaoSenha" className="form-label">Confirmação de senha</label>
                            <input {...formik.getFieldProps('confirmacaoSenha')} placeholder="Confirmação Senha" type="password" className={clsx(
                                'form-control',
                                {
                                    'is-invalid': formik.touched.confirmacaoSenha && formik.errors.confirmacaoSenha,
                                },
                                {
                                    'is-valid': formik.touched.confirmacaoSenha && !formik.errors.confirmacaoSenha,
                                }
                            )} id="form-confirmacaoSenha" />
                            {formik.touched.confirmacaoSenha && formik.errors.confirmacaoSenha && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.confirmacaoSenha}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </form>
                </div>
                <div className="modal-footer" style={{ margin: '0 auto' }}>
                    <button disabled={formik.isSubmitting} onClick={() => reset()} type="button" className="btn btn-danger me-5"> VOLTAR </button>
                    <button disabled={formik.isSubmitting || !formik.isValid } onClick={() => { formik.submitForm() }} type="button" className="btn btn-success ms-5">
                        {!formik.isSubmitting && <> TROCAR SENHA </>}
                        {formik.isSubmitting && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                Aguarde...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                </div>
            </div>
        </Modal>
    </>
}

export default TrocarSenhaModal;