import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Modal, Spinner } from "react-bootstrap-v5";
import { useFormik } from "formik";
import FormInputSelect, { FormInputMultiSelectItems } from "../../../components/FormInputSelect";
import * as Yup from 'yup';
import BotaoSubmitFormulario from "../../../components/BotaoSubmitFormulario";
import FormikErrorText from "../../../components/FormikErrorText";
import AdministradorService from "../../../../services/AdministradorService";
import ModalHeader from "../../../components/modal/ModalHeader";
import Loading from "../../../components/loading/Loading";
import BotaoCancelar from "../../../components/BotaoCancelar";
import ModalContent from "../../../components/modal/ModalContent";
import ModalBody from "../../../components/modal/ModalBody";
import ModalFooter from "../../../components/modal/ModalFooter";

interface IPedidoCreditoDetalheModalProps {
    toggleModal: () => void,
    exibirModal: boolean,
    grupoItens: FormInputMultiSelectItems[];
}

const ImportarPedidoModal = ({toggleModal, exibirModal, grupoItens}: IPedidoCreditoDetalheModalProps) =>{
    const [loadingInicial, setLoadingInicial] = useState<boolean>(false)
    
    const valoresIniciais = {
        gerarApenasCartoes: false,
        file: '',
        idGrupoEmpresarial: ''
      } as any;
      
      const importarPedidoValidation = Yup.object().shape({
        idGrupoEmpresarial: Yup.string().required('Grupo Empresarial é obrigatório'),
        file: Yup.string().required('Arquivo é obrigatório')
      })

      const formik = useFormik({
        initialValues: valoresIniciais,
        validationSchema: importarPedidoValidation,
        onSubmit: async (values) => {
          try {
       
            if (values.file === "" || values.file === undefined){
              Swal.fire({
                icon: 'error',
                title: 'Arquivo é obrigatório',
                text: 'Por favor selecione um arquivo.',
                showConfirmButton: true,
              })
              return;
            }
            if (values.file.type !== "text/plain"){
              Swal.fire({
                icon: 'error',
                title: 'Formato do arquivo inválido',
                text: 'Por favor envie arquivo no formato texto (.txt).',
                showConfirmButton: true,
              })
              return;
            }
            
            const formData = new FormData();
            formData.append("file", values.file);
            formData.append("GerarApenasCartoes", values.gerarApenasCartoes);

            await AdministradorService.importarArquivoPedido(values.idGrupoEmpresarial, formData);

            Swal.fire({
              icon: 'success',
              text: `Arquivo carregado com sucesso`,
              showConfirmButton: true,
            })
            
            limparEFecharModal();
          
          } catch (error) {
            Swal.fire(
              Array.isArray(error) ? error[0] : `Não foi possivel cadastrar o Pedido de crédito`
              );
          } finally {
            setLoadingInicial(false)
          } 
        }
      });

      function limparEFecharModal(){
        formik.resetForm();
        toggleModal();
    }
    
    return <>
        <Modal size='lg' centered={true} show={exibirModal} onHide={limparEFecharModal} >
          <ModalContent>
            <ModalHeader 
              title="Importar Pedido"
              closeFunction={limparEFecharModal}
            />

            <ModalBody>
              <Loading isLoading={loadingInicial}>
                <form className="d-flex flex-column" autoComplete="off" onSubmit={e => e.preventDefault()}>
                  <FormInputSelect 
                    disabled={formik.isSubmitting} 
                    values={grupoItens} 
                    formik={formik} 
                    className="col col-md-4" 
                    propName='idGrupoEmpresarial' 
                    label='Grupo' 
                    required={true}/>
                    
                    <FormikErrorText 
                      formik={formik}
                      name="idGrupoEmpresarial"
                    />

                    <label className='form-label fw-bold mt-4'>Selecione o arquivo</label>
                    <input className="form-control" name="file" type="file" onChange={(event) =>{ 
                        // @ts-ignore: Object is possibly 'null'
                        formik.setFieldValue("file", event.currentTarget.files[0]);
                    }} />

                    <FormikErrorText 
                      formik={formik}
                      name="file"
                    />
                    
                    <label className='form-label fw-bold mt-2' role="button">
                      <span className="me-2">Gerar Apenas Cartões</span>
                      <input 
                          className=""
                          type="checkbox" 
                          name="gerarApenasCartoes" 
                          checked={formik.values.gerarApenasCartoes}
                          onChange={() => formik.setFieldValue("gerarApenasCartoes", !formik.values.gerarApenasCartoes)} 
                      />
                    </label>
                  </form>
              </Loading>
            </ModalBody>
            <ModalFooter>
              <BotaoCancelar 
                onClick={limparEFecharModal}
                disabled={formik.isSubmitting} 
              />
              <BotaoSubmitFormulario formik={formik} />
            </ModalFooter>
          </ModalContent>
      </Modal>
    </>
}

export default ImportarPedidoModal;