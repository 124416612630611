import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Modal, Spinner } from "react-bootstrap-v5";
import { IPedidoCreditoResponse } from "../../../../interfaces/responses/clientes/IPedidoCreditoResponse.1";
import AdministradorService from "../../../../services/AdministradorService";
import { IAdminConfirmarPedidoEmLote } from "../../../../interfaces/request/administrador/IAdminConfirmarPedidoEmLote";
import FormInputSelect, { FormInputMultiSelectItems } from "../../../components/FormInputSelect";
import { useFormik } from "formik";
import * as Yup from 'yup';
import FormikErrorText from "../../../components/FormikErrorText";
import ModalHeader from "../../../components/modal/ModalHeader";
import PedidoGrid from "../../../components/pedido/PedidoGrid";
import Loading from "../../../components/loading/Loading";
import BotaoAcao from "../../../components/BotaoAcao";
import BotaoCancelar from "../../../components/BotaoCancelar";
import ModalContent from "../../../components/modal/ModalContent";
import ModalBody from "../../../components/modal/ModalBody";
import ModalFooter from "../../../components/modal/ModalFooter";


interface IConfirmarPedidoEmLoteProps {
    toggleModal: () => void,
    exibirModal: boolean,
    grupoItens: FormInputMultiSelectItems[];
}

const ConfirmarPedidoEmLoteModal = ({toggleModal, exibirModal, grupoItens}: IConfirmarPedidoEmLoteProps) =>{
    useEffect(() => {
    }, [exibirModal])

    const [loadingInicial, setLoadingInicial] = useState<boolean>(false)
    const [pedidos, setPedidos] = useState<IPedidoCreditoResponse[]>([])
    const [atualizandoPedidos, setAtualizandoPedidos] = useState<boolean>(false);
    
    async function carregarPedidos(idGrupo: string){
        try {
            setLoadingInicial(true)
            const { data  } = await AdministradorService.obterPedidosCredito(idGrupo, '', '', 'Rascunho', '');
            setPedidos(data)
          
          } catch (error) {
            Swal.fire({
              icon: 'error',
              title: 'Não foi possivel carregar os Pedidos',
              text: 'Atualize a pagina, caso o erro continue entre em contato com o administrador.',
              showConfirmButton: true,
            })
          } finally {
            setLoadingInicial(false)
          } 
    }

    const valoresIniciais = {
        idGrupoEmpresarial: ''
      } as any;

      const importarPedidoValidation = Yup.object().shape({
        idGrupoEmpresarial: Yup.string().required('Grupo Empresarial é obrigatório'),
      })

      const formik = useFormik({
        initialValues: valoresIniciais,
        validationSchema: importarPedidoValidation,
        onSubmit: async (values) => {
            setAtualizandoPedidos(true);
            Swal.fire({
                title: 'Confirmar todos os pedidos?',
                html: `Confirmar os <strong>${pedidos.length}</strong> pedidos? Após confirmados os pedidos <span style='color: #dc3545'><strong>não podem ser alterados</strong><span>`,
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'VOLTAR',
                confirmButtonText: 'CONFIRMAR',
                reverseButtons: true,
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger'
                }
              }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                      const { data  } = await AdministradorService.confirmarPedidosEmLote({
                        idGrupo: values.idGrupoEmpresarial
                      } as IAdminConfirmarPedidoEmLote);
                      
                      Swal.fire({
                        icon: 'success',
                        text: `${pedidos.length} pedidos confirmados com sucesso!`,
                      });
                      
                      closeModal();
    
                    } catch (error) {
                      Swal.fire(
                        Array.isArray(error) ? error[0] : `Não foi possivel confirmar os pedidos`
                        );
                    }
                    finally{
                        setAtualizandoPedidos(false);
                    }
                }
                else{
                    setAtualizandoPedidos(false);
                }
              })
        }
      });

      function closeModal(){
        formik.resetForm();
        setPedidos([]);
        toggleModal();
      }
      
    return <>
         <Modal size='xl' centered={true} show={exibirModal} onHide={closeModal}>
            <ModalContent>
              <ModalHeader 
                  title="Confirmar pedidos em lote"
                  closeFunction={closeModal}
              />
              <ModalBody>
                <Loading
                  isLoading={loadingInicial}>
                  <FormInputSelect 
                    disabled={formik.isSubmitting} 
                    values={grupoItens} 
                    formik={formik} 
                    className="col col-md-4" 
                    propName='idGrupoEmpresarial' 
                    label='Grupo' 
                    required={true}/>
                                
                  <FormikErrorText 
                      formik={formik}
                      name="idGrupoEmpresarial"
                      />
              
                  <BotaoAcao 
                      className="btn btn-success mt-2 me-2"
                      disabled={atualizandoPedidos || !formik.values['idGrupoEmpresarial']}
                      labelLoading="Aguarde"
                      onClick={() => carregarPedidos(formik.values['idGrupoEmpresarial'])}
                      label="Buscar pedidos"
                      isLoading={atualizandoPedidos || formik.isSubmitting}
                  />

                  <PedidoGrid pedidos={pedidos} />
                </Loading>
              </ModalBody>
              <ModalFooter>
                <BotaoCancelar 
                    onClick={closeModal}
                    disabled={atualizandoPedidos}
                />
                
                <BotaoAcao 
                  disabled={atualizandoPedidos || pedidos.length === 0}
                  labelLoading="Aguarde"
                  onClick={formik.submitForm}
                  label={`CONFIRMAR (${pedidos.length})`}
                  isLoading={atualizandoPedidos || formik.isSubmitting}
                />
              </ModalFooter>
            </ModalContent>
        </Modal>
    </>
}

export default ConfirmarPedidoEmLoteModal;