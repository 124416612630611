import React, { ChangeEvent, useEffect, useState } from "react"
import { Modal, Spinner } from "react-bootstrap-v5";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Swal from "sweetalert2";
import clsx from "clsx";
import { IFinanceiroEditarCredenciado } from "../../../interfaces/request/financeiro/IFinanceiroEditarCredenciado";
import FinanceiroService from "../../../services/FinanceiroService";
import { IFinanceiroCredenciado } from "../../../interfaces/responses/financeiro/IFinanceiroCredenciado";
import BotaoSubmitFormulario from "../../components/BotaoSubmitFormulario";
import ExibirCredenciado from "./components/ExibirCredenciado";
import { IFinanceiroGrupoEmpresarial } from "../../../interfaces/responses/financeiro/IFinanceiroGrupoEmpresarial";
import FormInputSelect from "../../components/FormInputSelect";
import { TipoGrupoEmpresarial } from "../../../utils/Constants";

interface CredenciadoModalEditarProps {
    toggleModal: (refresh: boolean) => void,
    exibirModal: boolean,
    credenciado: IFinanceiroCredenciado
}

const CredenciadoModalEditar = ({ toggleModal, exibirModal, credenciado }: CredenciadoModalEditarProps) => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [gruposEmpresariais, setGruposEmpresarias] = useState<IFinanceiroGrupoEmpresarial[]>([]);
   
    useEffect(() => {
        formik.resetForm({
            values: {
                responsavel: credenciado?.responsavel ?? '',
                telefone: credenciado?.telefone ?? '',
                email: credenciado?.email ?? '',
                diaPagamento: credenciado?.diaPagamento ?? 1,
                taxa: credenciado?.taxa ?? 0.1,
                idGrupoEmpresarial: credenciado?.idGrupoEmpresarial ?? ''
            }
        });
      }, [credenciado]);
      
      useEffect(() => {
        carregarGrupos();
      }, [])

    async function carregarGrupos(){
        try {
            setLoading(true)
            
            const { data } = await FinanceiroService.obterGruposEmpresariais()
    
            setGruposEmpresarias(data);
    
            } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Não foi possivel carregar os grupos',
                text: 'Atualize a pagina, caso o erro continue entre em contato com o administrador.',
                showConfirmButton: true,
            })
            } finally {
                setLoading(false)
            } 
    }

    const credenciadoValidations = Yup.object().shape({
        responsavel: Yup.string().max(100, 'Maximo 100 caracteres'),
        telefone: Yup.string().min(10).max(11).matches(/^\d{10,11}$/, 'Informe apenas números'),
        email: Yup.string().email('Informe um email válido'),
        diaPagamento: Yup.number().min(0, 'Mínimo 0').max(31, 'Máximo 31'),
        taxa: Yup.number().min(0, 'Mínimo 0').max(100, 'Máximo 100')
    })
    
    const valoresIniciais = {
        responsavel: credenciado.responsavel,
        telefone: credenciado.telefone,
        email: credenciado.email,
        diaPagamento: credenciado.diaPagamento,
        taxa: credenciado.taxa,
        idGrupoEmpresarial: credenciado.idGrupoEmpresarial
    } as any;
    
    const formik = useFormik({
        initialValues: valoresIniciais,
        validationSchema: credenciadoValidations,
        onSubmit: async (values) => {
            try {
                setLoading(true);
                
                let credenciadoToUpdate: IFinanceiroEditarCredenciado =
                {
                    id: credenciado.id,
                    responsavel: values.responsavel,
                    telefone: values.telefone,
                    email: values.email,
                    diaPagamento: values.diaPagamento,
                    taxa: values.taxa,
                    idGrupoEmpresarial: values.idGrupoEmpresarial
                }

                let data = await FinanceiroService.editarCredenciado(credenciadoToUpdate);

                Swal.fire({
                            icon: 'success',
                            text: `Credenciado alterado com sucesso`,
                            showConfirmButton: true,
                        })

                limparFechar(true);
            } catch (error) {

                Swal.fire(
                     Array.isArray(error) ? error[0] : `Falha ao cadastrar, tente novamente`
                );
            }
            finally {
                setLoading(false);
            } 
        }
    });

    function closeModal(){
        if(!formik.dirty){
            limparFechar();
            return;
        }
        Swal.fire({
            title: 'Deseja fechar a tela de edição?',
            text: `Ao sair você perderá todas as alterações pendentes`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'CONTINUAR',
            confirmButtonText: 'SAIR',
            reverseButtons: true,
            customClass: {
                confirmButton: 'btn btn-danger',
                cancelButton: 'btn btn-primary'
            }
            
          }).then((result) => {
            if (result.isConfirmed) {
                limparFechar()
            }
        })
    }

    function limparFechar(refresh: boolean = false) {
        formik.resetForm();
        toggleModal(refresh);
    }

    return <>
        <Modal size='xl' centered={true} show={exibirModal} onHide={closeModal}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title titulo-modal">Editar Credenciado</h5>
                    <div onClick={() => closeModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                        <i className="fas fa-times" style={{ color: '#5E6397' }}></i>
                    </div>
                </div>
                <div className="modal-body">
                {isLoading ? (
                    <Spinner animation='border' />
                    ) : (
                    <>
                        <ExibirCredenciado 
                            credenciado={credenciado}
                        />
                        <form className="row">
                            <h3>Informações Financeiras</h3>   
                            <div className="col-md-2 mb-5">
                                <label htmlFor="form-responsavel" className="form-label">Responsável</label>
                                <input {...formik.getFieldProps('responsavel')} placeholder="Responsável" type="text" className={clsx(
                                    'form-control',
                                    {
                                        'is-invalid': formik.touched.responsavel && formik.errors.responsavel,
                                    },
                                    {
                                        'is-valid': formik.touched.responsavel && !formik.errors.responsavel,
                                    }
                                )} id="form-responsavel" />
                                {formik.touched.responsavel && formik.errors.responsavel && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.responsavel}</span>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="col-md-2 mb-5">
                                <label htmlFor="form-telefone" className="form-label">Telefone</label>
                                <input {...formik.getFieldProps('telefone')} placeholder="Telefone" type="tel" className={clsx(
                                    'form-control',
                                    {
                                        'is-invalid': formik.touched.telefone && formik.errors.telefone,
                                    },
                                    {
                                        'is-valid': formik.touched.telefone && !formik.errors.telefone,
                                    }
                                )} id="form-telefone" />
                                {formik.touched.telefone && formik.errors.telefone && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.telefone}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            
                            <div className="col-md-4 mb-5">
                                <label htmlFor="form-email" className="form-label">E-mail</label>
                                <input {...formik.getFieldProps('email')} placeholder="E-mail" type="email" className={clsx(
                                    'form-control',
                                    {
                                        'is-invalid': formik.touched.email && formik.errors.email,
                                    },
                                    {
                                        'is-valid': formik.touched.email && !formik.errors.email,
                                    }
                                )} id="form-email" />
                                {formik.touched.email && formik.errors.email && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.email}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            
                            <div className="col-md-2 mb-5">
                                <label htmlFor="form-diaPagamento" className="form-label">Dia Pagamento</label>
                                <input {...formik.getFieldProps('diaPagamento')} placeholder="diaPagamento" type="number" className={clsx(
                                    'form-control',
                                    {
                                        'is-invalid': formik.touched.diaPagamento && formik.errors.diaPagamento,
                                    },
                                    {
                                        'is-valid': formik.touched.diaPagamento && !formik.errors.diaPagamento,
                                    }
                                )} id="form-diaPagamento" />
                                {formik.touched.diaPagamento && formik.errors.diaPagamento && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.diaPagamento}</span>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="col-md-2 mb-5">
                                <label htmlFor="form-taxa" className="form-label">Taxa</label>
                                <input {...formik.getFieldProps('taxa')} placeholder="taxa" type="number" className={clsx(
                                    'form-control',
                                    {
                                        'is-invalid': formik.touched.taxa && formik.errors.taxa,
                                    },
                                    {
                                        'is-valid': formik.touched.taxa && !formik.errors.taxa,
                                    }
                                )} id="form-taxa" />
                                {formik.touched.taxa && formik.errors.taxa && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.taxa}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <FormInputSelect disabled={formik.isSubmitting} values={
                                gruposEmpresariais
                                .filter(v=> v.tipo !== TipoGrupoEmpresarial.CLIENTE)
                                .map(grupo => ({
                                    value: grupo.id,
                                    label: grupo.nome,
                                }))
                            } formik={formik} className="col col-md-3" 
                            propName='idGrupoEmpresarial' label='Grupo Credenciado' loading={isLoading} />
                        </form>
                    </>
                )
                }
                </div>
                <div className="modal-footer" style={{ margin: '0 auto' }}>
                    <button  onClick={() => closeModal()} type="button" className="btn btn-secondary me-5"> VOLTAR </button>
                    <BotaoSubmitFormulario 
                        formik={formik}
                    />
                </div>
            </div>
        </Modal>
    </>
}

export default CredenciadoModalEditar;