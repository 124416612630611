import React, { useState } from "react"
import { ICartaoResponse } from "../../../interfaces/responses/clientes/ICartaoResponse"
import { Modal } from "react-bootstrap-v5"
import GridPadrao from "../../components/GridPadrao"
import Swal from "sweetalert2"
import ClienteService from "../../../services/ClienteService"
import { ISolicitarSegundaViaCartao } from "../../../interfaces/request/clientes/IResetarSenhaCartao"
import { useHistory } from "react-router-dom"

interface FuncionarioModalCartaoProps {
    toggleModal: () => void,
    exibirModal: boolean,
    cartao: ICartaoResponse,
}

const FuncionarioModalCartao = ({ toggleModal, exibirModal, cartao }: FuncionarioModalCartaoProps) => {
    const [isLoading, setLoading] = useState<boolean>(false)
    const history = useHistory();
    const [cancelarCartao, setCancelarCartao] = useState<boolean>(false)
    
    const colunasCartao = [
        {
          name: 'Número',
          selector: (row: ICartaoResponse) => row['numero'],
          sortable: false,
          wrap: false,
        },
        {
            name: 'Senha Carta Berço',
            selector: (row: ICartaoResponse) => row['senhaCartaBerco'],
            sortable: false,
            wrap: false,
        },
        {
            name: 'Situação',
            selector: (row: ICartaoResponse) => row['status'],
            sortable: false,
            wrap: false,
        },
        {
            name: 'Saldo',
            selector: (row: ICartaoResponse) => row['saldoCartao'],
            sortable: false,
            wrap: false,
        },
        {
            name: 'Bloqueado',
            selector: (row: ICartaoResponse) => row['bloqueado'] ? 'SIM' : 'NÃO',
            sortable: false,
            wrap: false,
        },
        {
            name: 'Bloqueio QrCode',
            selector: (row: ICartaoResponse) => row['bloqueioQrCode'] ? 'SIM' : 'NÃO',
            sortable: false,
            wrap: false,
        }
    ]

    async function solicitarSegundaViaCartao(){

        const cancelarCartaoText = `Deseja cancelar o <strong class='text-danger'>cartão</strong>?
            <br/>Essa operação irá cancelar o <strong class='text-danger'>cartão atual</strong>,
            <strong class='text-danger'>gerar um novo cartão</strong> e 
            <strong class='text-danger'>transferir o saldo do cartão antigo para o novo</strong>, tem certeza?`;
        const apenasSegundaViaCartaoText = `Deseja solicitar a segunda via do <strong class='text-danger'>cartão</strong>?
            <br/>Essa operação irá alterar o <strong class='text-danger'>status do cartão</strong>, 
            <strong class='text-danger'>bloquear os pagamentos</strong> e 
            <strong class='text-danger'>resetar a senha do cartão</strong>, tem certeza?`;

        Swal.fire({
            title: 'Solicitar segunda via do cartão?',
            html: cancelarCartao ? cancelarCartaoText : apenasSegundaViaCartaoText,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'NÃO',
            confirmButtonColor: '#28a745',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'SIM'
            
          }).then(async (confirmResult) => {
            try{
                if (confirmResult.isConfirmed) {
                    setLoading(true)

                    let result = await ClienteService.solicitarSegundaViaCartao({ 
                                                                            idCartao: cartao.id,
                                                                            idCliente: cartao.cliente.id,
                                                                            idFuncionario: cartao.funcionario.id,
                                                                            gerarNovoCartao: cancelarCartao
                                                                          } as ISolicitarSegundaViaCartao);
                    Swal.fire({
                        icon: 'success',
                        title: 'Segunda via solicitada com sucesso!',
                        html: `Você ainda precisa emitir um novo lote para esse cartão.
                        Clique no botão "+ Novo Lote".<br/>
                        <strong class='text-danger'>Se essa etapa não for realizada, o cartão não sera emitido.</strong><br/>`,
                        showConfirmButton: true,
                        confirmButtonColor: '#28a745',
                        confirmButtonText: 'ENTENDI'
                    })

                    history.push('/cliente/lotes-cartao')
                }
            }catch (error : any) {
                  Swal.fire(
                    Array.isArray(error) ? error[0] : `Não foi possível solicitar a segunda via do cartão`
                    );
                    setLoading(false);
                    toggleModal();
            }
        })
    }

    return <>
        <Modal size='lg' centered={true} show={exibirModal} onHide={toggleModal}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title titulo-modal">Segunda via cartão</h5>
                    <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                        <i className="fas fa-times" style={{ color: '#5E6397' }}></i>
                    </div>
                </div>
                <div className="modal-body">
                    <h4>Cartão</h4>
                    <div>
                        {cartao ? 
                            (<GridPadrao paginar={false} colunas={colunasCartao} tipo='Cartão' itens={[cartao]} />) : (<></>)
                        }
                    </div>
                    
                </div>
                <div className="modal-footer" style={{ margin: '0 auto' }}>
                    <button  onClick={() => toggleModal()} type="button" className="btn btn-secondary me-5"> VOLTAR </button>
                    
                    <button type="button" 
                        className="btn btn-primary ms-5" 
                        disabled={isLoading} 
                        onClick={() => solicitarSegundaViaCartao()}>
                            SOLICITAR 2ª VIA
                    </button>
                    <div className="form-check form-switch">
                        <input 
                            className="form-check-input" 
                            type="checkbox" 
                            onChange={() => setCancelarCartao(!cancelarCartao)}
                        />
                        <label className="form-check-label" htmlFor="cancelar">
                        Gerar um novo cartão e transferir o saldo
                        </label>
                    </div>
                </div>
            </div>
        </Modal>
    </>
}

export default FuncionarioModalCartao;