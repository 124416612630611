import React, { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { FallbackView } from '../../_metronic/partials'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { Funcionarios } from '../pages/clientes/funcionario/Funcionarios'
import { PedidosCredito } from '../pages/clientes/pedidos-credito/PedidosCredito'
import { Perfil } from '../pages/usuario/Perfil'
import { RelatorioUtilizacao } from '../pages/clientes/relatorio-utilizacao/RelatorioUtilizacao'
import { LotesCartao } from '../pages/clientes/lotes-cartao/LotesCartao'
import { Credenciados } from '../pages/clientes/credenciado/Credenciados'
import { RelatorioRecarga } from '../pages/clientes/relatorio-recarga/RelatorioRecarga'
import { ValidarArquivoPedido } from '../pages/clientes/pedidos-credito/ValidarArquivoPedido'
import { FuncionarioExtrato } from '../pages/clientes/funcionario/FuncionarioExtrato'

export function ClienteRoutes() {

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route exact path='/usuario/perfil' component={Perfil} />
        <Route exact path='/cliente/funcionarios' component={Funcionarios} />
        <Route exact path='/cliente/funcionarios/extrato/:cpf' component={FuncionarioExtrato} />
        <Route exact path='/cliente/pedidos-credito' component={PedidosCredito} />
        <Route exact path='/cliente/lotes-cartao' component={LotesCartao} />
        <Route exact path='/relatorio-utilizacao' component={RelatorioUtilizacao} />
        <Route exact path='/cliente-credenciado' component={Credenciados} />
        <Route exact path='/relatorio-recarga' component={RelatorioRecarga} />
        <Route exact path='/cliente/validar-arquivo-pedido' component={ValidarArquivoPedido} />
        <Redirect from='/' to='/cliente/funcionarios' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
