import clsx from "clsx";
import { FormikValues } from "formik";
import Select, { StylesConfig } from 'react-select';

interface FormInputMultiSelectProps {
    formik: FormikValues;
    label: string;
    propName: string;
    className: string;
    required?: boolean;
    values: FormInputMultiSelectItems[];
    disabled?: boolean;
    loading?: boolean;
    labelIsVisible?: boolean;
}

export interface FormInputMultiSelectItems {
    value: string;
    label: string;
}

const customStyles: StylesConfig<any, false> = {
    control: (provided: any, state: { isFocused: boolean }) => ({
      ...provided,
      borderColor: state.isFocused ? '#B5B5C3' : '#E4E6EF',
      boxShadow: 'none !important',
      borderRadius: '0.475rem',
      padding: '0.175rem 0.75rem',
      fontSize: '1.1rem',
      fontWeight: '500',
      lineHeight: '1.5',
      color: '#181C32',
      border: '1px solid #E4E6EF',
      backgroundColor: '#ffffff',
      transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;',
      appearance: 'none'
    })
  };
  
const FormInputSelect = ({
    formik,
    label,
    propName,
    className,
    required,
    values,
    disabled,
    loading,
    labelIsVisible = true,
  }: FormInputMultiSelectProps) => {
    return (
        <div className={className}>
            {labelIsVisible && <label htmlFor={propName} className={clsx('form-label fw-bold', required ? 'required' : '')}>{label}</label>}
            
            <Select 
            isDisabled={disabled} 
            placeholder="Selecione" 
            options={values} 
            onBlur={
                async () => {
                    await formik.setFieldTouched(propName, true);
                }
            } 
            onChange={async (e) => {                                                        
                await formik.setFieldValue(propName, e?.value);
                await formik.setFieldTouched(propName, true);
            }} 
            value={
                values.find(option => option.value === formik.values[propName])
                || null
              }
            isClearable={true}
            isLoading={loading}
            styles={customStyles}
            />                    
        </div>
    )
}

export default FormInputSelect;