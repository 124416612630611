import ReactDatePicker, { registerLocale } from "react-datepicker";
import ptBR from 'date-fns/locale/pt-BR';
registerLocale('ptBR', ptBR)

interface InputDatePickerProps {
    id?: string,
    name?: string
    className?: string,
    disabled?: boolean
    selected?: Date | null,
    onChange: (date: Date) => void,
    placeHolderText?: string,
    dateFormat?: 'dd/MM/yyyy' | 'MM/yyyy'
}

export default function InputDatePicker({ className, selected, onChange, id, name, disabled, placeHolderText, dateFormat = 'dd/MM/yyyy' }: InputDatePickerProps) {
    return <ReactDatePicker 
            showMonthYearPicker={dateFormat === 'MM/yyyy'} 
            showFullMonthYearPicker={dateFormat === 'MM/yyyy'} 
            disabled={disabled} 
            placeholderText={!placeHolderText && dateFormat === 'dd/MM/yyyy' ? 'DD/MM/AAAA' : 'MM/AAAA'} 
            id={id} 
            name={name} 
            dateFormat={dateFormat} 
            locale="ptBR" 
            className={className} 
            selected={selected} 
            onChange={onChange} />
}