import React, { ChangeEvent, useState } from "react"
import { Modal } from "react-bootstrap-v5";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useEffect } from "react";
import clsx from "clsx";
import Swal from "sweetalert2";
import { useAuth } from "../../../contexts/AuthProvider";
import { IMovimento } from "../../../interfaces/responses/credenciados/IMovimento";
import CredenciadoService from "../../../services/CredenciadoService";
import { IMovimentoRequest } from "../../../interfaces/request/credenciados/IMovimento";

interface IMovimentoDetalhesModalProps { 
    toggleModal: () => void, 
    exibirModal: boolean, 
    movimento: IMovimento | undefined, 
    titulo: string 
}

const MovimentosDetalhesModal = ({ toggleModal, exibirModal, movimento, titulo }: IMovimentoDetalhesModalProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    useEffect(() => {
        // eslint-disable-next-line
    }, [exibirModal]);

    async function estornar(){
            Swal.fire({
                title: 'Estornar movimento?',
                text: `Esta operação não pode ser desfeita, deseja continuar?`,
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'NÃO',
                confirmButtonColor: '#28a745',
                cancelButtonColor: '#dc3545',
                confirmButtonText: 'SIM'
                
              }).then(async (confirmResult) => {
                try{
                    if (confirmResult.isConfirmed) {
                        setIsLoading(true);
                        let result = await CredenciadoService.estornarMovimento(movimento?.idCredenciado || '', { id: movimento?.id, nsu: movimento?.nsu} as IMovimentoRequest);
                        Swal.fire({
                            icon: 'success',
                            title: 'Estorno realizado com sucesso',
                            text: 'Movimento estornado',
                            showConfirmButton: true,
                        })
                        setIsLoading(false);
                        toggleModal();
                    }
                }catch (error : any) {
                      Swal.fire(
                        Array.isArray(error) ? error[0] : `Não foi possível estornar o movimento`
                        );
                        setIsLoading(false);
                        toggleModal();
                }
            })
    }

    async function confirmar(){
            Swal.fire({
                title: 'Confirmar movimento?',
                text: `Esta operação não pode ser desfeita, deseja continuar?`,
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'NÃO',
                confirmButtonColor: '#28a745',
                cancelButtonColor: '#dc3545',
                confirmButtonText: 'SIM'
                
              }).then(async (confirmResult) => {
                try{
                    if (confirmResult.isConfirmed) {
                        setIsLoading(true);
                        let result = await CredenciadoService.confirmarMovimento(movimento?.idCredenciado || '', { id: movimento?.id, nsu: movimento?.nsu} as IMovimentoRequest);
                        Swal.fire({
                            icon: 'success',
                            title: 'Sucesso',
                            text: 'Movimento confirmado com sucesso',
                            showConfirmButton: true,
                        })
                        setIsLoading(false);
                        toggleModal();
                    }
                }catch (error : any) {
                      Swal.fire(
                        Array.isArray(error) ? error[0] : `Não foi possível confirmar o movimento`
                        );
                        setIsLoading(false);
                      toggleModal();
                }
            })
    }
    return <>
        <Modal size='lg' centered={true} show={exibirModal} onHide={toggleModal}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title titulo-modal">{titulo}</h5>
                    <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                        <i className="fas fa-times" style={{ color: '#5E6397' }}></i>
                    </div>
                </div>
                <div className="modal-body">
                    <form className="row g-3">
                        <div className="col-md-6 mb-5">
                        <label htmlFor="form-nome" className="form-label">Data transação</label>
                        <p>{ movimento?.dataFormatada }</p>
                        </div>
                        <div className="col-md-3">
                        <label htmlFor="form-nome" className="form-label">Descrição</label>
                        <p>{ movimento?.descricao }</p>
                        </div>
                        <div className="col-md-3">
                        <label htmlFor="form-nome" className="form-label">NSU</label>
                        <p>{ movimento?.nsu }</p>
                        </div>
                        <div className="col-md-3">
                        <label htmlFor="form-nome" className="form-label">Valor</label>
                        <p>{ movimento?.valorFormatado }</p>
                        </div>
                        <div className="col-md-3">
                        <label htmlFor="form-nome" className="form-label">Autorizador</label>
                        <p>{ movimento?.autorizador }</p>
                        </div>
                        <div className="col-md-3">
                        <label htmlFor="form-nome" className="form-label">Status</label>
                        <p>{ movimento?.status }</p>
                        </div>
                        <div className="col-md-3">
                        <label htmlFor="form-nome" className="form-label">Referência</label>
                        <p>{ movimento?.referencia }</p>
                        </div>
                    </form>
                </div>
                <div className="modal-footer" style={{ margin: '0 auto' }}>
                    <button  onClick={() => toggleModal()} type="button" className="btn btn-danger me-5"> VOLTAR </button>
                    {
                        (movimento?.status === 'Confirmado' || movimento?.status === 'Pendente') 
                        && movimento?.tipo === 'Debito' && movimento.autorizador != 'Cartao' ? 
                        (<>
                            <button disabled={isLoading} onClick={() => estornar()} type="button" className="btn btn-warning ms-5">
                                {!isLoading && <> ESTORNAR TRANSAÇÃO </>}
                                {isLoading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        Salvando...
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </>) : (<></>) 
                    }
                    {
                        movimento?.status === 'Pendente' && movimento.autorizador != 'Cartao' ? 
                        (<>
                            <button disabled={isLoading} onClick={() => confirmar()}  type="button" className="btn btn-success ms-5">
                                {!isLoading && <> CONFIRMAR TRANSAÇÃO </>}
                                {isLoading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        Salvando...
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </>) : 
                        (<></>)
                    }
                     
                </div>
                {
                    movimento?.autorizador === 'Cartao' ?
                    (<>
                        <div className="alert alert-info mb-3" role="alert" style={{width: '90%', margin: '0 auto'}}>
                            <strong>Estorno</strong> e <strong>Confirmação</strong> de movimentos autorizados pelo cartão, devem ser realizados pelo <strong>sistema integrador</strong>.
                        </div>   
                    </>) : 
                    (<></>)
                }
            </div>
        </Modal>
    </>
}

export default MovimentosDetalhesModal;