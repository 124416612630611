import axios from "axios";
import StorageUtils from "../utils/StorageUtils";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "https://api-painel.develop.qbcard.com.br/api/",
  // baseURL: 'https://localhost:44377/api/'
});

api.interceptors.request.use(
  async (config: any) => {
    config.headers['Access-Control-Allow-Origin'] = '*';
    config.headers['Content-Type'] = 'application/json';

    const accessToken = await StorageUtils.getToken();

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`
    }

    return config
  },
  (err: any) => {
    Promise.reject(err)
  }
)

api.interceptors.response.use((response) => {
  return response;
}, async error => {
  
  if (error.response.status === 401) {
    await StorageUtils.clearStorage();
    window.location.reload();
  }

  if (error.response.data && !error.response.data.success) {
    return Promise.reject(error.response.data.errors)
  } 

  if (error.message) {
    return Promise.reject([error.message]);
  } 

  return Promise.reject(error)
})

export default api;
