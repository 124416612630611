import { useState } from "react";
import { useAuth } from "../../contexts/AuthProvider";
import GridPadrao from "../components/GridPadrao";
import HeaderContent from "../components/HeaderContent";
import TrocarSenhaModal from "./TrocarSenhaModal";

export function Perfil() {
    const [exibirModal, setExibirModal] = useState<boolean>(false)
    const { usuario } = useAuth();
    const permissoes = usuario?.permissoes || [];

    function toggleModal(): void {
        setExibirModal(!exibirModal);
    }

    const colunas = [
        {
          name: 'Permissão',
          selector: (row: string) => row,
          sortable: true,
          wrap: true
        }
    ]
    return (
        <>
        <TrocarSenhaModal 
            exibirModal={exibirModal}
            toggleModal={toggleModal}
        />

         <HeaderContent title='Meus dados'>
           
        </HeaderContent>

        <div className="card">
            <div className="card-body">
                <h5 className="card-title">Dados do usuário</h5>
                <ul className="list-group list-group-flush">
                    <li className="list-group-item">Login: {usuario?.login}</li>
                    <li className="list-group-item">E-mail: {usuario?.email}</li>
                    <li className="list-group-item">Perfil: {usuario?.perfil}</li>
                </ul>
                {permissoes.length === 0 ? (<></>): (
                    <>
                        <hr />
                        <h5>Permissões</h5>
                        <GridPadrao colunas={colunas} tipo='Permissões' itens={permissoes} />
                    </>
                )}
            </div>
            <div className="card-footer">
                <button onClick={() => {
                    setExibirModal(true)
                }} className="btn btn-primary">Trocar senha</button>
            </div>
        </div>
        </>
    )
}