import React, { createContext, useState, useEffect, useContext } from 'react';
import { ICliente, ICredenciado, IGrupoEmpresarial, IUsuarioAutenticacao } from '../interfaces/responses/IAutenticacao';
import AuthService from '../services/AuthService';
import Storage from '../utils/StorageUtils';

interface IAuthContext {
    isAuthorized: Boolean,
    loading: Boolean,
    usuario: IUsuarioAutenticacao | undefined,
    token: string | null,
    credenciados: ICredenciado[] | undefined,
    clientes: ICliente[] | undefined,
    grupoEmpresarial: IGrupoEmpresarial | undefined,
    signIn: any,
    signOut: any
}

const AuthContext = createContext<IAuthContext | null>(null);

export const AuthProvider = ({ children }: any) => {
    const [isAuthorized, setAuthorized] = useState<boolean>(false);
    const [token, setToken] = useState<string | null>(null);
    const [usuario, setUsuario] = useState<IUsuarioAutenticacao | undefined>();
    const [loading, setLoading] = useState<boolean>(true);
    const [credenciados, setCredenciados] = useState<ICredenciado[] | undefined>();
    const [clientes, setClientes] = useState<ICliente[] | undefined>();
    const [grupoEmpresarial, setGrupoEmpresarial] = useState<IGrupoEmpresarial | undefined>();

    useEffect(() => {
        async function loadStorageData() {
            enableSplashScreen();
            const storageToken =  await Storage.getToken();
            const storagedUsuario = await Storage.getUsuario();
            const credenciados = await Storage.getCredenciados();
            const clientes = await Storage.getClientes();
            const grupoEmpresarial = await Storage.getGrupoEmpresarial();

            if (storagedUsuario && storageToken) {
                setToken(storageToken);
                setUsuario(storagedUsuario);
                setCredenciados(credenciados);
                setClientes(clientes);
                setAuthorized(true);
                setGrupoEmpresarial(grupoEmpresarial);
            }

            setLoading(false);
            disableSplashScreen();
        }

        loadStorageData();
    }, []);

    const enableSplashScreen = () => {
        const splashScreen = document.getElementById('splash-screen')
        if (splashScreen) {
            splashScreen.style.setProperty('display', 'flex')
        }
    }

    const disableSplashScreen = () => {
        const splashScreen = document.getElementById('splash-screen')
        if (splashScreen) {
            splashScreen.style.setProperty('display', 'none')
        }
    }

    async function signIn(email: string, password: string): Promise<string | void> {
        const result = await AuthService.signIn(email, password);

        await Storage.setToken(result.data.token);
        await Storage.setUsuario(result.data.usuario);
        await Storage.setCredenciados(result.data.credenciados);
        await Storage.setClientes(result.data.clientes);
        await Storage.setGrupoEmpresarial(result.data.grupoEmpresarial);

        setToken(result.data.token);
        setUsuario(result.data.usuario);
        setCredenciados(result.data.credenciados);
        setClientes(result.data.clientes);
        setGrupoEmpresarial(result.data.grupoEmpresarial);
        setAuthorized(true);

        return result.data.token;
    }

    async function signOut(): Promise<void> {
        await Storage.clearStorage();
        setAuthorized(false);
        setUsuario(undefined);
        setCredenciados(undefined);
        setClientes(undefined);
        setGrupoEmpresarial(undefined);
        setToken(null);
    }

    return (
        <AuthContext.Provider value={{ isAuthorized, usuario, token, loading, signIn, signOut, credenciados, clientes, grupoEmpresarial }}>
            {children}
        </AuthContext.Provider >
    )
}

export function useAuth() {
    const context = useContext<IAuthContext | any>(AuthContext);

    return context as IAuthContext;
}