import React, { useState } from "react"
import { Modal, Spinner } from "react-bootstrap-v5";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useEffect } from "react";
import clsx from "clsx";

import Swal from "sweetalert2";
import { ICartaoResponse } from "../../../../interfaces/responses/clientes/ICartaoResponse";
import AdministradorService from "../../../../services/AdministradorService";
import GridPadrao from "../../../components/GridPadrao";
import ModalContent from "../../../components/modal/ModalContent";
import ModalHeader from "../../../components/modal/ModalHeader";
import ModalBody from "../../../components/modal/ModalBody";
import Loading from "../../../components/loading/Loading";
import ModalFooter from "../../../components/modal/ModalFooter";
import BotaoCancelar from "../../../components/BotaoCancelar";
import BotaoAcao from "../../../components/BotaoAcao";


interface CriarLoteCartaoModalProps {
    toggleModal: () => void,
    exibirModal: boolean,
    cartoes: ICartaoResponse[],
    idGrupoEmpresarial: string
}

const CriarLoteCartao = ({ toggleModal, exibirModal, cartoes, idGrupoEmpresarial }: CriarLoteCartaoModalProps) => {
    const [loadingInicial, setLoadingInicial] = useState<boolean>(false)
    
    const valoresIniciais = { } as any;

    const formik = useFormik({
        initialValues: valoresIniciais,
        onSubmit: async () => {
            try {
                setLoadingInicial(true)

                await AdministradorService.criarLote(idGrupoEmpresarial);

                Swal.fire({
                        icon: 'success',
                        text: `Novo lote de cartão criado com sucesso!`,
                        showConfirmButton: false,
                        timer: 3000
                    })
                toggleModal();
            } catch (error) {
                Swal.fire(
                        Array.isArray(error) ? error[0] : `Não foi possível criar lote tente novamente ou contate o suporte`
                );
            }finally {
                setLoadingInicial(false)
            } 
        }
    })
    
    const colunas = [
        {
          name: 'Nome',
          selector: (row: ICartaoResponse) => row.funcionario['nome'],
          sortable: true,
          wrap: true,
        },
        {
          name: 'CPF',
          selector: (row: ICartaoResponse) => row.funcionario['cpf'].replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4"),
          sortable: true,
          wrap: true,
        },
        {
          name: 'Filial Nome',
          selector: (row: ICartaoResponse) => row.cliente['nome'],
          sortable: true,
          wrap: true,
        },
        {
          name: 'Filial CNPJ',
          selector: (row: ICartaoResponse) => row.cliente['cnpj'].replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5"),
          sortable: true,
          wrap: true,
        },
        {
          name: 'Número Cartão',
          selector: (row: ICartaoResponse) => row['numero'],
          sortable: true,
          wrap: true,
        }
      ]
    return <>
        <Modal fullscreen centered={true} show={exibirModal} onHide={toggleModal}>
            <ModalContent>
                <ModalHeader 
                    title="Novo Lote"
                    closeFunction={toggleModal}
                />
                <ModalBody>
                    <Loading isLoading={loadingInicial}>
                        <GridPadrao colunas={colunas} tipo='Funcionários' itens={cartoes} />
                    </Loading>
                </ModalBody>
                <ModalFooter>
                    <BotaoCancelar 
                        onClick={toggleModal}
                        disabled={formik.isSubmitting}
                    />
                
                    <BotaoAcao 
                        labelLoading="Aguarde..."
                        disabled={formik.isSubmitting}
                        onClick={formik.submitForm}
                        isLoading={formik.isSubmitting}
                        label={`CRIAR LOTE`}
                    />
              </ModalFooter>
            </ModalContent>
        </Modal>
    </>
}

export default CriarLoteCartao;