import React, { useEffect, useState } from "react";

import ClienteService from "../../../services/ClienteService";
import Swal from "sweetalert2";
import { Modal, Spinner } from "react-bootstrap-v5";
import GridPadrao from "../../components/GridPadrao";
import { IPedidoCreditoResponse } from "../../../interfaces/responses/clientes/IPedidoCreditoResponse.1";
import { IPedidoCreditoDetalheResponse } from "../../../interfaces/responses/clientes/IPedidoCreditoDetalheResponse";

interface IPedidoCreditoDetalheModalProps {
    toggleModal: () => void,
    exibirModal: boolean,
    pedidoCredito: IPedidoCreditoResponse,
}

const PedidoCreditoDetalheModal = ({toggleModal, exibirModal, pedidoCredito}: IPedidoCreditoDetalheModalProps) =>{
    useEffect(() => {
    }, [pedidoCredito])

    const [loadingInicial, setLoadingInicial] = useState<boolean>(false)
    const [funcionarios, setFuncionarios] = useState<IPedidoCreditoDetalheResponse[]>([])

    const colunas = [
        {
          name: 'Nome',
          selector: (row: IPedidoCreditoDetalheResponse) => row['nome'],
          sortable: true,
          wrap: true,
        },
        {
          name: 'CPF',
          selector: (row: IPedidoCreditoDetalheResponse) => row['cpf'].replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4"),
          sortable: true,
          wrap: true,
        },
        {
            name: 'Cartão',
            selector: (row: IPedidoCreditoDetalheResponse) => row['numero'],
            sortable: true,
            wrap: true,
        },
        {
            name: 'Valor',
            selector: (row: IPedidoCreditoDetalheResponse) => row['valorFormatado'],
            sortable: true,
            wrap: true,
        }
    ]

    async function carregarPedidoCreditoDetalhe(){
        try {
            setLoadingInicial(true)
            const { data  } = await ClienteService.obterPedidoCreditoDetalhes(pedidoCredito.idCliente, pedidoCredito.id)
            setFuncionarios(data)
          
          } catch (error) {
            Swal.fire({
              icon: 'error',
              title: 'Não foi possivel carregar os Funcionários',
              text: 'Atualize a pagina, caso o erro continue entre em contato com o administrador.',
              showConfirmButton: true,
            })
          } finally {
            setLoadingInicial(false)
          } 
    }
    return <>
         <Modal size='lg' centered={true} show={exibirModal} onHide={toggleModal} onShow={carregarPedidoCreditoDetalhe}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title titulo-modal">Pedido # {pedidoCredito.codigo} - Funcionários</h5>
                    <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                        <i className="fas fa-times" style={{ color: '#5E6397' }}></i>
                    </div>
                </div>
                <div className="modal-body" >
                    <div>
                        {loadingInicial ? (
                            <Spinner animation='border' />
                        ) : (
                        <div>
                            <h4 className="mb-3"><i style={{fontSize: 22}} className="bi bi-calendar-event-fill text-black"></i> {pedidoCredito.dataDisponibilizacaoFormatada}</h4>
                            <GridPadrao colunas={colunas} tipo='Funcionários' itens={funcionarios} />
                        </div>
                        )}
                    </div>
                </div>
                <div className="modal-footer" style={{ margin: '0 auto' }}>
                
                </div>
            </div>
        </Modal>
    </>
}

export default PedidoCreditoDetalheModal;