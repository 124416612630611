import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { IFinanceiroCredenciado } from '../../../../interfaces/responses/financeiro/IFinanceiroCredenciado';
import GridPadrao from '../../../components/GridPadrao';
import { array } from 'yup';

interface CredenciadoImprimirProps {
  credenciado: IFinanceiroCredenciado
}

function ExibirCredenciado({credenciado} : CredenciadoImprimirProps) {
  const colunas = [
    {
      name: 'Código',
      selector: (row: IFinanceiroCredenciado) => row['codigo'],
      sortable: false,
      wrap: true,
      grow: 1
    },
    {
      name: 'CNPJ',
      selector: (row: IFinanceiroCredenciado) => row['cnpj']?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5"),
      sortable: false,
      wrap: true,
      grow: 4
    },
    {
      name: 'Nome',
      selector: (row: IFinanceiroCredenciado) => row['nome'],
      sortable: false,
      wrap: false,
      grow: 5
    },
    {
      name: 'Nome Fantasia',
      selector: (row: IFinanceiroCredenciado) => row['nomeFantasia'],
      sortable: false,
      wrap: true,
      grow: 4
    },
    {
      name: 'Grupo',
      selector: (row: IFinanceiroCredenciado) => row['nomeGrupoEmpresarial'],
      sortable: false,
      wrap: false,
      grow: 3
    },
    {
      name: 'Ativo',
      selector: (row: IFinanceiroCredenciado) => row['ativo'] === true ? 'SIM' : 'NÃO',
      sortable: false,
      wrap: true,
      grow: 2
    }
  ]
  
  const colunasEndereco = [
    {
      name: 'CEP',
      selector: (row: IFinanceiroCredenciado) => row['cep'],
      sortable: false,
      wrap: true,
      //grow: 4
    },
    {
      name: 'Logradouro',
      selector: (row: IFinanceiroCredenciado) => row['logradouro'],
      sortable: false,
      wrap: false,
      grow: 3
    },
    {
      name: 'Número',
      selector: (row: IFinanceiroCredenciado) => row['numero'],
      sortable: false,
      wrap: true
    },
    {
      name: 'Complemento',
      selector: (row: IFinanceiroCredenciado) => row['complemento'],
      sortable: false,
      wrap: false
    },
    {
      name: 'Bairro',
      selector: (row: IFinanceiroCredenciado) => row['bairro'],
      sortable: false,
      wrap: true
    },
    {
      name: 'Cidade',
      selector: (row: IFinanceiroCredenciado) => row['cidade'],
      sortable: false,
      wrap: true
    },
    {
      name: 'UF',
      selector: (row: IFinanceiroCredenciado) => row['uf'],
      sortable: false,
      wrap: true,
    }
  ]

  return (
    <div className="">
      <h3>Informações Básicas</h3>   
      <div className="row">
        <GridPadrao itens={[credenciado]} colunas={colunas} tipo='Credenciado'/>
      </div>
      <h3>Endereço</h3>   
      <div className="row">
        <GridPadrao itens={[credenciado]} colunas={colunasEndereco} tipo='Endereço'/>
      </div>
    </div>
  );
}

export default ExibirCredenciado;
