import clsx from 'clsx';
import { FormikProps } from 'formik';
import React from 'react'

interface IFormikErrorTextProps {
    formik: FormikProps<any>;
    name: string;
}

export default function FormikErrorText({
                                    formik,
                                    name
                                }: IFormikErrorTextProps) 
{
    return <>
        {formik.touched[name] && formik.errors[name] && (
                <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                        <span role='alert' className='text-danger'>{formik.errors[name]}</span>
                    </div>
                </div>
            )}
    </>
}