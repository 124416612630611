import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap-v5";
import Swal from "sweetalert2";
import { useAuth } from "../../../contexts/AuthProvider";
import { IMovimento } from "../../../interfaces/responses/credenciados/IMovimento";
import { IRelatorioMovimento } from "../../../interfaces/responses/credenciados/IRelatorioMovimento";
import CredenciadoService from "../../../services/CredenciadoService";
import BotaoFiltro from "../../components/BotaoFiltro";
import ComboBox, { ComboboxItem } from "../../components/ComboBox";
import GridPadrao from "../../components/GridPadrao";
import HeaderContent from "../../components/HeaderContent";
import InputDatePicker from "../../components/InputDatePicker";
import CardTotalizador from "../../components/CardTotalizador";
import * as Yup from 'yup';
import clsx from "clsx";
import PainelFiltro from "../../components/PainelFiltro";
import * as XLSX from 'xlsx'
import FormInputSelect, { FormInputMultiSelectItems } from "../../components/FormInputSelect";
import { TipoGrupoEmpresarial } from "../../../utils/Constants";

export function RelatorioMovimento(){
    const [movimentoRelatorioBase, setMovimentosRelatorioBase] = useState<IRelatorioMovimento>({ totalizadores: [], movimentosAnalitico: [], movimentosSintetico: [] } as IRelatorioMovimento)
    const [loadingInicial, setLoadingInicial] = useState<boolean>(true)
    const { credenciados } = useAuth();
    const [selectCredenciados, setCredenciadoItens] = useState<FormInputMultiSelectItems[]>([])
    const [selectGrupoCliente, setGrupoClienteItens] = useState<FormInputMultiSelectItems[]>([])
    const [exportarCSV, setExportarCSV] = useState<boolean>(false)
    
    useEffect(() => {
        carregarComboBox();
        carregarGrupos();
      }, [])
    
    async function carregarComboBox(){
        credenciados?.map((p) => selectCredenciados.push({ value: p.id, label: p.descricao }));
        setCredenciadoItens(selectCredenciados);
        setLoadingInicial(false);
    }
    async function carregarGrupos(){
        try {
       
            setLoadingInicial(true)
            
            const { data } = await CredenciadoService.obterGruposEmpresariais()

            const grupos = data.filter(v=> v.tipo !== TipoGrupoEmpresarial.CREDENCIADO);

            grupos?.map((p) => selectGrupoCliente.push({ value: p.id, label: p.nome }));
            
            setGrupoClienteItens(selectGrupoCliente);
    
          } catch (error) {
            Swal.fire({
              icon: 'error',
              title: 'Não foi possivel carregar os dados',
              text: 'Atualize a pagina, caso o erro continue entre em contato com o administrador.',
              showConfirmButton: true,
            })
          } finally {
            setLoadingInicial(false)
          }
    }

    const valoresIniciaisFiltro = {
        idCredenciado: '',
        dataInicio: '',
        dataFim: '',
        tipo: 'Analitico',
        autorizador: 'Todos',
        idGrupoEmpresarial: ''
      } as any;
    
      const filtroValidacoes = Yup.object().shape({
        dataInicio: Yup.date().required('Data Inicio é obrigatória').test('validacao-range', 'Data Inicio deve ser menor que o Data Fim', async (value) => {
            if (!value || !formikFiltro.values.dataFim) {
                return true;
            }

            if (value.getTime() <= formikFiltro.values.dataFim.getTime()) {
                return true;
            }

            return false;
        }),
        dataFim: Yup.date().required('Data Fim é obrigatória').test('validacao-range', 'Data Fim deve ser maior que o Data Inicio', async (value) => {
            if (!formikFiltro.values.dataInicio || !value) {
                return true;
            }

            if (formikFiltro.values.dataInicio.getTime() <= value.getTime()) {
                return true;
            }

            return false;
        })
    })

    const formikFiltro = useFormik({
        initialValues: valoresIniciaisFiltro,
        validationSchema: filtroValidacoes,
        onSubmit: async ({idCredenciado, autorizador, dataInicio, dataFim, idGrupoEmpresarial}) => {
          try {
            setLoadingInicial(true)
            const { data } = await CredenciadoService
                                                    .relatorioMovimentoPorPeriodo(idCredenciado, 
                                                                                  autorizador, 
                                                                                  new Date(dataInicio).toLocaleDateString('pt-br'), 
                                                                                  new Date(dataFim).toLocaleDateString('pt-br'),
                                                                                  idGrupoEmpresarial);
            setMovimentosRelatorioBase(data);
            setLoadingInicial(false);
            setExportarCSV(true)
          
          } catch (error) {
                Swal.fire(
                    Array.isArray(error) ? error[0] : `Não foi possivel carregar o relatório`
                );
          } finally {
            setLoadingInicial(false)
          } 
        }
    });

    const colunasSintetico = [
        {
            name: 'Filial',
            selector: (row: IMovimento) => row['credenciadoNome'],
            sortable: true,
            wrap: true,
        },
        {
            name: 'Valor',
            selector: (row: IMovimento) => row['valorFormatado'],
            sortable: true,
            wrap: true,
        },
        {
            name: 'Autorizador',
            selector: (row: IMovimento) => row['autorizador'],
            sortable: true,
            wrap: true,
        },
    ]

    const colunasAnalitico = [
        {
            name: 'Data',
            selector: (row: IMovimento) => row['dataFormatada'],
            sortable: true,
            wrap: true,
        },
        {
            name: 'Filial',
            selector: (row: IMovimento) => row['credenciadoNome'],
            sortable: true,
            wrap: true,
        },
        {
            name: 'Valor',
            selector: (row: IMovimento) => row['valorFormatado'],
            sortable: true,
            wrap: true,
        },
        {
            name: 'Final Cartão',
            selector: (row: IMovimento) => row['ultimosDigitosCartao'],
            sortable: true,
            wrap: true,
        },
        {
            name: 'Grupo Cliente',
            selector: (row: IMovimento) => row['nomeGrupoEmpresarialCliente'],
            sortable: true,
            wrap: true,
        },
        {
            name: 'Autorizador',
            selector: (row: IMovimento) => row['autorizador'],
            sortable: true,
            wrap: true,
        },
        {
            name: 'Tipo',
            selector: (row: IMovimento) => row['tipo'],
            sortable: true,
            wrap: true,
        },
        {
            name: 'Referencia',
            selector: (row: IMovimento) => row['referencia'],
            sortable: true,
            wrap: true,
        },
        {
            name: 'Status',
            selector: (row: IMovimento) => row['status'],
            sortable: true,
            wrap: true,
        }
    ]

    const headersSintetico = [
        { label: "Filial", key: "credenciadoNome" },
        { label: "Valor", key: "valor" },
        { label: "Autorizador", key: "autorizador" }
    ];

    const headersAnalitico = [
    { label: "Data", key: "dataFormatada" },
    { label: "Filial", key: "credenciadoNome" },
    { label: "Valor", key: "valor" },
    { label: "Final Cartão", key: "ultimosDigitosCartao" },
    { label: "Grupo Cliente", key: "nomeGrupoEmpresarialCliente" },
    { label: "Autorizador", key: "autorizador" },
    { label: "Tipo", key: "tipo" },
    { label: "Referencia", key: "referencia" },
    { label: "Status", key: "status" }
    ];

      function DownloadFile(){

        let sintetico = movimentoRelatorioBase.movimentosSintetico;
        let analitico = movimentoRelatorioBase.movimentosAnalitico;
        let dataParaPlanilha: any[] = [];
       
        if(formikFiltro.values.tipo === 'Sintetico'){
            sintetico.forEach(item => {
                dataParaPlanilha.push({
                    Filial: item.credenciadoNome,
                    Valor: item.valor,
                    Autorizador: item.autorizador
                })
            });
        }

        if(formikFiltro.values.tipo === 'Analitico'){
            analitico.forEach(item => {
                dataParaPlanilha.push({
                    Data: item.dataFormatada,
                    Filial: item.credenciadoNome,
                    Valor: item.valor,
                    Cartao: item.ultimosDigitosCartao,
                    GrupoCliente: item.nomeGrupoEmpresarialCliente,
                    Autorizador: item.autorizador,
                    Tipo: item.tipo,
                    Referencia: item.referencia,
                    Status: item.status
                })
            });
        }
        
        let worksheet = XLSX.utils.json_to_sheet(dataParaPlanilha);
        let workbook = XLSX.utils.book_new();
        let fileName = `relatorio-movimento-
                        ${formikFiltro.values.tipo.toLowerCase()}-
                        ${new Date(formikFiltro.values.dataInicio).toLocaleDateString('pt-br')}-
                        ${new Date(formikFiltro.values.dataFim).toLocaleDateString('pt-br')}
                        .ods`;

        XLSX.utils.book_append_sheet(workbook, worksheet, "relatorio-movimento");

        XLSX.writeFile(workbook, fileName, { bookType: "ods" });
    }
      
    return (
        <>
            <HeaderContent title={"Relatório de Movimentos"} />
            <PainelFiltro>
                {
                    <>
                        <FormInputSelect 
                                disabled={formikFiltro.isSubmitting} 
                                values={selectCredenciados} formik={formikFiltro} 
                                className="col col-md-4" 
                                propName='idCredenciado' label='Credenciados' />

                        <div className="col">
                            <label className='form-label fw-bold required'>Data Inicio</label>
                            <InputDatePicker selected={formikFiltro.values.dataInicio} onChange={async (date: any) => {
                                setExportarCSV(false)
                                if (!date) {
                                    await formikFiltro.setFieldValue('dataInicio', undefined);
                                } else {
                                    await formikFiltro.setFieldValue('dataInicio', date);
                                }

                                await formikFiltro.setFieldTouched('dataInicio');

                            }} className={clsx(
                                'form-control',

                                {

                                    'is-invalid': formikFiltro.touched.dataInicio && formikFiltro.errors.dataInicio,

                                },

                                {

                                    'is-valid': formikFiltro.touched.dataInicio && !formikFiltro.errors.dataInicio,

                                }

                            )} id="form-data-sorteio" />
                            {formikFiltro.touched.dataInicio && formikFiltro.errors.dataInicio && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formikFiltro.errors.dataInicio}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="col">
                        <label className='form-label fw-bold required'>Data Fim</label>
                            <InputDatePicker selected={formikFiltro.values.dataFim} onChange={async (date: any) => {
                                    setExportarCSV(false)
                                    if (!date) {
                                        await formikFiltro.setFieldValue('dataFim', undefined);
                                    } else {
                                        await formikFiltro.setFieldValue('dataFim', date);
                                    }

                                    await formikFiltro.setFieldTouched('dataFim');

                                }}  className={clsx(
                                    'form-control',
    
                                    {
    
                                        'is-invalid': formikFiltro.touched.dataFim && formikFiltro.errors.dataFim,
    
                                    },
    
                                    {
    
                                        'is-valid': formikFiltro.touched.dataFim && !formikFiltro.errors.dataFim,
    
                                    }
    
                                )} id="form-data-fim" />
                                {formikFiltro.touched.dataFim && formikFiltro.errors.dataFim && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formikFiltro.errors.dataFim}</span>
                                        </div>
                                    </div>
                                )}
                        </div>
                        <div className="col">
                            <label htmlFor="form-tipo" className="form-label">Tipo</label>
                            <select 
                                className="form-select" 
                                aria-label="form-tipo"
                                name="tipo"
                                value={formikFiltro.values.tipo}
                                onChange={formikFiltro.handleChange}
                                onBlur={formikFiltro.handleBlur}
                            >
                                <option value='Analitico' label="Analítico" />
                                <option value='Sintetico' label="Sintético" />
                            </select>
                        </div>
                        <div className="col">
                            <label htmlFor="form-autorizador" className="form-label">Autorizador</label>
                            <select 
                                className="form-select" 
                                aria-label="form-autorizador"
                                name="autorizador"
                                value={formikFiltro.values.autorizador}
                                onChange={formikFiltro.handleChange}
                                onBlur={formikFiltro.handleBlur}
                            >
                                <option value='Todos' label="Todos" />
                                <option value='QrCode' label="QrCode" />
                                <option value='Web' label="Web" />
                                <option value='Cartao' label="Cartão" />
                            </select>
                        </div>
                        <div className="row">
                            <FormInputSelect 
                                disabled={formikFiltro.isSubmitting} 
                                values={selectGrupoCliente}    
                                formik={formikFiltro} 
                                className="col col-md-4 mt-2" 
                                propName='idGrupoEmpresarial' 
                                label='Grupo Cliente' />

                        </div>
                    </>
                }
                {
                    <>
                        <button data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                            data-kt-menu-flip='top-end'
                            className='btn btn-sm btn-flex btn-primary btn-active-primary fw-bolder me-3'
                            onClick={formikFiltro.submitForm}
                            disabled={formikFiltro.isSubmitting || !formikFiltro.isValid}>
                            <i className="icon-lg fa fa-filter"></i>
                            Filtrar
                        </button>
                        {movimentoRelatorioBase.movimentosAnalitico.length > 0  && exportarCSV &&
                            <button 
                                className='btn btn-sm btn-icon btn-secondary my-1'
                                onClick={() => DownloadFile()}
                            >
                                <i style={{fontSize: 24}} className="bi bi-printer-fill">
                            </i></button>
                        }
                    </>
                }
            </PainelFiltro>
          {loadingInicial ? (
                    <Spinner animation='border' />
                ) : (
                    <>
                        <div className="row mb-2">
                            <CardTotalizador
                                totalizadores={movimentoRelatorioBase?.totalizadores}
                            />
                        </div>
                        <GridPadrao 
                            colunas={formikFiltro.values.tipo === 'Sintetico' ? colunasSintetico : colunasAnalitico} 
                            tipo='Movimentos' 
                            itens={formikFiltro.values.tipo === 'Sintetico' ? movimentoRelatorioBase.movimentosSintetico : movimentoRelatorioBase.movimentosAnalitico}  
                        />
                    </>
                )
            }
        </>
    )
}