import { useFormik } from "formik";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap-v5";
import Swal from "sweetalert2";
import GridPadrao from "../../components/GridPadrao";
import HeaderContent from "../../components/HeaderContent";
import InputCNPJ from "../../components/InputCNPJ";
import PainelFiltro from "../../components/PainelFiltro";
import clsx from "clsx";
import * as Yup from 'yup';
import FinanceiroService from "../../../services/FinanceiroService";
import { IFinanceiroCredenciado } from "../../../interfaces/responses/financeiro/IFinanceiroCredenciado";
import FormInputSelect from "../../components/FormInputSelect";
import { IFinanceiroGrupoEmpresarial } from "../../../interfaces/responses/financeiro/IFinanceiroGrupoEmpresarial";
import CredenciadoModalEditar from "./CredenciadoModalEditar";
import { TipoGrupoEmpresarial } from "../../../utils/Constants";
                  
export function Credenciados() {
  const [loadingInicial, setLoadingInicial] = useState<boolean>(true);
  const [credenciados, setCredenciados] = useState<IFinanceiroCredenciado[]>([]);
  const [credenciadoSelecionado, setCredenciadoSelecionado] = useState<IFinanceiroCredenciado>({} as IFinanceiroCredenciado);
  const [gruposEmpresariais, setGruposEmpresarias] = useState<IFinanceiroGrupoEmpresarial[]>([]);
  const [exibirModal, setExibirModal] = useState<boolean>(false)


  useEffect(() => {
    formikFiltro.submitForm();
    carregarGrupos();
  }, [])

  async function carregarGrupos(){
    try {
        setLoadingInicial(true)
        
        const { data } = await FinanceiroService.obterGruposEmpresariais()

        setGruposEmpresarias(data);

      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Não foi possivel carregar os dados',
          text: 'Atualize a pagina, caso o erro continue entre em contato com o administrador.',
          showConfirmButton: true,
        })
      } finally {
        setLoadingInicial(false)
      } 
  }
  const valoresIniciaisFiltro = {
    cnpj: '',
    codigo: '',
    idGrupoEmpresarial:''
  } as any;
  
  const filtroValidacoes = Yup.object().shape({
    cnpj: Yup.string().min(14, "CNPJ deve possuir exatamente 14 caracteres").max(14, "CNPJ deve possuir exatamente 14 caracteres"),
  })
  
  const formikFiltro = useFormik({
    initialValues: valoresIniciaisFiltro,
    validationSchema: filtroValidacoes,
    onSubmit: async ({cnpj, codigo, idGrupoEmpresarial}) => {
      try {
       
        setLoadingInicial(true)
        
        const { data } = await FinanceiroService.obterCredenciados(cnpj, codigo, idGrupoEmpresarial)

        setCredenciados(data)

      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Não foi possivel carregar os Clientes',
          text: 'Atualize a pagina, caso o erro continue entre em contato com o administrador.',
          showConfirmButton: true,
        })
      } finally {
        setLoadingInicial(false)
      } 
    }
  });

  const colunas = [
    {
      name: 'CNPJ',
      selector: (row: IFinanceiroCredenciado) => row['cnpj']?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5"),
      sortable: false,
      wrap: true,
      grow: 4
    },
    {
      name: 'Nome',
      selector: (row: IFinanceiroCredenciado) => row['nome'],
      sortable: false,
      wrap: false,
      grow: 5
    },
    {
      name: 'Grupo',
      selector: (row: IFinanceiroCredenciado) => row['nomeGrupoEmpresarial'],
      sortable: false,
      wrap: true,
      grow: 4
    },
    {
      name: 'Cidade',
      selector: (row: IFinanceiroCredenciado) => row['cidade'],
      sortable: false,
      wrap: false,
      grow: 3
    },
    {
      name: 'Ativo',
      selector: (row: IFinanceiroCredenciado) => row['ativo'] === true ? 'SIM' : 'NÃO',
      sortable: false,
      wrap: true,
      grow: 2
    },
    {
      name: 'Taxa',
      selector: (row: IFinanceiroCredenciado) => row['taxa'],
      sortable: true,
      wrap: true,
      grow: 1
    },
    {
      name: 'Responsável',
      selector: (row: IFinanceiroCredenciado) => row['responsavel'],
      sortable: false,
      wrap: false,
      grow: 2
    },
    {
      name: 'Dia Pagamento',
      selector: (row: IFinanceiroCredenciado) => row['diaPagamento'],
      sortable: true,
      wrap: false,
      grow: 1
    },
    {
      name: 'Editar',
      cell: (credenciado: IFinanceiroCredenciado) => {
        return (
         
            <div className="p-1">
              <button
                  title="Detalhes"
                  className='btn btn-sm btn-icon btn-secondary me-1'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                  onClick={() => {
                    setCredenciadoSelecionado(credenciado);
                    setExibirModal(true);
                  }}
              >
                
                  <i style={{fontSize: 24}} className="bi bi-pencil"></i>
              </button>
            </div>
        )
      },
    }
  ]

  function toggleModal(refresh: boolean): void {
    setExibirModal(!exibirModal);
    if(refresh){
        formikFiltro.submitForm();
    }
  }

  return (
    <>
      <HeaderContent title="Credenciado" />
      
      <CredenciadoModalEditar 
        exibirModal={exibirModal}
        toggleModal={toggleModal}
        credenciado={credenciadoSelecionado}
      />
      <PainelFiltro>
              {
                  <>
                        <div className="col col-md-2">
                          <label className='form-label fw-bold'>CNPJ</label>
                          <InputCNPJ 
                            id="form-cnpj"
                            handlechange={async (e: ChangeEvent<HTMLInputElement>) => {
                              e.target.focus()
                              await formikFiltro.setFieldValue(e.target.name, e.target.value.replace(/[^\d]/g, ''));
                              await formikFiltro.setFieldTouched(e.target.name);
                              }}
                              value={formikFiltro.values.cnpj}
                              name="cnpj"
                              placeholder="Busque por CNPJ"
                              className={clsx(
                                'form-control',
                                {
                                    'is-invalid': formikFiltro.touched.cnpj && formikFiltro.errors.cnpj,
                                },
                                {
                                    'is-valid': formikFiltro.touched.cnpj && !formikFiltro.errors.cnpj,
                                }
                            )}
                            />
                            {formikFiltro.touched.cnpj && formikFiltro.errors.cnpj && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formikFiltro.errors.cnpj}</span>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="col col-md-2">
                          <label className='form-label fw-bold'>Código</label>
                          <input onChange={async (e) =>{ 
                                await formikFiltro.setFieldValue(e.target.name, e.target.value);
                                await formikFiltro.setFieldTouched(e.target.name);
                            }} 
                            value={formikFiltro.values.codigo}
                            name="codigo" 
                            type="text"
                            placeholder="Busque por código"
                            className="form-control"
                            />
                        </div>
                        
                        <FormInputSelect disabled={formikFiltro.isSubmitting} values={
                                gruposEmpresariais
                                .filter(v=> v.tipo !== TipoGrupoEmpresarial.CLIENTE)
                                .map(grupo => ({
                                  value: grupo.id,
                                  label: grupo.nome,
                              }))
                            } formik={formikFiltro} className="col col-md-4" propName='idGrupoEmpresarial' label='Grupo' loading={loadingInicial} />
                  </>
              }
              {
                  <>
                      <button data-kt-menu-trigger='click'
                          data-kt-menu-placement='bottom-end'
                          data-kt-menu-flip='top-end'
                          className='btn btn-sm btn-flex btn-primary btn-active-primary fw-bolder me-3'
                          onClick={formikFiltro.submitForm}
                          disabled={formikFiltro.isSubmitting || !formikFiltro.isValid}>
                          <i className="icon-lg fa fa-filter"></i>
                          Filtrar
                      </button>
                  </>
              }
      </PainelFiltro>
      {loadingInicial ? (
          <Spinner animation='border' />
        ) : (
          <div>
            <GridPadrao colunas={colunas} tipo='Credenciados' itens={credenciados} />
          </div>
        )}
    
    </>
  )
}