import React, { useEffect, useState } from "react";

import ClienteService from "../../../services/ClienteService";
import Swal from "sweetalert2";
import { Modal, Spinner } from "react-bootstrap-v5";
import { useAuth } from "../../../contexts/AuthProvider";
import { useFormik } from "formik";
import ComboBox, { ComboboxItem } from "../../components/ComboBox";

interface IPedidoCreditoDetalheModalProps {
    toggleModal: () => void,
    exibirModal: boolean,
}

const PedidoCreditoModalImportar = ({toggleModal, exibirModal}: IPedidoCreditoDetalheModalProps) =>{
  useEffect(() => {
    // carregarComboBox();
  }, [])

    const [loadingInicial, setLoadingInicial] = useState<boolean>(false)
    const { grupoEmpresarial } = useAuth();
    // const [itens, setItens] = useState<ComboboxItem[]>([])
    // const [idClienteSelecionado, setItem] = useState<any>()
    
    // function carregarComboBox(){
    //   clientes?.map((p) => itens.push({ value: p.id, text: p.descricao }));
    //   setItens(itens);
    // }

    const valoresIniciais = {
        gerarApenasCartoes: false,
        file: ''
      } as any;
    
      const formik = useFormik({
        initialValues: valoresIniciais,
        onSubmit: async (values) => {
          try {
       
            if (values.file === "" || values.file === undefined){
              Swal.fire({
                icon: 'error',
                title: 'Arquivo é obrigatório',
                text: 'Por favor selecione um arquivo.',
                showConfirmButton: true,
              })
              return;
            }
            if (values.file.type !== "text/plain"){
              Swal.fire({
                icon: 'error',
                title: 'Formato do arquivo inválido',
                text: 'Por favor envie arquivo no formato texto (.txt).',
                showConfirmButton: true,
              })
              return;
            }
            
            const formData = new FormData();
            formData.append("file", values.file);
            formData.append("GerarApenasCartoes", values.gerarApenasCartoes);

            let idGrupoEmpresarial = '';

            if(grupoEmpresarial?.id) idGrupoEmpresarial = grupoEmpresarial?.id;

            await ClienteService.importarArquivoPedido(idGrupoEmpresarial, formData);

            Swal.fire({
              icon: 'success',
              text: `Arquivo carregado com sucesso`,
              showConfirmButton: true,
            })
            
            limparEFecharModal();
          
          } catch (error) {
            Swal.fire(
              Array.isArray(error) ? error[0] : `Não foi possivel cadastrar o Pedido de crédito`
              );
          } finally {
            setLoadingInicial(false)
          } 
        }
      });

      function limparEFecharModal(){
        // setItem('');
        formik.resetForm();
        toggleModal();
    }
      // function onChangeComboBox(event: React.ChangeEvent<HTMLSelectElement>) {
      //   let id = event.target.value;
      //   setItem(id)
      // }
    return <>
         <Modal size='lg' centered={true} show={exibirModal} onHide={limparEFecharModal} >
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title titulo-modal">Importar Pedido</h5>
                    <div onClick={() => limparEFecharModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                        <i className="fas fa-times" style={{ color: '#5E6397' }}></i>
                    </div>
                </div>
                <div className="modal-body" >
                    <div>
                        {loadingInicial ? (
                            <Spinner animation='border' />
                        ) : (
                        <div>
                            <form className="d-flex flex-column" autoComplete="off" onSubmit={e => e.preventDefault()}>
                              <h4 className='fs-4'>{grupoEmpresarial?.descricao}</h4>
                              <label className='form-label fw-bold mt-4'>Selecione o arquivo</label>
                              <input className="form-control" name="file" type="file" onChange={(event) =>{ 
                                  // @ts-ignore: Object is possibly 'null'
                                  formik.setFieldValue("file", event.currentTarget.files[0]);
                              }} />
                              <label className='form-label fw-bold mt-2' role="button">
                                <span className="me-2">Gerar Apenas Cartões</span>
                                <input 
                                    className=""
                                    type="checkbox" 
                                    name="gerarApenasCartoes" 
                                    checked={formik.values.gerarApenasCartoes}
                                    onChange={() => formik.setFieldValue("gerarApenasCartoes", !formik.values.gerarApenasCartoes)} 
                                />
                              </label>
                            </form>
                        </div>
                        )}
                    </div>
                </div>
                <div className="modal-footer" style={{ margin: '0 auto' }}>
                  <button disabled={formik.isSubmitting} onClick={() => limparEFecharModal()} type="button" className="btn btn-danger me-5"> CANCELAR </button>
                  <button disabled={formik.isSubmitting} onClick={() => { formik.submitForm() }} type="button" className="btn btn-primary ms-5">
                        {!formik.isSubmitting && <> ENVIAR </>}
                        {formik.isSubmitting && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                ENVIANDO...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                </div>
            </div>
        </Modal>
    </>
}

export default PedidoCreditoModalImportar;