import { useFormik } from "formik";
import React, { ChangeEvent, useState } from "react";
import { Spinner } from "react-bootstrap-v5";
import Swal from "sweetalert2";
import GridPadrao from "../../components/GridPadrao";
import HeaderContent from "../../components/HeaderContent";
import InputDatePicker from "../../components/InputDatePicker";
import * as Yup from 'yup';
import clsx from "clsx";
import * as XLSX from 'xlsx'
import PainelFiltro from "../../components/PainelFiltro";
import ClienteService from "../../../services/ClienteService";
import { IRelatorioCreditoFuncionarioRecargaResponse } from "../../../interfaces/responses/clientes/IRelatorioCreditoFuncionarioRecargaResponse";
import { IRelatorioCreditoFuncionarioResponse } from "../../../interfaces/responses/clientes/IRelatorioCreditoFuncionarioResponse";
import InputCPF from "../../components/InputCPF";

export function RelatorioRecarga(){
    const [data, setData] = useState<IRelatorioCreditoFuncionarioResponse>({ cpf: '', nome: '', valorTotalRecargas: '', quantidadeRecargas: 0,recargas: []} as IRelatorioCreditoFuncionarioResponse)
    const [loadingInicial, setLoadingInicial] = useState<boolean>(false)
    const [exportarCSV, setExportarCSV] = useState<boolean>(false)
    
    const valoresIniciaisFiltro = {
        dataInicio: '',
        dataFim: '',
        cpf: '',
      } as any;
    
    const filtroValidacoes = Yup.object().shape({
        dataInicio: Yup.date().required('Data Inicio é obrigatória').test('validacao-range', 'Data Inicio deve ser menor que o Data Fim', async (value) => {
            if (!value || !formikFiltro.values.dataFim) {
                return true;
            }

            if (value.getTime() <= formikFiltro.values.dataFim.getTime()) {
                return true;
            }

            return false;
        }),
        dataFim: Yup.date().required('Data Fim é obrigatória').test('validacao-range', 'Data Fim deve ser maior que o Data Inicio', async (value) => {
            if (!formikFiltro.values.dataInicio || !value) {
                return true;
            }

            if (formikFiltro.values.dataInicio.getTime() <= value.getTime()) {
                return true;
            }

            return false;
        }),
        cpf: Yup.string().required("CPF é obrigatório").min(11, "CPF deve possuir exatamente 11 caracteres").max(11, "CPF deve possuir exatamente 11 caracteres"),

    })

    const formikFiltro = useFormik({
        initialValues: valoresIniciaisFiltro,
        validationSchema: filtroValidacoes,
        onSubmit: async ({dataInicio, dataFim, cpf}) => {
          try {
            
            setLoadingInicial(true)
            
            const { data } = await ClienteService.relatorioRecargaPorFuncionario(cpf, new Date(dataInicio).toLocaleDateString('pt-br'), new Date(dataFim).toLocaleDateString('pt-br'));
            
            if(data.quantidadeRecargas > 0)
            {
                setData(data);
            }

            setLoadingInicial(false);
            setExportarCSV(true)
          } catch (error) {
                Swal.fire(
                    Array.isArray(error) ? error[0] : `Não foi possivel carregar o relatório`
                );
          } finally {
            setLoadingInicial(false)
          } 
        }
    });
      
    const colunas = [
        {
            name: 'Data Crédito',
            selector: (row: IRelatorioCreditoFuncionarioRecargaResponse) => row['dataCredito'],
            sortable: true,
            wrap: true,
            grow: 2
        },
        {
            name: 'Valor Crédito',
            selector: (row: IRelatorioCreditoFuncionarioRecargaResponse) => row['valorCredito'],
            sortable: true,
            wrap: true,
        }
    ]

    function DownloadFile(){
        let recargas = data.recargas
        let dataParaPlanilha: any[] = [];
       
        recargas.forEach(item => {
            dataParaPlanilha.push({
                Nome: data.nome,
                Cpf: data.cpf,
                DataCredito: item.dataCredito,
                ValorCredito: item.valorCredito
            })
        });

        let worksheet = XLSX.utils.json_to_sheet(dataParaPlanilha);
        let workbook = XLSX.utils.book_new();
        let fileName = `relatorio-recarga-${data.nome}-${data.cpf}-
                        ${new Date(formikFiltro.values.dataInicio).toLocaleDateString('pt-br')}-
                        ${new Date(formikFiltro.values.dataFim).toLocaleDateString('pt-br')}
                        .ods`;

        XLSX.utils.book_append_sheet(workbook, worksheet, "relatorio-recarga");

        XLSX.writeFile(workbook, fileName, { bookType: "ods" });
    }

    return (
        <>
            <HeaderContent title={"Relatório de Recarga"} />
            <PainelFiltro>
                {
                    <>
                        <div className="col col-md-2">
                            <label className='form-label fw-bold required'>Data Inicio</label>
                            <InputDatePicker selected={formikFiltro.values.dataInicio} onChange={async (date: any) => {
                                setExportarCSV(false)
                                if (!date) {
                                    await formikFiltro.setFieldValue('dataInicio', undefined);
                                } else {
                                    await formikFiltro.setFieldValue('dataInicio', date);
                                }

                                await formikFiltro.setFieldTouched('dataInicio');

                            }} className={clsx(
                                'form-control',

                                {

                                    'is-invalid': formikFiltro.touched.dataInicio && formikFiltro.errors.dataInicio,

                                },

                                {

                                    'is-valid': formikFiltro.touched.dataInicio && !formikFiltro.errors.dataInicio,

                                }

                            )} id="form-data-sorteio" />
                            {formikFiltro.touched.dataInicio && formikFiltro.errors.dataInicio && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formikFiltro.errors.dataInicio}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="col col-md-2">
                        <label className='form-label fw-bold required'>Data Fim</label>
                            <InputDatePicker selected={formikFiltro.values.dataFim} onChange={async (date: any) => {
                                    setExportarCSV(false)
                                    if (!date) {
                                        await formikFiltro.setFieldValue('dataFim', undefined);
                                    } else {
                                        await formikFiltro.setFieldValue('dataFim', date);
                                    }

                                    await formikFiltro.setFieldTouched('dataFim');

                                }}  className={clsx(
                                    'form-control',
    
                                    {
    
                                        'is-invalid': formikFiltro.touched.dataFim && formikFiltro.errors.dataFim,
    
                                    },
    
                                    {
    
                                        'is-valid': formikFiltro.touched.dataFim && !formikFiltro.errors.dataFim,
    
                                    }
    
                                )} id="form-data-fim" />
                                {formikFiltro.touched.dataFim && formikFiltro.errors.dataFim && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formikFiltro.errors.dataFim}</span>
                                        </div>
                                    </div>
                                )}
                        </div>
                        <InputCPF 
                            formik={formikFiltro}
                            label="CPF"
                            name="cpf"
                            className="col col-md-2"
                            placeholder="Busque por CPF"
                            required={true} />
                    </>
                }
                {
                    <>
                        <button data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                            data-kt-menu-flip='top-end'
                            className='btn btn-sm btn-flex btn-primary btn-active-primary fw-bolder me-3'
                            onClick={formikFiltro.submitForm}
                            disabled={formikFiltro.isSubmitting || !formikFiltro.isValid}>
                            <i className="icon-lg fa fa-filter"></i>
                            Filtrar
                        </button>
                        {data.recargas.length > 0  && exportarCSV &&
                            <button 
                                className='btn btn-sm btn-icon btn-secondary my-1'
                                onClick={() => DownloadFile()}
                            >
                                <i style={{fontSize: 24}} className="bi bi-printer-fill">
                            </i></button>
                        }
                    </>
                }
            </PainelFiltro>
          {loadingInicial ? (
                    <Spinner animation='border' />
                ) : (
                    <>
                        <div className="row">
                            <div className="col mb-2">
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-title fw-bold">Funcionário</h5>
                                        <div className="card-toolbar">
                                            
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="card-text">
                                            <h5>Nome {data?.nome}</h5>
                                            <br/>
                                            <h5>CPF {data?.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col mb-2">
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-title fw-bold">Totalizador</h5>
                                        <div className="card-toolbar">
                                            
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="card-text">
                                            <h5>Quantidade {data?.quantidadeRecargas}</h5>
                                            <br/>
                                            <h5>Valor {data?.valorTotalRecargas}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <GridPadrao 
                            colunas={colunas} 
                            tipo='Recarga' 
                            itens={data.recargas}  
                        />
                    </>
                )
            }
        </>
    )
}
