import React, { useEffect, useState } from "react";
import ClienteService from "../../../services/ClienteService";
import Swal from "sweetalert2";
import { Modal, Spinner } from "react-bootstrap-v5";
import GridPadrao from "../../components/GridPadrao";
import { IPedidoCreditoPainel } from "../../../interfaces/responses/clientes/IPedidoCreditoPainel";
import { IConfirmarCartao } from "../../../interfaces/request/clientes/IConfirmarCartao";

interface IAdicionarFuncionarioModalProps {
    toggleModal: () => void,
    exibirModal: boolean,
    adicionar: (funcionario: IPedidoCreditoPainel) => void,
    idCliente: string,
    existe: (funcionario: IPedidoCreditoPainel) => boolean
}

const ModalAdiconarFuncionario = ({toggleModal, exibirModal, adicionar, idCliente, existe}: IAdicionarFuncionarioModalProps) =>{
    useEffect(() => {
    }, [idCliente])

    const [loadingInicial, setLoadingInicial] = useState<boolean>(false)
    const [pedidoCreditoPainel, setPedidoCreditoPainel] = useState<IPedidoCreditoPainel[]>([])

    const colunas = [
        {
          name: 'Nome',
          selector: (row: IPedidoCreditoPainel) => row['nome'],
          sortable: true,
          wrap: true,
        },
        {
          name: 'CPF',
          selector: (row: IPedidoCreditoPainel) => row['cpf'].replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4"),
          sortable: true,
          wrap: true,
        },
        {
            name: 'Cartão',
            selector: (row: IPedidoCreditoPainel) => row['numero'],
            sortable: true,
            wrap: true,
        },
        {
          name: 'Adicionar ao pedido',
          cell: (pedidoCreditoPainel: IPedidoCreditoPainel) => {
            return (
              <div className="p-2">
                <button
                      title="adicionar funcionário ao pedido"
                      className='btn btn-sm btn-success'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                      disabled={existe(pedidoCreditoPainel)}
                      onClick={() => {
                        adicionar(pedidoCreditoPainel);
                      }}
                  >
                    {
                      existe(pedidoCreditoPainel) ? (<><span>ADICIONADO</span></>) : (<>  <span>ADICIONAR</span></>)
                    }
                  </button>
              </div>
            )
          },
        },
        {
          name: 'Desligar Funcionário',
          cell: (pedidoCreditoPainel: IPedidoCreditoPainel) => {
            return (
              <div className="p-2">
                  <button
                    title="Desligar Funcionário"
                    className='btn btn-sm btn-icon btn-secondary me-1'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                    onClick={
                      () => desligarFuncionario(pedidoCreditoPainel)}
                  >
                    <i style={{fontSize: 24}} className="bi bi-person-fill-dash"></i>
                  </button>
              </div>
            )
          },
        }
    ]
    function desligarFuncionario(pedidoCreditoPainel: IPedidoCreditoPainel){
      Swal.fire({
        title: 'Deseja desligar o funcionário?',
        text: `Você não terá mais acesso à visualização dos dados do funcionário ${pedidoCreditoPainel.nome}.`,
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'DESLIGAR FUNCIONÁRIO',
        reverseButtons: true,
        customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger'
        }
        
      }).then(async (result) => {
        if (result.isConfirmed) {
            try{
              setLoadingInicial(true);
              await ClienteService.desligarFuncionario({
                idCartao: pedidoCreditoPainel.idCartao,
                idCliente: idCliente,
                idFuncionario: pedidoCreditoPainel.idFuncionario
              } as IConfirmarCartao)
  
              Swal.fire({
                icon: 'success',
                text: `Funcionário desligado`,
                showConfirmButton: true,
            })
  
            await carregarTodosFuncionarios();
            }
            catch(error){
              Swal.fire(
                Array.isArray(error) ? error[0] : `Não foi possivel desligar o funcionário`
                );
            }
            finally{
              setLoadingInicial(false);
            }
        }
    })
    }
    async function carregarTodosFuncionarios(){
        try {
            setLoadingInicial(true)
            const { data  } = await ClienteService.obterCartoes(idCliente, '','', false)
            let funcionariosCriar: IPedidoCreditoPainel[] = [];
            data.forEach(item => {
                funcionariosCriar.push({
                    idCartao: item.id,
                    idFuncionario: item.funcionario.id,   
                    numero: item.numero,         
                    cpf: item.funcionario.cpf,
                    nome: item.funcionario.nome,
                    valor: 0
                } as IPedidoCreditoPainel);
            });
            setPedidoCreditoPainel(funcionariosCriar)
          
          } catch (error) {
            Swal.fire({
              icon: 'error',
              title: 'Não foi possivel carregar os Funcionários',
              text: 'Atualize a pagina, caso o erro continue entre em contato com o administrador.',
              showConfirmButton: true,
            })
          } finally {
            setLoadingInicial(false)
          } 
    }
    return <>
         <Modal size='lg' centered={true} show={exibirModal} onHide={toggleModal} onShow={carregarTodosFuncionarios}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title titulo-modal">Funcionários</h5>
                    <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                        <i className="fas fa-times" style={{ color: '#5E6397' }}></i>
                    </div>
                </div>
                <div className="modal-body" >
                    <div>
                        {loadingInicial ? (
                            <Spinner animation='border' />
                        ) : (
                        <div>
                            <GridPadrao colunas={colunas} tipo='Funcionários' itens={pedidoCreditoPainel} />
                        </div>
                        )}
                    </div>
                </div>
                <div className="modal-footer" style={{ margin: '0 auto' }}>
                
                </div>
            </div>
        </Modal>
    </>
}

export default ModalAdiconarFuncionario;