import React from "react";
import { Spinner } from "react-bootstrap-v5";

interface ILoadingProps{
    isLoading: boolean
    children: any
}
export default function Loading(props: ILoadingProps){

    return <>
    {
        props.isLoading ? 
            (<Spinner animation='border' />) : 
            (props.children)
    }
    </>
}