import React, { useState } from "react";
import HeaderContent from "../../components/HeaderContent";
import { Spinner } from "react-bootstrap-v5";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import ClienteService from "../../../services/ClienteService";
import { IValidarArquivoPedidoResponse } from "../../../interfaces/responses/clientes/IValidarArquivoPedidoResponse";
import ExibirValidacaoArquivoPedido from "./ExibirValidacaoArquivoPedido";

export function ValidarArquivoPedido(){
    const [loadingInicial, setLoadingInicial] = useState<boolean>(false);
    const [resultadoValidacao, setResultadoValidacao] = useState<IValidarArquivoPedidoResponse>({
        erros: [],
        estaValido: false,
        filiais: [],
        quantidadePedido: 0,
        valorTotalPedido: 0,
        valorTotalPedidoFormat: '0',
    } as IValidarArquivoPedidoResponse);

    const valoresIniciais = {
        file: ''
      } as any;
    
      const formik = useFormik({
        initialValues: valoresIniciais,
        onSubmit: async (values) => {
          try {
            if (values.file === "" || values.file === undefined){
              Swal.fire({
                icon: 'error',
                title: 'Arquivo é obrigatório',
                text: 'Por favor selecione um arquivo.',
                showConfirmButton: true,
              })
              return;
            }
            if (values.file.type !== "text/plain"){
              Swal.fire({
                icon: 'error',
                title: 'Formato do arquivo inválido',
                text: 'Por favor envie arquivo no formato texto (.txt).',
                showConfirmButton: true,
              })
              return;
            }
            
            const formData = new FormData();
            formData.append("file", values.file);

            let result = await ClienteService.validarArquivoPedido(formData);
            
            setResultadoValidacao(result.data);
            
            Swal.fire({
              icon: 'success',
              text: `Validação concluida`,
              showConfirmButton: true,
            })
          
          } catch (error) {
            Swal.fire(
              Array.isArray(error) ? error[0] : `Não foi possivel validar o arquivo`
              );
          } finally {
            setLoadingInicial(false)
          } 
        }
      });
    return (
        <>
            <HeaderContent title='Validar Arquivo Pedido'>
               
            </HeaderContent>

            <div>
                {loadingInicial ? (
                    <Spinner animation='border' />
                ) : 
                (
                  <>
                    <div className="container">
                      <div className="col-md-5">
                        <form className="d-flex flex-column" autoComplete="off" onSubmit={e => e.preventDefault()}>
                            <label className='form-label fw-bold mt-4'>Selecione o arquivo</label>
                            <input className="form-control" name="file" type="file" onChange={(event) =>{ 
                                // @ts-ignore: Object is possibly 'null'
                                formik.setFieldValue("file", event.currentTarget.files[0]);
                            }} />
                        </form>
                      </div>

                      <button disabled={formik.isSubmitting} onClick={() => { formik.submitForm() }} type="button" className="btn btn-primary mt-2">
                        {!formik.isSubmitting && <> VALIDAR </>}
                        {formik.isSubmitting && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                VALIDANDO...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                      </button>
                      <div className="col-md-12">
                        <ExibirValidacaoArquivoPedido data={resultadoValidacao} />
                      </div>
                    </div>
                  </>
                )}
            </div>
        </>
    )
}