import { FormikProps } from 'formik';
import React from 'react'

interface BotaoProps {
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    className?: string;
    label?: string;
    labelLoading: string;
    disabled: boolean;
    isLoading: boolean;
}


export default function BotaoAcao({
                                    className = "btn btn-success",
                                    label = "SALVAR",
                                    labelLoading = "SALVANDO...",
                                    disabled,
                                    isLoading,
                                    onClick
                                }: BotaoProps) 
{
    return <button 
            disabled={disabled} 
            onClick={onClick} 
            type="button" 
            className={className}>
                {!isLoading && <> {label} </>}
                {isLoading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                        {labelLoading}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                    )
                }
        </button>
}