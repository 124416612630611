
import { IApiResult } from "../interfaces/responses/common/IApiResult";
import { IAutenticacao } from "../interfaces/responses/IAutenticacao";
import api from "./Api";

class AuthService {
    async signIn(email: string, senha: string): Promise<IApiResult<IAutenticacao>> {
        let { data: result } = await api.post<IApiResult<IAutenticacao>>('Autenticacao/login-painel', { login: email, senha });

        return result;
    }

    async trocarSenha(senha: string, confirmacaoSenha: string): Promise<IApiResult<any>> {
        let { data: result } = await api.put<IApiResult<any>>('Autenticacao/trocar-senha', { senha: senha, confirmacaoSenha: confirmacaoSenha });

        return result;
    }
}


export default new AuthService();