import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap-v5";
import Swal from "sweetalert2";
import clsx from 'clsx'
import { IClienteLoteCartaoResponse, IClienteLoteResponse } from "../../../../interfaces/responses/clientes/ILoteCartaoResponse";
import AdministradorService from "../../../../services/AdministradorService";
import HeaderContent from "../../../components/HeaderContent";
import GridPadrao from "../../../components/GridPadrao";
import PainelFiltro from "../../../components/PainelFiltro";
import FormInputSelect, { FormInputMultiSelectItems } from "../../../components/FormInputSelect";
import { useFormik } from "formik";
import { TipoGrupoEmpresarial } from "../../../../utils/Constants";
import { ICartaoResponse } from "../../../../interfaces/responses/clientes/ICartaoResponse";
import CriarLoteCartao from "../modal/CriarLoteCartao";
import LoteCartaoDetalhe from "../modal/LoteCartaoDetalhe";

const openInNewTab = (url: string) => {
  window.open(url, "_blank", "noreferrer");
};
export function Lotes(){
  
    const [loadingInicial, setLoadingInicial] = useState<boolean>(true)
    const [lotes, setLotes] = useState<IClienteLoteResponse[]>([])
    const [exibirModalCriar, setExibirModalCriar] = useState<boolean>(false)
    const [exibirModalDetalhe, setExibirModalDetalhe] = useState<boolean>(false)
    const [cartoesSemLote, setCartoesSemLote] = useState<ICartaoResponse[]>([])
    const [selectGrupos, setGruposItens] = useState<FormInputMultiSelectItems[]>([])
    const [idLoteCartaoSelecionado, setIdLoteCartao] = useState<string>('')

    useEffect(() => {
        CarregarDados();
      }, [])

    async function CarregarDados() {
        setLoadingInicial(true)
        await carregarGrupos();
        setLoadingInicial(false)
    }

    async function carregarGrupos(){
        try {
        
            const { data } = await AdministradorService.obterGruposEmpresarial()
    
            const grupos = data.filter(v=> v.tipo !== TipoGrupoEmpresarial.CREDENCIADO);
    
            grupos?.map((p) => selectGrupos.push({ value: p.id, label: p.nome }));
            
            setGruposItens(selectGrupos);
    
            } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Não foi possivel carregar os dados',
                text: 'Atualize a pagina, caso o erro continue entre em contato com o administrador.',
                showConfirmButton: true,
            })
            } finally {
            
            }
    }

    function toggleModalCriar(): void {
      formikFiltro.submitForm();
      setExibirModalCriar(!exibirModalCriar);
    }

    function toggleModalDetalhe(): void {
      setExibirModalDetalhe(!exibirModalDetalhe);
    }

    const colunas = [ 
        {
            name: '# Código',
            selector: (row: IClienteLoteResponse) => row['codigo'],
            sortable: true,
            wrap: true,
        },
        {
            name: 'Criado em',
            selector: (row: IClienteLoteResponse) => row['criadoEmFormatado'],
            sortable: true,
            wrap: true,
        },
        {
            name: 'Ações',
            cell: (loteCartao: IClienteLoteResponse) => {
              return (
                <>
                  <div className="p-1">
                    <button
                        title="Consultar cartões do lote"
                        className='btn btn-sm btn-icon btn-secondary me-1'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                        onClick={() => {
                           setIdLoteCartao(loteCartao.id)
                           setExibirModalDetalhe(true);
                        }}
                    >
                        <i style={{fontSize: 24}} className="bi bi-credit-card-fill"></i>
                    </button>
                    <button
                        title="Baixar arquivo de lote"
                        className='btn btn-sm btn-icon btn-secondary me-1'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                        onClick={async () => {
                           await AdministradorService.obterArquivoLote(loteCartao.id, loteCartao.codigo);
                        }}
                    >
                        <i style={{fontSize: 24}} className="bi bi-cloud-arrow-down-fill"></i>
                    </button>
                  </div>
                </>
              )
            },
          }
    ]
    
    const valoresIniciaisFiltro = {
        idGrupoEmpresarial: ''
    } as any;

    const formikFiltro = useFormik({
        initialValues: valoresIniciaisFiltro,
        onSubmit: async ({idGrupoEmpresarial}) => {
          try {
    
            setLoadingInicial(true)
            
            await obterLotes(idGrupoEmpresarial);
            await obterCartoesSemLote(idGrupoEmpresarial);
          
          } catch (error) {
            Swal.fire({
              icon: 'error',
              title: 'Não foi possivel carregar os pedidos',
              text: 'Atualize a pagina, caso o erro continue entre em contato com o administrador.',
              showConfirmButton: true,
            })
          } finally {
            setLoadingInicial(false)
          } 
        }
      });

    async function obterLotes(idGrupoEmpresarial: any) {
      const { data } = await AdministradorService.obterLotes(idGrupoEmpresarial);
  
      setLotes(data);
    }
    async function obterCartoesSemLote(idGrupoEmpresarial: any) {
      const { data } = await AdministradorService.obterCartoesSemLote(idGrupoEmpresarial);
  
      setCartoesSemLote(data);
    }
    return (
    <>
        <CriarLoteCartao
            toggleModal={toggleModalCriar}
            exibirModal={exibirModalCriar}
            cartoes={cartoesSemLote}
            idGrupoEmpresarial={formikFiltro.values['idGrupoEmpresarial']}
        />

        <LoteCartaoDetalhe 
          exibirModal={exibirModalDetalhe}
          toggleModal={toggleModalDetalhe}
          idLoteCartao={idLoteCartaoSelecionado}
        />
        <HeaderContent title={'Lotes Cartão'} >
         <div  className={clsx({pulse: cartoesSemLote.length > 0})} >
            <button 
                disabled={cartoesSemLote.length == 0}
                onClick={() => {
                setExibirModalCriar(true)
                }}  className='btn btn-sm btn-primary'>
              <i className="icon-lg text-white fas fa-plus "></i>
              <span style={{ verticalAlign: 'middle' }} > Novo Lote ({cartoesSemLote.length})</span>
            </button>
          </div>
        </HeaderContent>

        <PainelFiltro>
            <FormInputSelect 
                disabled={formikFiltro.isSubmitting} 
                values={selectGrupos} 
                formik={formikFiltro} 
                className="col col-md-4" 
                propName='idGrupoEmpresarial' 
                label='Grupo' />

            <button data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
                className='btn btn-sm btn-flex btn-primary btn-active-primary fw-bolder me-3'
                onClick={formikFiltro.submitForm}
                disabled={formikFiltro.isSubmitting || !formikFiltro.values['idGrupoEmpresarial']}>
                <i className="icon-lg fa fa-filter"></i>
                Filtrar
            </button>          
        </PainelFiltro>
       
       {loadingInicial ? (
          <Spinner animation='border' />
        ) : (
          <div>
            <GridPadrao colunas={colunas} tipo='Lotes' itens={lotes} />
          </div>
        )}
    
    </>
    );
}