import React, { useEffect, useState } from "react";

import Swal from "sweetalert2";
import { Modal, Spinner } from "react-bootstrap-v5";
import { IPedidoCreditoResponse } from "../../../../interfaces/responses/clientes/IPedidoCreditoResponse.1";
import { IPedidoCreditoDetalheResponse } from "../../../../interfaces/responses/clientes/IPedidoCreditoDetalheResponse";
import AdministradorService from "../../../../services/AdministradorService";
import GridPadrao from "../../../components/GridPadrao";
import ModalHeader from "../../../components/modal/ModalHeader";
import Loading from "../../../components/loading/Loading";
import BotaoCancelar from "../../../components/BotaoCancelar";
import ModalContent from "../../../components/modal/ModalContent";
import ModalBody from "../../../components/modal/ModalBody";
import ModalFooter from "../../../components/modal/ModalFooter";
import PedidoDetalheGrid from "../../../components/pedido/PedidoDetalheGrid";


interface IPedidoDetalheModalModalProps {
    toggleModal: () => void,
    exibirModal: boolean,
    pedidoCredito: IPedidoCreditoResponse,
}

const PedidoDetalheModal = ({toggleModal, exibirModal, pedidoCredito}: IPedidoDetalheModalModalProps) =>{
    useEffect(() => {
    }, [pedidoCredito])

    const [loadingInicial, setLoadingInicial] = useState<boolean>(false)
    const [pedidosDetalhe, setPedidosDetalhe] = useState<IPedidoCreditoDetalheResponse[]>([])

    

    async function carregarPedidoCreditoDetalhe(){
        try {
            setLoadingInicial(true)
            const { data  } = await AdministradorService.obterPedidoCreditoDetalhes(pedidoCredito.id)
            setPedidosDetalhe(data)
          
          } catch (error) {
            Swal.fire({
              icon: 'error',
              title: 'Não foi possivel carregar os Funcionários',
              text: 'Atualize a pagina, caso o erro continue entre em contato com o administrador.',
              showConfirmButton: true,
            })
          } finally {
            setLoadingInicial(false)
          } 
    }
    return <>
        <Modal size='lg' centered={true} show={exibirModal} onHide={toggleModal} onShow={carregarPedidoCreditoDetalhe}>
            <ModalContent>
                <ModalHeader 
                    title={`Pedido # ${pedidoCredito.codigo} - Funcionários`}
                    closeFunction={toggleModal}
                />
                <ModalBody>
                    <Loading
                        isLoading={loadingInicial}
                    >
                        <h4 className="mb-3">
                            <i 
                                style={{fontSize: 22}} 
                                className="bi bi-calendar-event-fill text-black me-2">
                            </i> 
                            {pedidoCredito.dataDisponibilizacaoFormatada}
                        </h4>
                        <PedidoDetalheGrid pedidos={pedidosDetalhe} />
                    </Loading>
                </ModalBody>
                <ModalFooter>
                    <BotaoCancelar 
                        onClick={toggleModal}
                        disabled={loadingInicial}
                    />
                </ModalFooter>
            </ModalContent>
        </Modal>
    </>
}

export default PedidoDetalheModal;