import React from 'react'

interface IModalFooterProps{
    children: any
}
export default function ModalFooter(props : IModalFooterProps){

    return <>
        <div className="modal-footer" style={{ margin: '0 auto' }} >
            {props.children}
        </div>
    </>
}