import clsx from 'clsx';
import React from 'react';
import { DefaultTitle } from '../../../_metronic/layout/components/header/page-title/DefaultTitle';
import { PageLink, PageTitle, useLayout } from '../../../_metronic/layout/core';

export interface IHeaderContentType {
    title: string;
    children?: any,
    breadcrumbs?: PageLink[],
}

const HeaderContent = ({ title, children, breadcrumbs = [] }: IHeaderContentType) => {
    const { classes } = useLayout();

    return <>
        <PageTitle breadcrumbs={breadcrumbs}>{title}</PageTitle>
        <div className='toolbar' id='kt_toolbar'>
            <div id='kt_toolbar_container' className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}>
                <DefaultTitle />
                <div className='d-flex align-items-center py-1'>
                    {children}
                </div>
            </div>
        </div>
    </>
}

export default HeaderContent;