import React from 'react'

export default function BotaoFiltro() {
    return <button data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        data-kt-menu-flip='top-end'
        className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder me-3'>
        <i className="icon-lg fa fa-filter"></i>
        Filtrar
    </button>
}