import React, { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { FallbackView } from '../../_metronic/partials'
import { Credenciados } from '../pages/administrador/credenciados/Credenciados'
import { Clientes } from '../pages/administrador/clientes/Clientes'
import { ValidarArquivoPedido } from '../pages/clientes/pedidos-credito/ValidarArquivoPedido'
import { GruposEmpresariais } from '../pages/administrador/grupoEmpresarial/GruposEmpresariais'
import { Usuarios } from '../pages/administrador/usuarios/listar/Usuarios'
import { UsuarioDetalhe } from '../pages/administrador/usuarios/detalhes/UsuarioDetalhes'
import { Pedidos } from '../pages/administrador/pedidos/listar/Pedidos'
import { Lotes } from '../pages/administrador/lotes/listar/Lotes'
import { Notificacoes } from '../pages/administrador/notificacoes/listar/Notificacoes'

export function AdministradorRoutes() {

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        {/* <Route exact path='/dashboard' component={DashboardWrapper} /> */}
        <Route exact path='/administrador/grupos' component={GruposEmpresariais} />
        <Route exact path='/administrador/credenciados' component={Credenciados} />
        <Route exact path='/administrador/clientes' component={Clientes} />
        <Route exact path='/administrador/usuarios' component={Usuarios} />
        <Route exact path='/administrador/pedidos' component={Pedidos} />
        <Route exact path='/administrador/lotes' component={Lotes} />
        <Route exact path='/administrador/usuario/detalhe/:idUsuario' component={UsuarioDetalhe} />
        <Route exact path='/administrador/validar/arquivo/pedido' component={ValidarArquivoPedido} />
        <Route exact path='/administrador/notificacoes' component={Notificacoes} />
        <Redirect from='/' to='/administrador/credenciados' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
