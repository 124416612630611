import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap-v5";
import Swal from "sweetalert2";
import { IClienteLoteCartaoResponse, IClienteLoteResponse } from "../../../interfaces/responses/clientes/ILoteCartaoResponse";
import ClienteService from "../../../services/ClienteService";
import GridPadrao from "../../components/GridPadrao";
import HeaderContent from "../../components/HeaderContent";
import LotesCartaoCriar from "./LotesCartaoCriar";
import LotesCartaoModalDetalhe from "./LotesCartaoModalDetalhe";
import clsx from 'clsx'

export function LotesCartao(){
    const [loadingInicial, setLoadingInicial] = useState<boolean>(true)
    const [lotes, setLotes] = useState<IClienteLoteCartaoResponse>({qtdCartoesSemLote: 0, lotesCartao: []} as IClienteLoteCartaoResponse)
    // const [qtdSemLote, setQtdSemLote] = useState<number>(0)
    const [exibirModalCriar, setExibirModalCriar] = useState<boolean>(false)
    const [exibirModalDetalhe, setExibirModalDetalhe] = useState<boolean>(false)
    const [idLoteCartaoSelecionado, setIdLoteCartao] = useState<string>('')
    
    useEffect(() => {
        carregarLotes();
    }, []);

    function toggleModalCriar(): void {
      if (exibirModalCriar) {
        carregarLotes()
      }
      setExibirModalCriar(!exibirModalCriar);
    }

    function toggleModalDetalhe(): void {
      setExibirModalDetalhe(!exibirModalDetalhe);
    }

    async function carregarLotes(){
        try {
            setLoadingInicial(true)
            const { data } = await ClienteService.obterLotesCartao();
            setLotes(data);
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Não foi possivel carregar os dados',
                text: Array.isArray(error) ? error[0] : `Atualize a pagina, caso o erro continue entre em contato com o administrador.`,
                showConfirmButton: true,
              })
        }finally {
            setLoadingInicial(false)
        } 
    }

    const colunas = [ 
        {
            name: '# Código',
            selector: (row: IClienteLoteResponse) => row['codigo'],
            sortable: true,
            wrap: true,
        },
        {
            name: 'Criado em',
            selector: (row: IClienteLoteResponse) => row['criadoEmFormatado'],
            sortable: true,
            wrap: true,
        },
        {
            name: 'Ações',
            cell: (loteCartao: IClienteLoteResponse) => {
              return (
                <>
                  <div className="p-1">
                    <button
                        title="Consultar cartões do lote"
                        className='btn btn-sm btn-icon btn-secondary me-1'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                        onClick={() => {
                           setIdLoteCartao(loteCartao.id)
                           setExibirModalDetalhe(true);
                        }}
                    >
                        <i style={{fontSize: 24}} className="bi bi-credit-card-fill"></i>
                    </button>
                  </div>
                </>
              )
            },
          }
    ]
    return (
    <>
        <LotesCartaoCriar
            toggleModal={toggleModalCriar}
            exibirModal={exibirModalCriar}
        />

      <LotesCartaoModalDetalhe 
        exibirModal={exibirModalDetalhe}
        toggleModal={toggleModalDetalhe}
        idLoteCartao={idLoteCartaoSelecionado}
      />
        <HeaderContent title={'Lotes Cartão'} >
          <div  className={clsx({pulse: lotes.qtdCartoesSemLote > 0})} >
            <button 
                disabled={lotes.qtdCartoesSemLote == 0}
                onClick={() => {
                setExibirModalCriar(true)
                }}  className='btn btn-sm btn-primary'>
              <i className="icon-lg text-white fas fa-plus "></i>
              <span style={{ verticalAlign: 'middle' }} > Novo Lote ({lotes.qtdCartoesSemLote})</span>
            </button>
          </div>
        </HeaderContent>
       
       {loadingInicial ? (
          <Spinner animation='border' />
        ) : (
          <div>
            <GridPadrao colunas={colunas} tipo='Lotes' itens={lotes.lotesCartao} />
          </div>
        )}
    
    </>
    );
}