import React, { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { FallbackView } from '../../_metronic/partials'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { Movimentos } from '../pages/credenciados/movimentos/Movimentos'
import { Pagamentos } from '../pages/credenciados/pagamentos/Pagamento'
import { Perfil } from '../pages/usuario/Perfil'
import { RelatorioMovimento } from '../pages/credenciados/relatorio-movimento/RelatorioMovimento'

export function CredenciadoRoutes() {

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route exact path='/usuario/perfil' component={Perfil} />
        <Route exact path='/movimentos' component={Movimentos} />
        <Route exact path='/pagamentos' component={Pagamentos} />
        <Route exact path='/relatorio-movimento' component={RelatorioMovimento} />
        <Redirect from='/' to='/pagamentos' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
