import { useFormik } from "formik";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap-v5";
import Swal from "sweetalert2";
import AdministradorService from "../../../services/AdministradorService";
import GridPadrao from "../../components/GridPadrao";
import HeaderContent from "../../components/HeaderContent";
import { IAdminGrupoEmpresarial } from "../../../interfaces/responses/administrador/IAdminGrupoEmpresarial";
import GrupoEmpresarialModalEditar from "./GrupoEmpresarialModalEditar";
import GrupoEmpresarialModalCriar from "./GrupoEmpresarialModalCriar";

export function GruposEmpresariais() {
  const [exibirModalEditar, setExibirModalEditar] = useState<boolean>(false)
  const [exibirModalCriar, setExibirModalCriar] = useState<boolean>(false)
  const [loadingInicial, setLoadingInicial] = useState<boolean>(true)
  const [GruposEmpresariais, setGruposEmpresariais] = useState<IAdminGrupoEmpresarial[]>([])
  const [grupoSelecionado, setGrupo] = useState<IAdminGrupoEmpresarial>({} as IAdminGrupoEmpresarial)

  useEffect(() => {
    carregarDados();
  }, []);

  async function carregarDados(){
    try {
       
      setLoadingInicial(true)
      
      const { data } = await AdministradorService.obterGruposEmpresarial();

      setGruposEmpresariais(data);

    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Não foi possivel carregar os Grupos',
        text: 'Atualize a pagina, caso o erro continue entre em contato com o administrador.',
        showConfirmButton: true,
      })
    } finally {
      setLoadingInicial(false)
    } 
  }


  async function toggleModalEditar(refresh: boolean) {
    setExibirModalEditar(!exibirModalEditar);
    if(refresh) await carregarDados();
  }

  async function toggleModalCriar(refresh: boolean) {
    setExibirModalCriar(!exibirModalCriar);
    if(refresh) await carregarDados();
  }

  const colunas = [
    {
      name: 'Id',
      selector: (row: IAdminGrupoEmpresarial) => row['id'],
      sortable: false,
      wrap: true,
      grow: 2
    },
    {
      name: 'Nome',
      selector: (row: IAdminGrupoEmpresarial) => row['nome'],
      sortable: false,
      wrap: true,
      grow: 2
    },
    {
      name: 'Nome Impresso Cartão',
      selector: (row: IAdminGrupoEmpresarial) => row['nomeImpressoCartao'],
      sortable: false,
      wrap: true,
      grow: 2
    },
    {
      name: 'Tipo',
      selector: (row: IAdminGrupoEmpresarial) => row['tipo'],
      sortable: false,
      wrap: true,
      grow: 2
    },
    {
      name: 'Editar',
      cell: (grupo: IAdminGrupoEmpresarial) => {
        return (
         
            <div className="p-1">
              <button
                  title="Editar"
                  className='btn btn-sm btn-icon btn-secondary me-1'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                  onClick={() => {
                    setGrupo(grupo);
                    setExibirModalEditar(true)
                  }}>
                  <i style={{fontSize: 24}} className="bi bi-pencil"></i>
              </button>
            </div>
        )
      },
    }
  ]

  return (
    <>
      <GrupoEmpresarialModalEditar
        toggleModal={toggleModalEditar}
        exibirModal={exibirModalEditar}
        grupoEmpresarial={grupoSelecionado}
      />

      <GrupoEmpresarialModalCriar
        toggleModal={toggleModalCriar}
        exibirModal={exibirModalCriar}
      />

      <HeaderContent title="Grupos Empresariais" >
        <button onClick={() => {
            setExibirModalCriar(true)
          }}  className='btn btn-sm btn-primary me-2'>
            <i className="icon-lg text-white fas fa-plus"></i>
            <span style={{ verticalAlign: 'middle' }}> Novo Grupo</span>
        </button>  
      </HeaderContent>

      {loadingInicial ? (
          <Spinner animation='border' />
        ) : (
          <div>
            <GridPadrao colunas={colunas} tipo='Grupos' itens={GruposEmpresariais} />
          </div>
        )}
    
    </>
  )
}