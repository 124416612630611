export const PERFIS = {
    ADMINISTRADOR: 'Administrador',
    CREDENCIADO: 'Credenciado',
    CLIENTE: 'Cliente',
    FINANCEIRO: 'Financeiro'
}

export const MASCARA_CPF = [
    /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/
];


export const TipoGrupoEmpresarial = {
    CLIENTE: "Cliente",
    CREDENCIADO: "Credenciado",
    AMBOS: "Ambos"
}