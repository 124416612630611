import clsx from 'clsx';
import { FormikProps } from 'formik';
import React from 'react'
import FormikErrorText from './FormikErrorText';

interface InputTextProps {
    formik: FormikProps<any>;
    className?: string;
    label?: string;
    name: string;
    placeholder?: string;
    required: boolean;
}

export default function InputText({
                                    formik,
                                    className,
                                    label,
                                    name,
                                    placeholder,
                                    required
                                }: InputTextProps) 
{
    return <div className={className}>
            <label 
                htmlFor={`form-${name}`} 
                className={clsx('form-label',
                {
                    'required': required
                })}>
                    {label}
            </label>
            
            <input {...formik.getFieldProps(name)} 
                placeholder={placeholder} 
                type="text" 
                className={clsx(
                'form-control',
                {
                    'is-invalid': formik.touched[name] && formik.errors[name],
                },
                {
                    'is-valid': formik.touched[name] && !formik.errors[name],
                }
            )} 
            id={`form-${name}`} />
            
            <FormikErrorText 
                formik={formik}
                name={name}
            />
        </div>
}