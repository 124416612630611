import { FormikProps } from 'formik';
import React, { useState } from 'react'
import GridPadrao from '../GridPadrao';
import { IAdminUsuario } from '../../../interfaces/responses/administrador/IAdminUsuario';
import { Link, useHistory } from 'react-router-dom';

interface UsuarioGridProps {
    usuarios: IAdminUsuario[],
    exibirTitulo: boolean,
    exibirBotaoDetalhe: boolean
}


export default function UsuarioGrid({
                                    usuarios,
                                    exibirBotaoDetalhe,
                                    exibirTitulo
                                }: UsuarioGridProps) 
{
    const colunas = [
        {
          name: 'Id',
          selector: (row: IAdminUsuario) => row['id'],
          sortable: false,
          wrap: true,
          grow: 2
        },
        {
          name: 'Login',
          selector: (row: IAdminUsuario) => row['login'],
          sortable: false,
          wrap: true,
          grow: 2
        },
        {
          name: 'Perfil',
          selector: (row: IAdminUsuario) => row['perfil'],
          sortable: false,
          wrap: true,
          grow: 2
        },
        {
          name: '',
          cell: (usuario: IAdminUsuario) => {
            if(!exibirBotaoDetalhe) return;
            return (
                
                <div className="p-1">
                  <Link to={`/administrador/usuario/detalhe/${usuario.id}`}
                      title="Detalhes"
                      className='btn btn-sm btn-icon btn-secondary me-1'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                  >
                    
                      <i style={{fontSize: 24}} className="bi bi-three-dots"></i>
                  </Link>
                </div>
            )
          },
        }
      ]
    return <>
     {exibirTitulo && <h2>Usuário</h2>}
     
     <GridPadrao colunas={colunas} tipo='Usuário' itens={usuarios} />
    </>
}