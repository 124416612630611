import React, { useState } from 'react';
import { IValidarArquivoPedidoResponse } from '../../../interfaces/responses/clientes/IValidarArquivoPedidoResponse';

const ExibirValidacaoArquivoPedido = ({ data }: { data: IValidarArquivoPedidoResponse }) => {
  const [openAccordion, setOpenAccordion] = useState<string | null>(null);

  const toggleAccordion = (name: string) => {
    if (openAccordion === name) {
      setOpenAccordion(null);
    } else {
      setOpenAccordion(name);
    }
  }

  return (
    <div className="mt-2">
      <div className="card col-md-4">
        <div className="card-body">
          <h5 className="card-title">Arquivo de Pedido</h5>
          <p className="card-text">Quantidade de Pedidos: {data.quantidadePedido}</p>
          <p className="card-text">Valor Total dos Pedidos: {data.valorTotalPedidoFormat}</p>
          <p className="card-text">
          Está Válido:{" "}
            <span
              style={{
                fontWeight: "bold",
                color: data.estaValido ? "green" : "red",
              }}
            >
              {data.estaValido ? "SIM" : "NÃO"}
            </span>
        </p>
        </div>
      </div>
      {data.erros.length > 0 && (<>
        <div className="card mt-2">
          <div className="card-body">
            <div className="accordion" id="accordionData">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingErros">
                  <button className="accordion-button" type="button" onClick={() => toggleAccordion('erros')}>
                    Erros
                  </button>
                </h2>
                {openAccordion === 'erros' && (
                  <div className="accordion-body">
                    <ul>
                      {data.erros.map((erro, index) => (
                        <li key={index}>{erro}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>

              {data.filiais.map((filial, index) => (
                <div className="accordion-item" key={index}>
                  <h2 className="accordion-header" id={`headingFilial${index}`}>
                    <button className="accordion-button" type="button" onClick={() => toggleAccordion(`filial${index}`)}>
                      {filial.nomeFilial} ({filial.cnpj})
                    </button>
                  </h2>
                  {openAccordion === `filial${index}` && (
                    <div className="accordion-body">
                      <p>Valor do Pedido: {filial.valorPedidoFormat}</p>
                      <p>Data de Disponibilização: {new Date(filial.dataDisponibilizacao).toLocaleDateString()}</p>

                      <h4>Funcionários</h4>
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>Nome</th>
                            <th>CPF</th>
                            <th>Valor do Crédito</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filial.funcionarios.map((funcionario, i) => (
                            <tr key={i}>
                              <td>{funcionario.nome}</td>
                              <td>{funcionario.cpf}</td>
                              <td>{funcionario.valorCreditoFormat}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </>)}
    </div>
  );
};

export default ExibirValidacaoArquivoPedido;
