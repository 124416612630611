import React, { useState } from "react"
import { Modal } from "react-bootstrap-v5";
import { IAdminCredenciado } from "../../../interfaces/responses/administrador/IAdminCredenciado";
import GridPadrao from "../../components/GridPadrao";
import Swal from "sweetalert2";

interface CredenciadoModalDetalheProps {
    toggleModal: () => void,
    exibirModal: boolean,
    credenciado: IAdminCredenciado,
}

const CredenciadoModalDetalhe = ({ toggleModal, exibirModal, credenciado }: CredenciadoModalDetalheProps) => {
    const [isLoading, setLoading] = useState<boolean>(false)

    const colunasCredenciado = [
        {
          name: 'CNPJ',
          selector: (row: IAdminCredenciado) => row['cnpj']?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5"),
          sortable: false,
          wrap: true,
        },
        {
          name: 'Nome',
          selector: (row: IAdminCredenciado) => row['nome'],
          sortable: false,
          wrap: true,
        },
        {
          name: 'Nome Fantasia',
          selector: (row: IAdminCredenciado) => row['nomeFantasia'],
          sortable: false,
          wrap: true,
        },
        {
            name: 'Codigo',
            cell: (credenciado: IAdminCredenciado) => {
              return (
                  <div className="d-flex align-items-center">
                    <div className="p-2 fs-3 text">
                        {credenciado.codigo}
                    </div>
                    <div className="mt-1">
                        <button className="btn btn-primary btn-sm" 
                            onClick={() => copiar(credenciado.codigo)}
                            >
                                Copiar
                        </button>
                    </div>
                  </div>
              )
            },
          }
      ]

    const colunasEndereco = [
        {
            name: 'Cep',
            selector: (row: IAdminCredenciado) => row['cep'],
            sortable: false,
            wrap: true,
        },
        {
            name: 'Logradouro',
            selector: (row: IAdminCredenciado) => row['logradouro'],
            sortable: false,
            wrap: true,
        },
        {
            name: 'Número',
            selector: (row: IAdminCredenciado) => row['numero'],
            sortable: false,
            wrap: true,
        },
        {
            name: 'Complemento',
            selector: (row: IAdminCredenciado) => row['complemento'],
            sortable: false,
            wrap: true,
        },
        {
            name: 'Bairro',
            selector: (row: IAdminCredenciado) => row['bairro'],
            sortable: false,
            wrap: true,
        },
        {
            name: 'Cidade',
            selector: (row: IAdminCredenciado) => row['cidade'],
            sortable: false,
            wrap: true,
        },
        {
            name: 'UF',
            selector: (row: IAdminCredenciado) => row['uf'],
            sortable: false,
            wrap: true,
        }
    ]

    function copiar(data: string){
        navigator.clipboard.writeText(data)
        Swal.fire({
                position: "center",
                color: '#FFFFFF',
                background: '#009EF7',
                toast: true,
                html: "<strong>C O P I A D O !</strong>",
                width: '15em',
                showConfirmButton: false,
                timer: 2000
            })
    }

    return <>
        <Modal size='xl' centered={true} show={exibirModal} onHide={toggleModal}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title titulo-modal">Credenciado detalhes</h5>
                    <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                        <i className="fas fa-times" style={{ color: '#5E6397' }}></i>
                    </div>
                </div>
                <div className="modal-body">
                    <h4>Credenciado</h4>
                    <div>
                        <GridPadrao paginar={false} colunas={colunasCredenciado} tipo='Funcionário' itens={[credenciado]} />
                    </div>
                    <hr />
                    <h4>Endereço</h4>
                    <div>
                        <GridPadrao paginar={false} colunas={colunasEndereco} tipo='Funcionário' itens={[credenciado]} />
                    </div>
                    <hr />
                    <h4>Api Key</h4>
                    <div className="col-12" style={{wordWrap: "break-word"}}>
                        <p>{credenciado.publicKey}</p>
                        <button className="btn btn-primary" onClick={() => copiar(credenciado.publicKey)}>Copiar</button>
                    </div>
                </div>
                <div className="modal-footer" style={{ margin: '0 auto' }}>
                    <button  onClick={() => toggleModal()} type="button" className="btn btn-secondary me-5"> VOLTAR </button>
                </div>
            </div>
        </Modal>
    </>
}

export default CredenciadoModalDetalhe;