import React from "react"
import { ITotalizador } from "../../interfaces/responses/credenciados/ITotalizador";

interface ICardTotalizadorprops {
    totalizadores: ITotalizador[]
}

const CardTotalizador = ({totalizadores}: ICardTotalizadorprops) => {

    function renderIcon(chave: string){
        if(chave === 'QrCode') return <i style={{fontSize: 24, color: '#000000'}} className="bi bi-qr-code"></i>
        if(chave === 'Web') return <i style={{fontSize: 24, color: '#000000'}} className="bi bi-globe"></i>
        if(chave === 'Cartao') return <i style={{fontSize: 24, color: '#000000'}} className="bi bi-credit-card-fill"></i>
        
        return <i style={{fontSize: 24, color: '#000000'}} className="bi bi-cash-coin"></i>
    }
    return (
        <>
            {
               totalizadores && totalizadores.map((elemento, index) =>{
                    return <div className="col" key={index}>
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-title fw-bold">{elemento.chave}</h5>
                                        <div className="card-toolbar">
                                            {renderIcon(elemento.chave)}
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="card-text">
                                            <span>Valor {elemento.valorFormatado}</span>
                                            <br/>
                                            <span>Quantiade {elemento.quantidade}</span>
                                        </div>
                                    </div>
                                </div>
                        </div>
                })
            }
        </>
    )
}

export default CardTotalizador;