import React, { useState } from "react"
import { Modal } from "react-bootstrap-v5";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useEffect } from "react";
import clsx from "clsx";
import { useAuth } from "../../../contexts/AuthProvider";
import ICriarFuncionario from "../../../interfaces/request/clientes/ICadastrarFuncionario";
import Swal from "sweetalert2";
import GridPadrao from "../../components/GridPadrao";
import BotaoSubmitFormulario from "../../components/BotaoSubmitFormulario";
import InputText from "../../components/InputText";
import ClienteService from "../../../services/ClienteService";
import InputCPF from "../../components/InputCPF";
import BotaoCancelar from "../../components/BotaoCancelar";
import BotaoAcao from "../../components/BotaoAcao";
import FormInputSelect, { FormInputMultiSelectItems } from "../../components/FormInputSelect";
import FormikErrorText from "../../components/FormikErrorText";


interface FuncionarioModalProps {
    toggleModal: (carregarDados?: boolean) => void,
    exibirModal: boolean,
}

interface ICriarFuncionarioQueue{
    nome: string;
    sobrenome: string,
    cpf: string
    status: string
    idCliente: string;
    nomeCliente: string;
}

const FuncionarioModalCriar = ({ toggleModal, exibirModal }: FuncionarioModalProps) => {
    const [funcionariosParaCadastrar, setFuncionariosParaCadastrar] = useState<ICriarFuncionarioQueue[]>([])
    const [realizandoCadastro, setRealizandoCadastro] = useState<boolean>(false)
    const [algumErro, setAlgumErro] = useState<boolean>(false)
    const { clientes } = useAuth();
    const [itens, setItens] = useState<FormInputMultiSelectItems[]>([])
    
    useEffect(() => {
        carregarComboBox();
      }, [])

    async function carregarComboBox(){
        clientes?.map((p) => itens.push({ value: p.id, label: p.descricao }));
        setItens(itens);
      }

    const funcionarioValidacoes = Yup.object().shape({
        nome: Yup.string().min(2, 'Mínimo 2 caracteres').max(100, 'Maximo 100 caracteres').required('Nome é obrigatório'),
        sobrenome: Yup.string().min(2, 'Mínimo 2 caracteres').max(100, 'Maximo 100 caracteres').required('Sobrenome é obrigatório'),
        cpf: Yup.string().min(11, 'Maximo 11 caracteres').max(11, 'Maximo 11 caracteres').required('CPF é obrigatório'),
        idCliente:Yup.string().required('Cliente é obrigatório')
    })
    
    const valoresIniciais = {
        idCliente: '',
        nome: '',
        sobrenome: '',
        cpf: '',
    } as any;

    const formik = useFormik({
        initialValues: valoresIniciais,
        validationSchema: funcionarioValidacoes,
        onSubmit: async (values) => {
            try {
                    let newList = [...funcionariosParaCadastrar];
                    let nomeCliente = clientes?.find(v=> v.id === values.idCliente);

                    newList.push({ 
                            nome: values.nome, 
                            sobrenome: values.sobrenome, 
                            cpf: values.cpf, 
                            status: 'Aguardando' ,
                            idCliente: nomeCliente?.id,
                            nomeCliente: nomeCliente?.descricao
                        } as ICriarFuncionarioQueue)
                    setFuncionariosParaCadastrar(newList);
                    formik.resetForm();
                    await formik.setFieldValue('idCliente', nomeCliente?.id);
                    await formik.setFieldTouched('idCliente', true);
                 
                    Swal.fire({
                        showConfirmButton: false,
                        icon: 'success',
                        timerProgressBar: true,
                        text: `Funcionário Adicionado`,
                        position: 'top-end',
                        toast: true,
                        timer: 1000
                    })
            } catch (error) {
                Swal.fire(
                    'Falha ao adicionar funcionário'
                );
            }
        }
    });

    const colunas = [
        {
          name: 'Nome',
          selector: (row: ICriarFuncionarioQueue) => row['nome'],
          sortable: true,
          wrap: true,
          grow: 2
        },
        {
            name: 'Sobrenome',
            selector: (row: ICriarFuncionarioQueue) => row['sobrenome'],
            sortable: true,
            wrap: true,
            grow: 2
          },
        {
          name: 'CPF',
          selector: (row: ICriarFuncionarioQueue) => row['cpf'].replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4"),
          sortable: true,
          wrap: true,
        }, 
        {
            name: 'Cliente',
            selector: (row: ICriarFuncionarioQueue) => row['nomeCliente'],
            sortable: true,
            wrap: true,
            grow: 2
        }, 
        {
            name: 'Status',
            selector: (row: ICriarFuncionarioQueue) => row['status'],
            sortable: true,
            wrap: true,
            grow: 2
          },
    ]

    async function RealizarCadastro(){
        setRealizandoCadastro(true);
        setAlgumErro(false);
        try{
            let funcionariosAtualizados = [...funcionariosParaCadastrar];

            for (const [i, funcionario] of funcionariosParaCadastrar.entries()) {
                funcionariosAtualizados[i] = { ...funcionario, status: 'Realizando Cadastro' };
                setFuncionariosParaCadastrar(funcionariosAtualizados);

                try {
                    await ClienteService.criarFuncionario({
                        nome: `${funcionario.nome} ${funcionario.sobrenome}`,
                        cpf: funcionario.cpf,
                        idCliente: funcionario.idCliente
                    } as ICriarFuncionario);

                    funcionariosAtualizados[i] = { ...funcionariosAtualizados[i], status: 'Cadastro Realizado' };
                } catch (error) {
                    setAlgumErro(true);
                    let msg =  Array.isArray(error) ? error[0] : `Não foi possivel cadastrar`
                    funcionariosAtualizados[i] = { ...funcionariosAtualizados[i], status: msg };
                }

                setFuncionariosParaCadastrar([...funcionariosAtualizados]);
            }
        }
        catch{
            Swal.fire(
                'Falha ao cadastrar'
            );
        }
        finally{
            setRealizandoCadastro(false);
            if(!algumErro){
                
                formik.resetForm();
            }
        }
    }

    function closeModal(){
        if(realizandoCadastro){
            return;
        }

        if(formik.dirty){
            Swal.fire({
                title: 'Deseja fechar a tela de cadastro?',
                text: `Ao sair você perderá todas as alterações pendentes`,
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'CONTINUAR CADASTRO',
                confirmButtonText: 'SAIR',
                reverseButtons: true,
                customClass: {
                    confirmButton: 'btn btn-danger',
                    cancelButton: 'btn btn-primary'
                }
                
              }).then((result) => {
                if (result.isConfirmed) {
                    LimparEFecharModal()
                }
            })
        }

        if(!realizandoCadastro && !formik.dirty){
            LimparEFecharModal()
        }
    }

    function LimparEFecharModal(){
        setFuncionariosParaCadastrar([]);
        formik.resetForm();
        toggleModal();
    }

    return <>
        <Modal size='xl' centered={true} show={exibirModal} onHide={closeModal}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title titulo-modal">Cadastrar Funcionário</h5>
                    <div onClick={() => closeModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                        <i className="fas fa-times" style={{ color: '#5E6397' }}></i>
                    </div>
                </div>
                <div className="modal-body">
                    <form className="row g-2">
                        <div className="row g-2">
                            <FormInputSelect 
                                disabled={formik.isSubmitting} 
                                values={itens} 
                                formik={formik} 
                                className="col col-md-4" 
                                propName='idCliente' 
                                label='Cliente' 
                                required={true} />
                                
                                <FormikErrorText
                                    formik={formik}
                                    name="idCliente"
                                />
                        </div>
                        <InputText
                            formik={formik}
                            name="nome"
                            required={true}
                            label="Nome"
                            placeholder="Nome"
                            className="col-md-4"
                        />

                        <InputText
                            formik={formik}
                            name="sobrenome"
                            required={true}
                            label="Sobrenome"
                            placeholder="Sobrenome"
                            className="col-md-4"
                        />

                        <InputCPF
                            formik={formik}
                            name="cpf"
                            required={true}
                            label="CPF"
                            placeholder="CPF"
                            className="col-md-4"
                        />
                        
                        <div className="row g-2">
                            <div className="col-md-4">
                                <BotaoSubmitFormulario
                                    formik={formik}
                                    className="btn btn-primary"
                                    label="ADICIONAR A LISTA"
                                />
                            </div>
                        </div>
                    </form>
                    <div className="row">
                        <h4 className="mt-2">Funcionários para cadastrar</h4>
                        {funcionariosParaCadastrar.length > 0 && 
                            <GridPadrao 
                                colunas={colunas}
                                itens={funcionariosParaCadastrar}
                                tipo="Funcionários"
                            />
                        }
                    </div>
                </div>
                <div className="modal-footer" style={{ margin: '0 auto' }}>
                    <BotaoCancelar 
                        disabled={formik.isSubmitting || realizandoCadastro}
                        onClick={closeModal}
                    />
                    <BotaoAcao 
                        disabled={realizandoCadastro}
                        isLoading={realizandoCadastro}
                        labelLoading="CADASTRANDO AGUARDE..."
                        onClick={RealizarCadastro}
                        className="btn btn-success"
                        label="CADASTRAR TODOS"
                    />
                </div>
            </div>
        </Modal>
    </>
}

export default FuncionarioModalCriar;