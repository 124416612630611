import React, { ChangeEvent } from "react"
import InputMask from "react-input-mask";

interface inputCep {
    className: string;
    handlechange: (event: ChangeEvent<HTMLInputElement>) => void,
    id: string,
    name: string,
    placeholder: string,
    value: number,
    disabled?: boolean
    
}

const InputCEP = (configs: inputCep) => {
    return (
        <InputMask 
            className={configs.className}
            onChange={configs.handlechange} 
            id={configs.id}
            name={configs.name}
            placeholder={configs.placeholder}
            value={configs.value} 
            mask="99999-999" 
        />
    );
}

export default InputCEP;