import React, { useState } from 'react'
import { useAuth } from '../../../../app/contexts/AuthProvider';
import { PERFIS } from '../../../../app/utils/Constants';

import {MenuItem} from './MenuItem'
import PagamentoModalConsultarSaldo from '../../../../app/pages/credenciados/pagamentos/PagamentoModalConsultarSaldo';

export function MenuInner() {
  const { usuario } = useAuth();
  const [exibirModalConsulta, setExibirModalConsulta] = useState<boolean>(false);
  
  function toggleModalConsulta(): void {
    setExibirModalConsulta(!exibirModalConsulta);
  }
  return (
    <>
       {usuario?.perfil === PERFIS.CREDENCIADO && <MenuItem title="Pagamentos" to='/pagamentos' />}
       {usuario?.perfil === PERFIS.CREDENCIADO && 
        <div className='menu-item me-lg-1'>
          <a onClick={() => {
                  setExibirModalConsulta(true)
                }}  className='menu-link py-3'>
                  
                  <span className='menu-title'>Consultar Saldo</span>
                </a>
    
                <PagamentoModalConsultarSaldo
                toggleModal={toggleModalConsulta}
                exibirModal={exibirModalConsulta}
                titulo={`Consultar Saldo`}
                />
        </div>
      }
    </>
  )
}
