import React, { ChangeEvent } from "react"
import InputMask from "react-input-mask";

interface inputCartao {
    className: string;
    handlechange: (event: ChangeEvent<HTMLInputElement>) => void,
    id: string,
    name: string,
    placeholder: string,
    value: number,
    disabled?: boolean
    
}

const InputCartao = (configs: inputCartao) => {
    return (
        <InputMask 
            className={configs.className}
            onChange={configs.handlechange} 
            id={configs.id}
            name={configs.name}
            placeholder={configs.placeholder}
            value={configs.value} 
            mask="9999 9999 9999 9999 99" 
        />
    );
}

export default InputCartao;