import React, { ChangeEvent, useEffect, useState } from 'react';
import { IFinanceiroGrupoEmpresarial } from '../../../interfaces/responses/financeiro/IFinanceiroGrupoEmpresarial';
import FinanceiroService from '../../../services/FinanceiroService';
import Swal from 'sweetalert2';
import { useFormik } from 'formik';
import { IRelatorioMovimentoCredenciado } from '../../../interfaces/responses/financeiro/IRelatorioMovimentoCredenciado';
import HeaderContent from '../../components/HeaderContent';
import PainelFiltro from '../../components/PainelFiltro';
import InputCNPJ from '../../components/InputCNPJ';
import FormInputSelect from '../../components/FormInputSelect';
import clsx from "clsx";
import * as Yup from 'yup';
import InputDatePicker from '../../components/InputDatePicker';
import { Spinner } from 'react-bootstrap-v5';
import GridPadrao from '../../components/GridPadrao';
import * as XLSX from 'xlsx';
import { TipoGrupoEmpresarial } from '../../../utils/Constants';

export default function RelatorioMovimentoCredenciado(){
    const [loadingInicial, setLoadingInicial] = useState<boolean>(true);
    const [gruposEmpresariais, setGruposEmpresarias] = useState<IFinanceiroGrupoEmpresarial[]>([]);
    const [relatorioMovimento, setRelatorioMovimento] = useState<IRelatorioMovimentoCredenciado[]>([]);
    const [exportarCSV, setExportarCSV] = useState<boolean>(false)

    useEffect(() => {
        carregarGrupos();
      }, [])

    async function carregarGrupos(){
    try {
        setLoadingInicial(true)
        
        const { data } = await FinanceiroService.obterGruposEmpresariais()

        setGruposEmpresarias(data);

        } catch (error) {
        Swal.fire({
            icon: 'error',
            title: 'Não foi possivel carregar os grupos',
            text: 'Atualize a pagina, caso o erro continue entre em contato com o administrador.',
            showConfirmButton: true,
        })
        } finally {
        setLoadingInicial(false)
        } 
    }

    const valoresIniciaisFiltro = {
        cnpj: '',
        codigo: '',
        dataInicio: '',
        dataFim: '',
        idGrupoEmpresarialCredenciado:'',
        idGrupoEmpresarialCliente:''
    } as any;

    const filtroValidacoes = Yup.object().shape({
        dataInicio: Yup.date().required('Data Inicio é obrigatória').test('validacao-range', 'Data Inicio deve ser menor que o Data Fim', async (value) => {
            if (!value || !formikFiltro.values.dataFim) {
                return true;
            }

            if (value.getTime() <= formikFiltro.values.dataFim.getTime()) {
                return true;
            }

            return false;
        }),
        dataFim: Yup.date().required('Data Fim é obrigatória').test('validacao-range', 'Data Fim deve ser maior que o Data Inicio', async (value) => {
            if (!formikFiltro.values.dataInicio || !value) {
                return true;
            }

            if (formikFiltro.values.dataInicio.getTime() <= value.getTime()) {
                return true;
            }

            return false;
        })
    })

    const formikFiltro = useFormik({
        initialValues: valoresIniciaisFiltro,
        validationSchema: filtroValidacoes,
        onSubmit: async ({cnpj, codigo, idGrupoEmpresarialCredenciado, idGrupoEmpresarialCliente, dataInicio, dataFim}) => {
          try {
            
            setLoadingInicial(true)
            
            const { data } = await FinanceiroService.relatorioMovimentoCredenciado(cnpj, 
                                                                                   codigo, 
                                                                                   idGrupoEmpresarialCredenciado, 
                                                                                   idGrupoEmpresarialCliente,
                                                                                   new Date(dataInicio).toLocaleDateString('pt-br'), 
                                                                                   new Date(dataFim).toLocaleDateString('pt-br'));
            
            
            setRelatorioMovimento(data);
            
            setExportarCSV(true)
          
          } catch (error) {
                Swal.fire(
                    Array.isArray(error) ? error[0] : `Não foi possivel carregar o relatório`
                );
          } finally {
            setLoadingInicial(false)
          } 
        }
    });

    const colunas = [
        {
            name: 'CNPJ',
            selector: (row: IRelatorioMovimentoCredenciado) => row['cnpj']?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5"),
            sortable: true,
            wrap: true,
            grow: 2
        },
        {
            name: 'Nome',
            selector: (row: IRelatorioMovimentoCredenciado) => row['nome'],
            sortable: true,
            wrap: true,
            grow: 3
        },
        {
            name: 'Grupo Cliente',
            selector: (row: IRelatorioMovimentoCredenciado) => row['nomeGrupoCliente'],
            sortable: true,
            wrap: true,
            grow: 2
        },
        {
            name: 'Taxa',
            selector: (row: IRelatorioMovimentoCredenciado) => row['taxa'],
            sortable: true,
            wrap: true,
            grow: 1
        },
        {
            name: 'Total Venda',
            selector: (row: IRelatorioMovimentoCredenciado) => row['valorTotalVendaFormat'],
            sortable: true,
            wrap: true,
        },
        {
            name: 'Valor Taxa',
            selector: (row: IRelatorioMovimentoCredenciado) => row['valorTotalTaxaFormat'],
            sortable: true,
            wrap: true,
        },
        {
            name: 'Total Devido',
            selector: (row: IRelatorioMovimentoCredenciado) => row['valorTotalDevidoFormat'],
            sortable: true,
            wrap: true,
        }
    ]

    const headersAnalitico = [
        { label: "CNPJ", key: "cnpj" },
        { label: "Nome", key: "nome" },
        { label: "Grupo", key: "nomeGrupo" },
        { label: "Taxa", key: "taxa" },
        { label: "Total Venda", key: "valorTotalVenda" },
        { label: "Total Taxa", key: "valorTotalTaxa" },
        { label: "Total Devido", key: "valorTotalDevido" }
      ];

      function DownloadFile(){
        
        let dataParaPlanilha: any[] = [];
        
        relatorioMovimento.forEach(item => {
            dataParaPlanilha.push({
                CNPJ: item.cnpj,
                Nome: item.nome,
                GrupoCliente: item.nomeGrupoCliente,
                Taxa: item.taxa,
                TotalVenda: item.valorTotalVenda,
                TotalTaxa: item.valorTotalTaxa,
                TotalDevido: item.valorTotalDevido,
            })
        });
        
        let worksheet = XLSX.utils.json_to_sheet(dataParaPlanilha);
        let workbook = XLSX.utils.book_new();
        let fileName = `relatorio-movimento-
                        ${new Date(formikFiltro.values.dataInicio).toLocaleDateString('pt-br')}-
                        ${new Date(formikFiltro.values.dataFim).toLocaleDateString('pt-br')}
                        .ods`;

        XLSX.utils.book_append_sheet(workbook, worksheet, "relatorio-movimento");

        XLSX.writeFile(workbook, fileName, { bookType: "ods" });
    }

    return <>
        <HeaderContent title={"Relatório de Movimentos"} />
        <PainelFiltro>
            {
                <>
                    <div className="col col-md-2">
                        <label className='form-label fw-bold'>CNPJ</label>
                        <InputCNPJ 
                            id="form-cnpj"
                            handlechange={async (e: ChangeEvent<HTMLInputElement>) => {
                            e.target.focus()
                            setExportarCSV(false)
                            await formikFiltro.setFieldValue(e.target.name, e.target.value.replace(/[^\d]/g, ''));
                            await formikFiltro.setFieldTouched(e.target.name);
                            }}
                            value={formikFiltro.values.cnpj}
                            name="cnpj"
                            placeholder="Busque por CNPJ"
                            className={clsx(
                            'form-control',
                            {
                                'is-invalid': formikFiltro.touched.cnpj && formikFiltro.errors.cnpj,
                            },
                            {
                                'is-valid': formikFiltro.touched.cnpj && !formikFiltro.errors.cnpj,
                            }
                        )}
                        />
                        {formikFiltro.touched.cnpj && formikFiltro.errors.cnpj && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formikFiltro.errors.cnpj}</span>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="col col-md-2">
                        <label className='form-label fw-bold'>Código</label>
                        <input onChange={async (e) =>{ 
                            setExportarCSV(false)
                            await formikFiltro.setFieldValue(e.target.name, e.target.value);
                            await formikFiltro.setFieldTouched(e.target.name);
                        }} 
                        value={formikFiltro.values.codigo}
                        name="codigo" 
                        type="text"
                        placeholder="Busque por código"
                        className="form-control"
                        />
                    </div>

                    <div className="col col-md-2">
                            <label className='form-label fw-bold required'>Data Inicio</label>
                            <InputDatePicker selected={formikFiltro.values.dataInicio} onChange={async (date: any) => {
                                setExportarCSV(false)
                                if (!date) {
                                    await formikFiltro.setFieldValue('dataInicio', undefined);
                                } else {
                                    await formikFiltro.setFieldValue('dataInicio', date);
                                }

                                await formikFiltro.setFieldTouched('dataInicio');

                            }} className={clsx(
                                'form-control',

                                {

                                    'is-invalid': formikFiltro.touched.dataInicio && formikFiltro.errors.dataInicio,

                                },

                                {

                                    'is-valid': formikFiltro.touched.dataInicio && !formikFiltro.errors.dataInicio,

                                }

                            )} id="form-data-sorteio" />
                            {formikFiltro.touched.dataInicio && formikFiltro.errors.dataInicio && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formikFiltro.errors.dataInicio}</span>
                                    </div>
                                </div>
                            )}
                    </div>

                    <div className="col col-md-2">
                    <label className='form-label fw-bold required'>Data Fim</label>
                        <InputDatePicker selected={formikFiltro.values.dataFim} onChange={async (date: any) => {
                                setExportarCSV(false)
                                if (!date) {
                                    await formikFiltro.setFieldValue('dataFim', undefined);
                                } else {
                                    await formikFiltro.setFieldValue('dataFim', date);
                                }

                                await formikFiltro.setFieldTouched('dataFim');

                            }}  className={clsx(
                                'form-control',

                                {

                                    'is-invalid': formikFiltro.touched.dataFim && formikFiltro.errors.dataFim,

                                },

                                {

                                    'is-valid': formikFiltro.touched.dataFim && !formikFiltro.errors.dataFim,

                                }

                            )} id="form-data-fim" />
                            {formikFiltro.touched.dataFim && formikFiltro.errors.dataFim && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formikFiltro.errors.dataFim}</span>
                                    </div>
                                </div>
                            )}
                    </div>
                    
                    <FormInputSelect disabled={formikFiltro.isSubmitting} values={
                            gruposEmpresariais
                            .filter(v=> v.tipo !== TipoGrupoEmpresarial.CLIENTE)
                            .map(grupo => ({
                                value: grupo.id,
                                label: grupo.nome,
                            }))
                        } formik={formikFiltro} className="col col-md-2" 
                        propName='idGrupoEmpresarialCredenciado' label='Grupo Credenciado' loading={loadingInicial} />

                    <FormInputSelect disabled={formikFiltro.isSubmitting} values={
                            gruposEmpresariais
                            .filter(v=> v.tipo !== TipoGrupoEmpresarial.CREDENCIADO)
                            .map(grupo => ({
                                value: grupo.id,
                                label: grupo.nome,
                            }))
                        } formik={formikFiltro} className="col col-md-2" propName='idGrupoEmpresarialCliente' label='Grupo Cliente' loading={loadingInicial} />
                </>
            }
            {
                <>
                    <button data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                        className='btn btn-sm btn-flex btn-primary btn-active-primary fw-bolder me-3'
                        onClick={formikFiltro.submitForm}
                        disabled={formikFiltro.isSubmitting || !formikFiltro.isValid}>
                        <i className="icon-lg fa fa-filter"></i>
                        Filtrar
                    </button>
                    {relatorioMovimento.length > 0  && exportarCSV &&
                            <button 
                                className='btn btn-sm btn-icon btn-secondary my-1'
                                onClick={() => DownloadFile()}
                            >
                                <i style={{fontSize: 24}} className="bi bi-printer-fill">
                            </i></button>
                        }
                </>
            }
        </PainelFiltro>
        {loadingInicial ? (
          <Spinner animation='border' />
        ) : (
          <div>
            <GridPadrao colunas={colunas} tipo='Movimentos' itens={relatorioMovimento} />
          </div>
        )}
    </>
}