import React from 'react'

interface IModalBodyProps{
    children: any
}
export default function ModalBody(props : IModalBodyProps){

    return <>
        <div className="modal-body" >
            {props.children}
        </div>
    </>
}