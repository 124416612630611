import { FormikProps, FormikValues } from 'formik';
import React, { useState } from 'react'
import PainelFiltro from '../PainelFiltro';
import FormInputSelect, { FormInputMultiSelectItems } from '../FormInputSelect';
import InputDatePicker from '../InputDatePicker';

interface IFiltroPedidoProps {
    formik: FormikValues;
    clienteItens: FormInputMultiSelectItems[];
    grupoItens?: FormInputMultiSelectItems[];
    isLoading?: boolean;
    toggleModal: () => void;
}

export default function FiltroPedido({
    formik,
    clienteItens,
    grupoItens = [],
    isLoading = false,
    toggleModal
     }
    : IFiltroPedidoProps) 
{
    
    return <>
      
      <PainelFiltro>
        {
            <>
                <FormInputSelect 
                    disabled={formik.isSubmitting} 
                    values={clienteItens} 
                    formik={formik} 
                    className="col col-md-4" 
                    propName='idCliente' 
                    label='Cliente' />
                
                {
                    grupoItens.length > 0 && 
                    <FormInputSelect 
                        disabled={formik.isSubmitting} 
                        values={grupoItens} 
                        formik={formik} 
                        className="col col-md-4" 
                        propName='idGrupoEmpresarial' 
                        label='Grupo' />
                }

                <div className="col col-md-3">
                <label className='form-label fw-bold'>Código</label>
                <input 
                    {...formik.getFieldProps('codigo')} 
                    placeholder="Codigo" 
                    type="number" 
                    className='form-control' 
                    id="form-codigo" />
                </div>

                <div className="col col-md-2">
                <label className='form-label fw-bold'>Data Disponibilização</label>
                <InputDatePicker selected={formik.values.dataDisponibilizacao} 
                onChange={async (date: any) => {
                        if (!date) {
                            await formik.setFieldValue('dataDisponibilizacao', undefined);
                        } else {
                            await formik.setFieldValue('dataDisponibilizacao', date);
                        }

                        await formik.setFieldTouched('dataDisponibilizacao');

                    }} className='form-control' id="form-data-fim" />
                </div>

                <div className='col col-md-3'>
                <label className='form-label fw-bold'>Status</label>
                <select
                    id="form-filtro-status" className='form-select'
                    {...formik.getFieldProps('status')}
                    onChange={async e => await formik.setFieldValue('status', e.target.value)}  >

                    <option value="">Todos</option>
                    <option value='Rascunho'>Rascunho</option>
                    {/* <option value='Processando'>Processando</option> */}
                    <option value='AguardandoLiberacaoCredito'>Aguardando Liberação Crédito</option>
                    <option value='Disponibilizado'>Disponibilizado</option>
                    <option value='Cancelado'>Cancelado</option>
                </select>
                </div>
            </>
        }
        {
            <>
                <button data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                    className='btn btn-sm btn-flex btn-primary btn-active-primary fw-bolder me-3'
                    onClick={formik.submitForm}
                    disabled={formik.isSubmitting || !formik.isValid}>
                    <i className="icon-lg fa fa-filter"></i>
                    Filtrar
                </button>
                <button 
                    className="btn btn-sm btn-flex btn-success btn-active-primary fw-bolder me-3"
                    onClick={() => toggleModal()}
                >
                <i style={{fontSize: 18, marginRight: 4}} className="bi bi-box-arrow-up"></i>
                Confirmar Todos Pedidos
                </button>
            </>
        }
        </PainelFiltro>
    </>
}