import React, { ChangeEvent, useEffect, useState } from "react"
import { Modal, Spinner } from "react-bootstrap-v5";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Swal from "sweetalert2";
import clsx from "clsx";
import AdministradorService from "../../../services/AdministradorService";
import ComboBox, { ComboboxItem } from "../../components/ComboBox";
import { IAdminGrupoEmpresarial } from "../../../interfaces/responses/administrador/IAdminGrupoEmpresarial";
import { IAdminEditarGrupoEmpresarialRequest } from "../../../interfaces/request/administrador/IAdminEditarGrupoEmpresarialRequest";
import { TipoGrupoEmpresarial } from "../../../utils/Constants";
import BotaoSubmitFormulario from "../../components/BotaoSubmitFormulario";
import { IAdminCriarGrupoEmpresarialRequest } from "../../../interfaces/request/administrador/IAdminCriarGrupoEmpresarialRequest";

interface GrupoEmpresarialModalEditarProps {
    toggleModal: (refresh: boolean) => void,
    exibirModal: boolean
}

const GrupoEmpresarialModalCriar = ({ toggleModal, exibirModal }: GrupoEmpresarialModalEditarProps) => {
    const [isLoading, setLoading] = useState<boolean>(false)
    const [comboItemsTipo, setComboItensTipo] = useState<ComboboxItem[]>([])
    
    const grupoValidations = Yup.object().shape({
        nome: Yup.string().max(100, 'Maximo 100 caracteres').required('Nome é obrigatório'),
        nomeImpressoCartao: Yup.string().max(100, 'Maximo 100 caracteres'),
        tipo: Yup.string().required("Tipo é obrigatório"),
    });
    
    useEffect(() => {
        carregarComboBox()
      }, []);

    async function carregarComboBox(){
        let itens: ComboboxItem[] = [];
        itens.push({ value: TipoGrupoEmpresarial.CLIENTE, text: TipoGrupoEmpresarial.CLIENTE });
        itens.push({ value: TipoGrupoEmpresarial.CREDENCIADO, text: TipoGrupoEmpresarial.CREDENCIADO });
        itens.push({ value: TipoGrupoEmpresarial.AMBOS, text: TipoGrupoEmpresarial.AMBOS });
        setComboItensTipo(itens);
    }
    const valoresIniciais = {
        nome: '',
        nomeImpressoCartao: '',
        tipo: ''
    } as any;
    
    const formik = useFormik({
        initialValues: valoresIniciais,
        validationSchema: grupoValidations,
        onSubmit: async (values) => {
            try {
                setLoading(true);
                
                let grupo: IAdminCriarGrupoEmpresarialRequest =
                {
                    nome: values.nome,
                    nomeImpressoCartao: values.nomeImpressoCartao,
                    tipo: values.tipo
                };
                
                let data = await AdministradorService.cadastrarGrupoEmpresarial(grupo);
                
                Swal.fire({
                            icon: 'success',
                            text: `Operação realizada com sucesso`,
                            showConfirmButton: true,
                        })
                limparFechar(true);
            } catch (error) {

                Swal.fire(
                     Array.isArray(error) ? error[0] : `Falha ao cadastrar, tente novamente`
                );
            }
            finally {
                setLoading(false);
                limparFechar(false);
            } 
        }
    });

    function closeModal(){
        if(!formik.dirty){
            limparFechar();
            return;
        }
        Swal.fire({
            title: 'Deseja fechar a tela de cadastro?',
            text: `Ao sair você perderá todas as alterações pendentes`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'CONTINUAR CADASTRO',
            confirmButtonText: 'SAIR',
            reverseButtons: true,
            customClass: {
                confirmButton: 'btn btn-danger',
                cancelButton: 'btn btn-primary'
            }
            
          }).then((result) => {
            if (result.isConfirmed) {
                limparFechar()
            }
        })
    }

    function limparFechar(refresh: boolean = false) {
        formik.resetForm();
        toggleModal(refresh);
    }

    return <>
        <Modal size='xl' centered={true} show={exibirModal} onHide={closeModal}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title titulo-modal">Editar Grupo Empresarial</h5>
                    <div onClick={() => closeModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                        <i className="fas fa-times" style={{ color: '#5E6397' }}></i>
                    </div>
                </div>
                <div className="modal-body">
                {isLoading ? (
                    <Spinner animation='border' />
                ) : (
                    <>
                        <form className="row">
                            <div className="row mt-2">
                                <div className="col-md-4 mb-5">
                                    <label className='form-label fw-bold required'>Nome</label>
                                        <input {...formik.getFieldProps('nome')} placeholder="Nome" type="text" className={clsx(
                                        'form-control',
                                        {
                                            'is-invalid': formik.touched.nome && formik.errors.nome,
                                        },
                                        {
                                            'is-valid': formik.touched.nome && !formik.errors.nome,
                                        }
                                        )} id="form-nome" />
                                        {formik.touched.nome && formik.errors.nome && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{formik.errors.nome}</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                <div className="col-md-4 mb-5">
                                    <label className='form-label fw-bold required'>Selecione o Tipo</label>
                                    <ComboBox items={comboItemsTipo} name='tipo' onChange={async (event: React.ChangeEvent<HTMLSelectElement>)=>{
                                        event.target.focus()
                                        let id = event.target.value;
                                        await formik.setFieldValue(event.target.name, id);
                                        await formik.setFieldTouched(event.target.name);

                                    }} value={formik.values.tipo} 
                                    className={clsx(
                                        'form-control',
                                        {
                                            'is-invalid': formik.touched.tipo && formik.errors.tipo,
                                        },
                                        {
                                            'is-valid': formik.touched.tipo && !formik.errors.tipo,
                                        }
                                    )} 
                                    />
                                    {formik.touched.tipo && formik.errors.tipo && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{formik.errors.tipo}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="col-md-4 mb-5">
                                    <label className='form-label fw-bold'>Nome Impresso Cartão</label>
                                    <input {...formik.getFieldProps('nomeImpressoCartao')} placeholder="Nome Impresso Cartão" type="text" className={clsx(
                                        'form-control',
                                        {
                                            'is-invalid': formik.touched.nomeImpressoCartao && formik.errors.nomeImpressoCartao,
                                        },
                                        {
                                            'is-valid': formik.touched.nomeImpressoCartao && !formik.errors.nomeImpressoCartao,
                                        }
                                        )} id="form-nomeImpressoCartao" />
                                        {formik.touched.nomeImpressoCartao && formik.errors.nomeImpressoCartao && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{formik.errors.nomeImpressoCartao}</span>
                                                </div>
                                            </div>
                                        )}
                                </div>
                            </div>
                        </form>
                    </>)
                }
                </div>
                <div className="modal-footer" style={{ margin: '0 auto' }}>
                    <button  onClick={() => closeModal()} type="button" className="btn btn-secondary me-5"> VOLTAR </button>
                    <BotaoSubmitFormulario 
                        formik={formik}
                    />
                </div>
            </div>
        </Modal>
    </>
}

export default GrupoEmpresarialModalCriar;