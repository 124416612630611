// import { IUsuario } from "../interfaces/IUsuario";

import { ICliente, ICredenciado, IGrupoEmpresarial, IUsuarioAutenticacao } from "../interfaces/responses/IAutenticacao";

export default class StorageUtils {
    
    static async clearStorage() {
        sessionStorage.clear();
    }

    static async getToken(): Promise<string | null> {
        const token = sessionStorage.getItem("token");

        if (token === null) {
            return null;
        }

        return token;
    }

    static setToken(token: string): void {
        sessionStorage.setItem("token", token);
    }

    static async getUsuario(): Promise<IUsuarioAutenticacao | undefined> {
        const usuarioJson = sessionStorage.getItem("usuario");

        if (usuarioJson === null) {
            return;
        }

        const user = JSON.parse(usuarioJson);

        return user;
    }

    static setUsuario(usuario: IUsuarioAutenticacao): void {
        sessionStorage.setItem("usuario", JSON.stringify(usuario));
    }

    static async getCredenciados(): Promise<ICredenciado[] | undefined> {
        const credenciadosJson = sessionStorage.getItem("credenciados");

        if (credenciadosJson === null) {
            return;
        }

        const credenciados = JSON.parse(credenciadosJson);

        return credenciados;
    }

    static setCredenciados(credenciados: ICredenciado[]): void {
        sessionStorage.setItem("credenciados", JSON.stringify(credenciados));
    }

    static async getClientes(): Promise<ICliente[] | undefined> {
        const clientesJson = sessionStorage.getItem("clientes");

        if (clientesJson === null) {
            return;
        }

        const clientes = JSON.parse(clientesJson);

        return clientes;
    }

    static setClientes(clientes: ICliente[]): void {
        sessionStorage.setItem("clientes", JSON.stringify(clientes));
    }

    static async getGrupoEmpresarial(): Promise<IGrupoEmpresarial | undefined> {
        const grupoEmpresarialJson = sessionStorage.getItem("grupoEmpresarial");

        if (grupoEmpresarialJson === null) {
            return;
        }

        const grupoEmpresarial = JSON.parse(grupoEmpresarialJson);

        return grupoEmpresarial;
    }

    static setGrupoEmpresarial(grupoEmpresarial: IGrupoEmpresarial): void {
        sessionStorage.setItem("grupoEmpresarial", JSON.stringify(grupoEmpresarial));
    }

}