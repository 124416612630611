import React, { ChangeEvent, useState } from "react"
import { Modal } from "react-bootstrap-v5";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useEffect } from "react";
import clsx from "clsx";
import { useAuth } from "../../../contexts/AuthProvider";
import ReactDOM from "react-dom";
import { AES, enc } from 'crypto-js';
import InputMonetario from "../../components/InputMonetario";
import Swal from "sweetalert2";
import CredenciadoService from "../../../services/CredenciadoService";
import { ICriarMovimentoRequest } from "../../../interfaces/request/credenciados/ICriarMovimentoRequest";
import { IMovimento } from "../../../interfaces/responses/credenciados/IMovimento";
import ReciboImpressao from "../../components/ReciboImpressao";

interface PagamentoModalProps {
    toggleModal: () => void,
    setMovimentoImpressao: (movimento : IMovimento) => void,
    exibirModal: boolean,
    idCredenciado: any, 
    titulo: string 
}

const PagamentoModalCriar = ({ toggleModal, exibirModal, titulo, idCredenciado, setMovimentoImpressao }: PagamentoModalProps) => {
    const { usuario } = useAuth();
   
    const [numeroCartaoCripto, setNumeroCartaoCripto] = useState<boolean>(false);
    const [senhaCartaoCripto, setSenhaCartaoCripto] = useState<boolean>(false);
    const [valorConfirmado, setValorConfirmado] = useState<boolean>(false);
    
    var inputValor : HTMLInputElement;
    var inputNumeroCartaoRef : HTMLInputElement;
    var inputSenhaCartaoRef : HTMLInputElement;

    useEffect(() =>{
        
    });

    function reset(){
        formik.resetForm();
        setNumeroCartaoCripto(false);
        setSenhaCartaoCripto(false);
        setValorConfirmado(false);
        toggleModal();
    }

    const pagamentoValidacoes = Yup.object().shape({
        numeroCartao: Yup.string()
                        // .length(18, 'Número do cartão deve possuir 18 caracteres')
                        //.matches(new RegExp('^\d+$'), 'apenas números')
                        .required('Número cartão é obrigatório'),

        senhaCartao: Yup.string()
                        .min(4, 'Senha deve possuir no mínimo 4 caracteres')
                        .max(6, 'Senha deve possuir no máximo 6 caracteres')
                        .required('Senha é obrigatório'),

        valor: Yup.number()
                    .min(0.01, 'Valor deve ser maior ou igual a 0.01')
                    .required('Valor é obrigatório'),

        referencia: Yup.string().max(50, 'Maximo 50 caracteres'),
    })
    
    const valoresIniciais = {
        numeroCartao: '',
        senhaCartao: '',
        valor: 1,
        referencia: '',
    } as any;

    const formik = useFormik({
        initialValues: valoresIniciais,
        validationSchema: pagamentoValidacoes,
        onSubmit: async (values) => {
            try {
                
                    let numCartao = values.numeroCartao;

                    if(values.numeroCartao.length > 18){
                        //;6398670000000002700=26030000000000000?
                        numCartao = values.numeroCartao.split('=')[0].substring(1,19);
                    }
                    
                    let valorCompra  = (values.valor * 100).toFixed(2);

                    var result = await CredenciadoService.criarMovimentoCartao(idCredenciado, {
                        idCredenciado: idCredenciado, 
                        numeroCartao: numCartao , 
                        senhaCartao: values.senhaCartao, 
                        referencia: values.referencia, 
                        valor:  parseInt(valorCompra) } as ICriarMovimentoRequest
                    );
                    
                    Swal.fire({
                        title: 'Pagamento realizado com sucesso',
                        text: `Você deseja imprimir o comprovante?`,
                        icon: 'success',
                        showCancelButton: true,
                        cancelButtonText: 'NÃO IMPRIMIR',
                        confirmButtonColor: '#28a745',
                        cancelButtonColor: '#dc3545',
                        confirmButtonText: 'IMPRIMIR'
                        
                      }).then((resultImpressao) => {
                        if (resultImpressao.isConfirmed) {
                            setTimeout(() => {
                                setMovimentoImpressao(result.data);
                                window.print();
                            }, 300);
                        }
                        reset();
                    })
            } catch (error) {
                Swal.fire(
                    Array.isArray(error) ? error[0] : `Não foi possivel cadastrar o Pagamento`
                    );
                reset();
            }
        }
    });

    function setFocusNumeroCartao(){
        inputNumeroCartaoRef.focus()
    }

    function setFocusSenhaCartao(){
        inputSenhaCartaoRef.focus()
    }

    // async function cryptoText(e: React.ChangeEvent<HTMLInputElement>, name: string)  {
    //     // var bytes  = AES.decrypt(e.target.value, 'secret key 123');
    //     // var originalText = bytes.toString(enc.Utf8);
    //     // console.log(originalText)
    //     if(name === 'numeroCartao' && numeroCartaoCripto) return;
    //     // if(name === 'senhaCartao' && senhaCartaoCripto) return;
    //     if(!e.target.value) return;
    //     // if(usuario === undefined) return;

    //     // var criptoValue = AES.encrypt(e.target.value, usuario.id).toString()
    //     await formik.setFieldValue(name, e.target.value.split('=')[0].substring(1));
        
    //     if(name === 'numeroCartao') 
    //     {
    //         formik.setFieldTouched(name);
    //         setNumeroCartaoCripto(true);
    //     }

    //     // if(name === 'senhaCartao') setSenhaCartaoCripto(true);
    // }

    function confirmarValor(){
        if(formik.errors.valor) return;
        setValorConfirmado(true);
    }
    
    function setFocusValor(){
        document.getElementById('form-valor')?.focus();
    }

    return <>
        <Modal size='lg' centered={true} show={exibirModal} onHide={reset} onShow={() => setFocusValor()}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title titulo-modal">{titulo}</h5>
                    <div onClick={() => reset()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                        <i className="fas fa-times" style={{ color: '#5E6397' }}></i>
                    </div>
                </div>
                <div className="modal-body" style={{margin: '0 auto'}}>
                    <form className="row g-3" autoComplete="off">
                        <div className="col-md-6 mb-5">
                            <label htmlFor="form-valor" className="form-label required">Valor</label>
                            <InputMonetario 
                                // reference={inputValor}
                                disabled={valorConfirmado} 
                                handlechange={async (e: ChangeEvent<HTMLSelectElement>, value: number, maskedValue: string) => {
                                    e.target.focus()
                                await formik.setFieldValue(e.target.name, value);
                                await formik.setFieldTouched(e.target.name);
                            }} name="valor" value={formik.values.valor} placeholder="R$ 9,99" className={clsx(
                                'form-control',
                                {
                                    'is-invalid': formik.touched.valor && formik.errors.valor,
                                },
                                {
                                    'is-valid': formik.touched.valor && !formik.errors.valor,
                                }
                            )} id="form-valor" />
                            {formik.touched.valor && formik.errors.valor && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.valor}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="col-md-6 mb-5">
                            <label htmlFor="form-referencia" className="form-label">Referência</label>
                            <input disabled={valorConfirmado} {...formik.getFieldProps('referencia')} placeholder="Referência" type="text" className={clsx(
                                'form-control',
                                {
                                    'is-invalid': formik.touched.referencia && formik.errors.referencia,
                                },
                                {
                                    'is-valid': formik.touched.referencia && !formik.errors.referencia,
                                }
                            )} id="form-referencia" />
                            {formik.touched.referencia && formik.errors.referencia && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.referencia}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="col-md-12 mb-5">
                            <button 
                                type="button" 
                                disabled={formik.getFieldProps('valor').value < 0.01 || valorConfirmado} 
                                onClick={confirmarValor} 
                                style={{width: '100%'}}
                                className="border border-success btn btn-outline-success d-grid btn-block mt-2" >
                                    CONFIRMAR VALOR E REFERENCIA
                                </button>
                        </div>
                        <div className="alert alert-info">
                            Verifique se o LED <span style={{fontWeight: 700}} className="text-success ">VERDE</span> está ligado no PINPAD. Pressione <span style={{fontWeight: 700}}>SCROLL LOCK</span> para ativá-lo.
                        </div>
                        <div className="col-md-6 mb-5">
                            <label htmlFor="form-numeroCartao" className="form-label required">Número Cartão</label>
                            <input disabled={!valorConfirmado || (formik.touched.numeroCartao && !formik.errors.numeroCartao)} ref={c => (inputNumeroCartaoRef  = c!)} 
                                    {...formik.getFieldProps('numeroCartao')} 
                                    // onBlur={e => cryptoText(e, 'numeroCartao')}
                                    placeholder="Número Cartão" type="password" 
                                    className={clsx(
                                'form-control',
                                {
                                    'is-invalid': formik.touched.numeroCartao && formik.errors.numeroCartao,
                                },
                                {
                                    'is-valid': formik.touched.numeroCartao && !formik.errors.numeroCartao,
                                }
                            )} id="form-numeroCartao" />
                            {formik.touched.numeroCartao && formik.errors.numeroCartao && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.numeroCartao}</span>
                                    </div>
                                </div>
                            )}
                            <button 
                                disabled={!valorConfirmado || (formik.touched.numeroCartao && !formik.errors.numeroCartao)} 
                                type="button" 
                                style={{width: '100%'}}
                                className="border border-success btn btn-outline-success d-grid mt-2" 
                                onClick={setFocusNumeroCartao}>
                                    {<>{formik.getFieldProps('numeroCartao').value != '' ? 'CONFIRMAR CARTAO' : 'SOLICITAR CARTÃO'}</>} 
                            </button>
                        </div>
                        <div className="col-md-6 mb-5">
                            <label htmlFor="form-senhaCartao" className="form-label required">Senha Cartão</label>
                            <input disabled={!valorConfirmado  || !!formik.errors.numeroCartao} ref={c => (inputSenhaCartaoRef  = c!)} 
                                    {...formik.getFieldProps('senhaCartao')} 
                                    // onBlur={e => cryptoText(e, 'senhaCartao')}
                                    placeholder="Senha Cartão" 
                                    type="password" 
                                    className={clsx(
                                'form-control',
                                {
                                    'is-invalid': formik.touched.senhaCartao && formik.errors.senhaCartao,
                                },
                                {
                                    'is-valid': formik.touched.senhaCartao && !formik.errors.senhaCartao,
                                }
                            )} id="form-senhaCartao" />
                            {formik.touched.senhaCartao && formik.errors.senhaCartao && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.senhaCartao}</span>
                                    </div>
                                </div>
                            )}
                            <button 
                                type="button"                            
                                disabled={!valorConfirmado  || !!formik.errors.numeroCartao} 
                                style={{width: '100%'}}
                                className="border border-success btn btn-outline-success d-grid mt-2" 
                                onClick={setFocusSenhaCartao}>
                                     {<>{formik.getFieldProps('senhaCartao').value != '' ? 'CONFIRMAR SENHA' : 'SOLICITAR SENHA'}</>} 
                            </button>
                        </div>
                    </form>
                </div>
                <div className="modal-footer" style={{ margin: '0 auto' }}>
                    <button disabled={formik.isSubmitting} onClick={() => reset()} type="button" className="btn btn-danger me-5"> VOLTAR </button>
                    <button disabled={formik.isSubmitting || !formik.isValid || !valorConfirmado} onClick={() => { formik.submitForm() }} type="button" className="btn btn-success ms-5">
                        {!formik.isSubmitting && <> PAGAR </>}
                        {formik.isSubmitting && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                Salvando...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                </div>
            </div>
        </Modal>
    </>
}

export default PagamentoModalCriar;