import React, { ChangeEvent, useState } from "react"
import { Modal, Table } from "react-bootstrap-v5";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useEffect } from "react";
import clsx from "clsx";
import InputMonetario from "../../components/InputMonetario";
import Swal from "sweetalert2";
import { IPedidoCreditoPainel } from "../../../interfaces/responses/clientes/IPedidoCreditoPainel";

interface FuncionarioAlterarValorModalProps {
    toggleModal: () => void,
    exibirModal: boolean,
    pedidoCreditoPainel: IPedidoCreditoPainel
}

const ModalAltearValorFuncionario = ({ toggleModal, exibirModal, pedidoCreditoPainel }: FuncionarioAlterarValorModalProps) => {
    useEffect(() => {
        async function montarForm() {
            await formik.resetForm();

            if (pedidoCreditoPainel) {
                await formik.setValues({ valor: pedidoCreditoPainel.valor });
            }
        }

        montarForm();

        // eslint-disable-next-line
    }, [exibirModal, pedidoCreditoPainel]);

    const alterarValorValidacoes = Yup.object().shape({        
        valor: Yup.number()
                    .min(0, 'Valor deve ser maior ou igual a 0')
                    .required('Valor é obrigatório'),

    })
    
    const valoresIniciais = {
        valor: 0
    } as any;

    const formik = useFormik({
        initialValues: valoresIniciais,
        validationSchema: alterarValorValidacoes,
        onSubmit: (values) => {
            pedidoCreditoPainel.valor = values.valor
            Swal.fire({
                        icon: 'success',
                        text: `Valor alterado`,
                        showConfirmButton: false,
                        timer: 3000
                    })
            toggleModal();
            formik.resetForm();
        }
    });

    return <>
        <Modal size='lg' centered={true} show={exibirModal} onHide={toggleModal}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title titulo-modal">Adicionar Valor de Crédito</h5>
                    <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                        <i className="fas fa-times" style={{ color: '#5E6397' }}></i>
                    </div>
                </div>
                <div className="modal-body" >
                    <h4>Funcionário</h4>
                    <Table bordered striped >
                        <thead>
                            <tr>
                                <th className="p-2">Nome</th>
                                <th className="p-2">CPF</th>
                                <th className="p-2">Cartão</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="p-2">{pedidoCreditoPainel.nome}</td>
                                <td className="p-2">{pedidoCreditoPainel.cpf}</td>
                                <td className="p-2">{pedidoCreditoPainel.numero}</td>
                            </tr>
                        </tbody>
                    </Table>
                    <form className="row" 
                        autoComplete="off"
                        onSubmit={formik.handleSubmit}>
                        <div className="col-md-3">
                            <label htmlFor="form-valor" className="form-label required">Valor de crédito</label>
                            <InputMonetario 
                                handlechange={async (e: ChangeEvent<HTMLSelectElement>, value: number, maskedValue: string) => {
                                await formik.setFieldValue(e.target.name, value);
                                await formik.setFieldTouched(e.target.name);
                            }} name="valor" value={formik.values.valor} placeholder="R$ 9,99" className={clsx(
                                'form-control',
                                {
                                    'is-invalid': formik.touched.valor && formik.errors.valor,
                                },
                                {
                                    'is-valid': formik.touched.valor && !formik.errors.valor,
                                }
                            )} id="form-valor" />
                            {formik.touched.valor && formik.errors.valor && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.valor}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </form>
                </div>
                <div className="modal-footer" style={{ margin: '0 auto' }}>
                <button disabled={formik.isSubmitting} onClick={() => toggleModal()} type="button" className="btn btn-danger me-5"> CANCELAR </button>
                    <button 
                        disabled={!formik.isValid} 
                        type="submit"
                        className="btn btn-success ms-5"
                        onClick={formik.submitForm}>
                        SALVAR
                    </button>
                </div>
            </div>
        </Modal>
    </>
}

export default ModalAltearValorFuncionario;