import DataTable, { ConditionalStyles, PaginationOptions } from 'react-data-table-component';

interface GridPadraoType {
    colunas: any,
    itens: any[],
    tipo: string,
    conditionalRowStyles?: ConditionalStyles<any>[],
    paginar?: boolean
}

export default function GridPadrao({ colunas, itens, tipo, conditionalRowStyles, paginar = true }: GridPadraoType) {
    const paginationComponentOptions: PaginationOptions = {
        rowsPerPageText: `${tipo} por página`,
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos'
    };

    return <>
        {
            itens.length > 0 ? <DataTable responsive={true} conditionalRowStyles={conditionalRowStyles} paginationPerPage={20} highlightOnHover={true} columns={colunas} data={itens} dense pagination={paginar} paginationComponentOptions={paginationComponentOptions} /> : <h2 className="mt-5">Sem {tipo} para exibir</h2>
        }
    </>
}