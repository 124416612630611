import { ICriarMovimentoRequest } from "../interfaces/request/credenciados/ICriarMovimentoRequest";
import { IMovimentoRequest } from "../interfaces/request/credenciados/IMovimento";
import { IApiResult } from "../interfaces/responses/common/IApiResult";
import { IEnriquecimentoCnpj } from "../interfaces/responses/administrador/IEnriquecimentoCnpj";
import { IMovimento } from "../interfaces/responses/credenciados/IMovimento";
import { IRelatorioMovimento } from "../interfaces/responses/credenciados/IRelatorioMovimento";
import api from "./Api";
import { ICredenciadoGrupoEmpresarial } from "../interfaces/responses/credenciados/ICredenciadoGrupoEmpresarial";

class CredenciadoService {
    
    async obterMovimentos(idCredenciado: string): Promise<IApiResult<IMovimento[]>> {
        let { data: result } = await api.get<IApiResult<IMovimento[]>>(`credenciado/obter-movimentos?idCredenciado=${idCredenciado}`);

        return result;
    }

    async obterSaldoCartao(numeroCartao: string, senhaCartao: string): Promise<IApiResult<string>> {
        let { data: result } = await api.get<IApiResult<string>>(`credenciado/obter-saldo-cartao?numeroCartao=${numeroCartao}&senha=${senhaCartao}`);

        return result;
    }

    async obterGruposEmpresariais(): Promise<IApiResult<ICredenciadoGrupoEmpresarial[]>> {
        let { data: result } = await api.get<IApiResult<ICredenciadoGrupoEmpresarial[]>>(`credenciado/obter-grupos`);

        return result;
    }

    async obterUltimosMovimentos(idCredenciado: string): Promise<IApiResult<IMovimento[]>> {
        let { data: result } = await api.get<IApiResult<IMovimento[]>>(`credenciado/obter-ultimos-movimentos?idCredenciado=${idCredenciado}`);

        return result;
    }

    async relatorioMovimentoPorPeriodo
    (idCredenciado: string, autorizador: string, dataInicio: string, dataFim: string, idGrupoEmpresarial: string): Promise<IApiResult<IRelatorioMovimento>> {
        let { data: result } = await api.get<IApiResult<IRelatorioMovimento>>(`credenciado/relatorio-movimento-por-periodo`, 
        {
            params: {idCredenciado: idCredenciado, autorizador: autorizador, dataInicial: dataInicio, dataFinal: dataFim, idGrupoEmpresarialCliente:  idGrupoEmpresarial}
        });

        return result;
    }

    async confirmarMovimento(idCredenciado: string, movimentoRequest: IMovimentoRequest): Promise<IApiResult<any>> {
        let { data: result } = await api.put<IApiResult<any>>(`credenciado/confirmar-movimento/${idCredenciado}`, movimentoRequest);

        return result;
    }

    async estornarMovimento(idCredenciado: string, movimentoRequest: IMovimentoRequest): Promise<IApiResult<any>> {
        let { data: result } = await api.put<IApiResult<any>>(`credenciado/estornar-movimento/${idCredenciado}`, movimentoRequest);

        return result;
    }

    async criarMovimentoCartao(idCredenciado: string, criarMovimentoRequest: ICriarMovimentoRequest): Promise<IApiResult<IMovimento>> {
        let { data: result } = await api.post<IApiResult<IMovimento>>(`credenciado/criar-movimento-cartao/${idCredenciado}`, criarMovimentoRequest);
        return result;
    }
}

export default new CredenciadoService();