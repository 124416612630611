import React, { useState } from "react";
import { IAdminUsuario } from "../../../../interfaces/responses/administrador/IAdminUsuario";
import { Modal } from "react-bootstrap-v5";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { ITrocarSenhaUsuarioRequest } from "../../../../interfaces/request/administrador/ITrocarSenhaUsuarioRequest";
import AdministradorService from "../../../../services/AdministradorService";
import Swal from "sweetalert2";
import clsx from "clsx";
import UsuarioGrid from "../../../components/usuario/UsuarioGrid";


interface TrocarSenhaUsuarioModalProps {
    toggleModal: () => void,
    exibirModal: boolean,
    usuario: IAdminUsuario,
}

const TrocarSenhaUsuarioModal = ({ toggleModal, exibirModal, usuario }: TrocarSenhaUsuarioModalProps) =>{
    const [isLoading, setLoading] = useState<boolean>(false)
    
    const usuarioTrocarSenhaValidations = Yup.object().shape({
        senha: Yup.string().min(4, 'Senha deve possuir no mínimo 4 caracteres').required('Senha é obrigatória')
    })
    const valoresIniciais = {
        senha: ''
    } as any;

    const formik = useFormik({
        initialValues: valoresIniciais,
        validationSchema: usuarioTrocarSenhaValidations,
        onSubmit: async (values) => {
            try {
                setLoading(true);
                
                let redefinirSenha: ITrocarSenhaUsuarioRequest =
                {
                    senha: values.senha,
                    idUsuario: usuario.id
                }

                let data = await AdministradorService.redefinirSenha(redefinirSenha);
                
                Swal.fire({
                            icon: 'success',
                            text: `Senha redefinida com sucesso`,
                            showConfirmButton: true,
                        });
            
                limparFechar();

            } catch (error) {
                Swal.fire(
                     Array.isArray(error) ? error[0] : `Falha ao redefinir senha, tente novamente`
                );
            }
            finally {
                setLoading(false);
            } 
        }
    });

    function limparFechar() {
        formik.resetForm();
        toggleModal();
    }

    return (
    <>
        <Modal size='xl' centered={true} show={exibirModal} onHide={toggleModal}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title titulo-modal">Trocar Senha Usuário</h5>
                    <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                        <i className="fas fa-times" style={{ color: '#5E6397' }}></i>
                    </div>
                </div>
                <div className="modal-body">
                    <div className="card">
                        <div className="card-body">
                            <UsuarioGrid 
                                exibirBotaoDetalhe={false}
                                exibirTitulo={true}
                                usuarios={[usuario]}
                            />
                            <form className="row mt-2">
                                <h4>Redefinir Senha</h4>
                                <div className="row mt-2">
                                    <div className="col-md-4 mb-5">
                                    <label htmlFor="form-senha" className="form-label required">Senha</label>
                                        <input {...formik.getFieldProps('senha')} placeholder="Senha" type="text" className={clsx(
                                            'form-control',
                                            {
                                                'is-invalid': formik.touched.senha && formik.errors.senha,
                                            },
                                            {
                                                'is-valid': formik.touched.senha && !formik.errors.senha,
                                            }
                                        )} id="form-senha" />
                                        {formik.touched.senha && formik.errors.senha && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{formik.errors.senha}</span>
                                                </div>
                                            </div>
                                    )}  
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="modal-footer" style={{ margin: '0 auto' }}>
                    <button  onClick={() => toggleModal()} type="button" className="btn btn-secondary me-5"> VOLTAR </button>
                    <button 
                        className="btn btn-primary"
                        disabled={formik.isSubmitting || !formik.isValid} onClick={() => { formik.submitForm() }}
                    >
                        {!formik.isSubmitting && <> TROCAR SENHA </>}
                        {formik.isSubmitting && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                Salvando...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                </div>
            </div>
        </Modal>
    </>
    )
}

export default TrocarSenhaUsuarioModal;