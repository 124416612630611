import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap-v5";
import Swal from "sweetalert2";
import { useAuth } from "../../../contexts/AuthProvider";
import { IMovimento } from "../../../interfaces/responses/credenciados/IMovimento";
import CredenciadoService from "../../../services/CredenciadoService";
import BotaoAcaoGrid from "../../components/BotaoAcaoGrid";
import BotaoFiltro from "../../components/BotaoFiltro";
import ComboBox, { ComboboxItem } from "../../components/ComboBox";
import DropdownAcoesGrid from "../../components/DropdownAcoesGrid";
import GridPadrao from "../../components/GridPadrao";
import HeaderContent from "../../components/HeaderContent";
import ModalFiltros from "../../components/ModalFiltros";
import ReciboImpressao from "../../components/ReciboImpressao";
import MovimentosDetalhesModal from "../movimentos/MovimentosDetalhesModal";

import PagamentoModalCriar from "./PagamentoModalCriar";
import PagamentoModalConsultarSaldo from "./PagamentoModalConsultarSaldo";

export function Pagamentos(){
    const [exibirModalCriar, setExibirModalCriar] = useState<boolean>(false);
    const [ credenciadoSelecionadoDescricao, setCredenciado ] = useState<string>('')
    const [itens, setItens] = useState<ComboboxItem[]>([])
    const [item, setItem] = useState<any>()
    const { credenciados } = useAuth();
    const [loadingInicial, setLoadingInicial] = useState<boolean>(true)
    const [movimentos, setMovimentos] = useState<IMovimento[]>([])
    const [movimentoSelecionado, setMovimento] = useState<IMovimento | undefined>();
    const [exibirModalMovimento, setExibirModalMovimento] = useState<boolean>(false);

    useEffect(() => {
        carregarComboBox();
      }, [])

    function carregarComboBox(){
    credenciados?.map((p) => itens.push({ value: p.id, text: p.descricao }));
    setItens(itens);
    setLoadingInicial(false);
    if(credenciados != undefined)
    {
        setItem(credenciados[0].id)
        setCredenciado(credenciados[0].descricao)
        formikFiltro.submitForm()
    }
    }
      
    function toggleModalCriar(): void {
        if (exibirModalCriar) {
          formikFiltro.submitForm()
        }
    
        setExibirModalCriar(!exibirModalCriar);
    }

    function toggleModalMovimento(carregarDados?: boolean): void {
      if (exibirModalMovimento) {
        formikFiltro.submitForm()
      }
  
      setExibirModalMovimento(!exibirModalMovimento);
    }
    
    function onChangeComboBox(event: React.ChangeEvent<HTMLSelectElement>) {
        let id = event.target.value;
        setItem(id)
        setCredenciado(credenciados?.find(c => c.id === id)?.descricao || '');
      }

      const valoresIniciaisFiltro = {
        idCredenciado: '',
      } as any;
    
      const formikFiltro = useFormik({
        initialValues: valoresIniciaisFiltro,
        onSubmit: async () => {
          try {
            if (item === ""){
              setMovimentos([])
              return;
            }
    
            setLoadingInicial(true)
            const { data } = await CredenciadoService.obterUltimosMovimentos(item)
            setMovimentos(data)
          
          } catch (error) {
            Swal.fire({
              icon: 'error',
              title: 'Não foi possivel carregar os Movimentos',
              text: 'Atualize a pagina, caso o erro continue entre em contato com o administrador.',
              showConfirmButton: true,
            })
          } finally {
            setLoadingInicial(false)
          } 
        }
      });

      const colunas = [
        {
          name: 'Descrição',
          selector: (row: IMovimento) => row['descricao'],
          sortable: true,
          wrap: true,
        },
        {
          name: 'Valor',
          selector: (row: IMovimento) => row['valorFormatado'],
          sortable: true,
          wrap: true,
        },
        {
          name: 'Data',
          selector: (row: IMovimento) => row['dataFormatada'],
          sortable: true,
          wrap: true,
        },
        // {
        //   name: 'Autorizador',
        //   selector: (row: IMovimento) => row['autorizador'],
        //   sortable: true,
        //   wrap: true,
        // },
        {
          name: 'Status',
          selector: (row: IMovimento) => row['status'],
          sortable: true,
          wrap: true,
        },
        // {
        //   name: 'NSU',
        //   selector: (row: IMovimento) => row['nsu'],
        //   sortable: true,
        //   wrap: true,
        // },
        {
          name: 'Referencia',
          selector: (row: IMovimento) => row['referencia'],
          sortable: true,
          wrap: true,
        },
        {
          name: 'Ações',
          cell: (movimento: IMovimento) => {
            return (
              <div>
                <button
                    className='btn btn-sm btn-icon btn-secondary my-1'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                    title="Imprimir recibo"
                    onClick={() => {
                      setTimeout(() => {
                        setMovimento(movimento);
                        window.print()
                      }, 300);
                    }}
                >
                    <i style={{fontSize: 24}} className="bi bi-printer-fill"></i>
                </button>
                <button 
                    title="Detalhes"
                    style={{marginLeft: 8}}
                    className='btn btn-sm btn-icon btn-secondary my-1'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                    onClick={() => {
                      setMovimento(movimento);
                      setExibirModalMovimento(true);
                    }}
                >
                   <i style={{fontSize: 24}} className="bi bi-info-circle-fill"></i>
                </button>
                  {/* <BotaoAcaoGrid />
                  <DropdownAcoesGrid DropdownAcoesGridItens={
                    [
                      {
                      title: 'Detalhes', onClick: () => {
                        setMovimento(movimento);
                        setExibirModalMovimento(true);
                      }
                      },
                      {
                      title: 'Imprimir Recibo', onClick: () => {
                        setMovimento(movimento);
                        window.print()
                      }
                      }
                  ]
                  } /> */}
              </div>
            )
          },
        },
      ]
    return (
        <>
            <PagamentoModalCriar
            setMovimentoImpressao={setMovimento}
            toggleModal={toggleModalCriar}
            exibirModal={exibirModalCriar}
            titulo={`Criar Pagamento ${credenciadoSelecionadoDescricao}`}
            idCredenciado={item}
            />

            <MovimentosDetalhesModal toggleModal={toggleModalMovimento} exibirModal={exibirModalMovimento} movimento={movimentoSelecionado} titulo='Movimento Detalhe' />

            <HeaderContent title={'Pagamentos ' + credenciadoSelecionadoDescricao} >
            <BotaoFiltro />
             <ModalFiltros>
                <div className='mb-3'>
                    <label className='form-label fw-bold'>Credenciados</label>
                    <ComboBox className='form-select' items={itens} onChange={onChangeComboBox} value={item} />
                </div>
                
                <div className='d-flex justify-content-end'>
                    <button  type='reset' className='btn btn-sm btn-white btn-active-light-primary me-2' data-kt-menu-dismiss='true'>
                    Limpar
                    </button>

                    <button onClick={() => formikFiltro.submitForm()} type='submit' className='btn btn-sm btn-primary' data-kt-menu-dismiss='true'>
                    Aplicar
                    </button>
                </div>
             </ModalFiltros>
            <button onClick={() => {
              setExibirModalCriar(true)
            }}  className='btn btn-sm btn-primary'>
              <i className="icon-lg text-white fas fa-plus"></i>
              <span style={{ verticalAlign: 'middle' }}> Novo pagamento </span>
            </button>
            </HeaderContent>

            <div>
            {loadingInicial ? (
                    <Spinner animation='border' />
                ) : (
                    <div>
                    <GridPadrao colunas={colunas} tipo='Movimentos' itens={movimentos} />
                    </div>
                )}  
            </div>
            {movimentoSelecionado ? (
              <ReciboImpressao data={movimentoSelecionado}></ReciboImpressao>
            ) : <></>}
        </>
        
    )
}