import { useFormik } from "formik";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap-v5";
import Swal from "sweetalert2";
import { IAdminCredenciado } from "../../../../interfaces/responses/administrador/IAdminCredenciado";
import AdministradorService from "../../../../services/AdministradorService";
import GridPadrao from "../../../components/GridPadrao";
import HeaderContent from "../../../components/HeaderContent";
import InputCNPJ from "../../../components/InputCNPJ";
import PainelFiltro from "../../../components/PainelFiltro";
import clsx from "clsx";
import * as Yup from 'yup';
import { IAdminUsuario } from "../../../../interfaces/responses/administrador/IAdminUsuario";
import FormInputSelect, { FormInputMultiSelectItems } from "../../../components/FormInputSelect";
import UsuarioCriarModal from "../UsuarioCriarModal";
import UsuarioGrid from "../../../components/usuario/UsuarioGrid";

                  
export function Usuarios() {
  const [exibirModalCriar, setExibirModalCriar] = useState<boolean>(false)

  const [loadingInicial, setLoadingInicial] = useState<boolean>(true)
  const [usuarios, setUsuarios] = useState<IAdminUsuario[]>([])

  const [credenciadoItems, setCredenciadoItems] = useState<FormInputMultiSelectItems[]>([])
  const [clienteItems, setClienteItems] = useState<FormInputMultiSelectItems[]>([])
  
  useEffect(() => {
    carregarCredenciados()
    carrecarClientes()
  }, [])

  async function carregarCredenciados(){
      setLoadingInicial(true);

      const { data } = await AdministradorService.obterCredenciados('', '', '');

      data?.map((p) => credenciadoItems.push({ value: p.id, label: p.nomeFantasia }));
      
      setCredenciadoItems(credenciadoItems);
      setLoadingInicial(false);
  }

  async function carrecarClientes(){
    setLoadingInicial(true);

    const { data } = await AdministradorService.obterClientes('', '', '');

    data?.map((p) => clienteItems.push({ value: p.id, label: p.nomeFantasia }));
    
    setClienteItems(clienteItems);
    setLoadingInicial(false);
  }

  const valoresIniciaisFiltro = {
    idCredenciado: '',
    idCliente: '',
    login: ''
  } as any;
  
  const filtroValidacoes = Yup.object().shape({
    idCredenciado: Yup.string(),
    idCliente: Yup.string(),
    login: Yup.string(),
  });
  
  const formikFiltro = useFormik({
    initialValues: valoresIniciaisFiltro,
    validationSchema: filtroValidacoes,
    onSubmit: async ({idCredenciado, idCliente, login}) => {
      try {
       
        setLoadingInicial(true)
        
        const { data } = await AdministradorService.obterUsuarios(idCredenciado, idCliente, login)

        setUsuarios(data)

      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Não foi possivel carregar os usuários',
          text: 'Atualize a pagina, caso o erro continue entre em contato com o administrador.',
          showConfirmButton: true,
        })
      } finally {
        setLoadingInicial(false)
      } 
    }
  });

  function toggleModalCriar(carregarDados?: boolean): void {
    setExibirModalCriar(!exibirModalCriar);
  }


  return (
    <>
       <UsuarioCriarModal
        toggleModal={toggleModalCriar}
        exibirModal={exibirModalCriar}
      />
      
   

      <HeaderContent title="Usuários" >
        <button onClick={() => {
            setExibirModalCriar(true)
          }}  className='btn btn-sm btn-primary me-2'>
            <i className="icon-lg text-white fas fa-plus"></i>
            <span style={{ verticalAlign: 'middle' }}> Novo Usuário</span>
        </button>  
      </HeaderContent>

      <PainelFiltro>
              {
                  <>
                    <FormInputSelect 
                        disabled={formikFiltro.isSubmitting} 
                        values={credenciadoItems} 
                        formik={formikFiltro} 
                        className="col col-md-4" 
                        propName='idCredenciado' label='Credenciados' />

                    <FormInputSelect 
                        disabled={formikFiltro.isSubmitting} 
                        values={clienteItems} 
                        formik={formikFiltro} 
                        className="col col-md-4" 
                        propName='idCliente' label='Clientes' />
                    
                      <div className="col">
                        <label className='form-label fw-bold'>Login</label>
                        <input onChange={async (e) =>{ 
                              await formikFiltro.setFieldValue(e.target.name, e.target.value);
                              await formikFiltro.setFieldTouched(e.target.name);
                          }} 
                          value={formikFiltro.values.login}
                          name="login" 
                          type="text"
                          placeholder="Busque por lgoin"
                          className="form-control"
                          />
                      </div>
                  </>
              }
              {
                  <>
                      <button data-kt-menu-trigger='click'
                          data-kt-menu-placement='bottom-end'
                          data-kt-menu-flip='top-end'
                          className='btn btn-sm btn-flex btn-primary btn-active-primary fw-bolder me-3'
                          onClick={formikFiltro.submitForm}
                          disabled={formikFiltro.isSubmitting || !formikFiltro.isValid}>
                          <i className="icon-lg fa fa-filter"></i>
                          Filtrar
                      </button>
                  </>
              }
      </PainelFiltro>
      {loadingInicial ? (
          <Spinner animation='border' />
        ) : (
          <div>
            <UsuarioGrid 
              usuarios={usuarios}
              exibirBotaoDetalhe={true}
              exibirTitulo={false}
            />
          </div>
        )}
    
    </>
  )
}