import { FormikProps } from 'formik';
import React from 'react'

interface BotaoProps {
    formik: FormikProps<any>;
    className?: string;
    label?: string;
    labelSubmiting?: string;
}


export default function BotaoSubmitFormulario({
                                    formik,
                                    className = "btn btn-primary",
                                    label = "SALVAR",
                                    labelSubmiting = "SALVANDO..."
                                }: BotaoProps) 
{
    return <button 
            disabled={formik.isSubmitting || !formik.isValid || !formik.dirty} 
            onClick={() => { formik.submitForm() }} 
            type="button" 
            className={className}>
                {!formik.isSubmitting && <> {label} </>}
                {formik.isSubmitting && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                        {labelSubmiting}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                    )
                 }
        </button>
}