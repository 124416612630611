import React from 'react'

interface IModalHeaderProps{
    title: string;
    closeFunction: () => void
}
export default function ModalHeader(props : IModalHeaderProps){

    return <>
        <div className="modal-header">
            <h5 className="modal-title titulo-modal">{props.title}</h5>
            <div onClick={() => props.closeFunction()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                <i className="fas fa-times" style={{ color: '#5E6397' }}></i>
            </div>
        </div>
    </>
}