import React, { useEffect, useState } from "react"
import { Modal, Spinner } from "react-bootstrap-v5";
import { IAdminUsuario } from "../../../interfaces/responses/administrador/IAdminUsuario";
import GridPadrao from "../../components/GridPadrao";
import Swal from "sweetalert2";
import AdministradorService from "../../../services/AdministradorService";
import { IAdminCliente } from "../../../interfaces/responses/administrador/IAdminCliente";
import TrocarSenhaUsuarioModal from "../usuarios/detalhes/UsuarioTrocarSenhaModal";

interface ClienteModalUsuariosProps {
    toggleModal: () => void,
    exibirModal: boolean,
    cliente: IAdminCliente,
}

const ClienteModalUsuarios = ({ toggleModal, exibirModal, cliente }: ClienteModalUsuariosProps) => {
    const [isLoading, setLoading] = useState<boolean>(true)
    const [usuarioCredenciado, setCredenciado] = useState<IAdminUsuario[]>({} as IAdminUsuario[])
    const [exibirModalUsuarioVincular, setExibirModalUsuarioVincular] = useState<boolean>(false)

    const [exibirModalUsuarioSenha, setExibirModalUsuarioSenha] = useState<boolean>(false)
    const [usuarioSelecionado, setUsuario] = useState<IAdminUsuario>({} as IAdminUsuario)
    
    async function carregarCredenciado(){
        try {
            setLoading(true)
            const { data } = await AdministradorService.obterUsuariosCredenciado(cliente.id);
            setCredenciado(data);
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Não foi possivel carregar os dados',
                text: 'Atualize a pagina, caso o erro continue entre em contato com o administrador.',
                showConfirmButton: true,
              })
        }finally {
            setLoading(false)
        } 
    }
    const colunasCliente = [
        {
          name: 'CNPJ',
          selector: (row: IAdminCliente) => row['cnpj']?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5"),
          sortable: false,
          wrap: true,
        },
        {
          name: 'Nome',
          selector: (row: IAdminCliente) => row['nome'],
          sortable: false,
          wrap: true,
        },
        {
          name: 'Nome Fantasia',
          selector: (row: IAdminCliente) => row['nomeFantasia'],
          sortable: false,
          wrap: true,
        }
    ]

    const colunasUsuarios = [
        {
          name: 'Id',
          selector: (row: IAdminUsuario) => row['id'],
          sortable: false,
          wrap: true,
        },
        {
          name: 'Login',
          selector: (row: IAdminUsuario) => row['login'],
          sortable: false,
          wrap: true,
        },
        {
          name: 'Perfil',
          selector: (row: IAdminUsuario) => row['perfil'],
          sortable: false,
          wrap: true,
        },
        {
            name: 'Trocar Senha',
            cell: (usuario: IAdminUsuario) => {
              return (
                  <div className="p-1">
                    <button
                        title="Trocar Senha"
                        className='btn btn-sm btn-icon btn-secondary me-1'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                        onClick={() => {
                            setUsuario(usuario);
                            setExibirModalUsuarioSenha(true);
                        }}
                    >
                        
                        <i style={{fontSize: 24}} className="bi bi-lock-fill"></i>
                    </button>
                  </div>
              )
            },
          }
    ]

    function toggleModalUsuarioSenha(): void {
      setExibirModalUsuarioSenha(!exibirModalUsuarioSenha);
    }
      
    return <>
        <TrocarSenhaUsuarioModal 
            usuario={usuarioSelecionado}
            exibirModal={exibirModalUsuarioSenha}
            toggleModal={toggleModalUsuarioSenha}
        />
        <Modal size='xl' centered={true} show={exibirModal} onHide={toggleModal} onShow={carregarCredenciado}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title titulo-modal">Usuários Cliente</h5>
                    <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                        <i className="fas fa-times" style={{ color: '#5E6397' }}></i>
                    </div>
                </div>
                <div className="modal-body">
                {isLoading ? (
                <Spinner animation='border' />
                ) : (
                    <>
                    <h4>Cliente</h4>
                    <div>
                        <GridPadrao paginar={false} colunas={colunasCliente} tipo='Cliente' itens={[cliente]} />
                    </div>
                    <hr />
                    <h4>Usuários</h4>
                    <div>
                        <GridPadrao paginar={false} colunas={colunasUsuarios} tipo='Usuário' itens={usuarioCredenciado} />
                    </div>
                    <hr />
                    </>
                )}
                
                </div>
                <div className="modal-footer" style={{ margin: '0 auto' }}>
                    <button  onClick={() => toggleModal()} type="button" className="btn btn-secondary me-5"> VOLTAR </button>
                </div>
            </div>
        </Modal>
    </>
}

export default ClienteModalUsuarios;