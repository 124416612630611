import React, { ChangeEvent, useEffect, useState } from "react";
import { IMovimento } from "../../../interfaces/responses/credenciados/IMovimento";
import CredenciadoService from "../../../services/CredenciadoService";
import BotaoFiltro from "../../components/BotaoFiltro";
import HeaderContent from "../../components/HeaderContent";
import ModalFiltros from "../../components/ModalFiltros";
import Swal from 'sweetalert2'
import { Spinner } from "react-bootstrap-v5";
import GridPadrao from "../../components/GridPadrao";
import ComboBox, { ComboboxItem } from "../../components/ComboBox";
import { useAuth } from "../../../contexts/AuthProvider";
import { ICredenciado } from "../../../interfaces/responses/IAutenticacao";
import { useFormik } from "formik";
import BotaoAcaoGrid from "../../components/BotaoAcaoGrid";
import DropdownAcoesGrid from "../../components/DropdownAcoesGrid";
import MovimentosDetalhesModal from "./MovimentosDetalhesModal";
import InputDatePicker from "../../components/InputDatePicker";
import InputMonetario from "../../components/InputMonetario";
import clsx from "clsx";

export function Movimentos() {
  const [itens, setItens] = useState<ComboboxItem[]>([])
  const [loadingInicial, setLoadingInicial] = useState<boolean>(true)
  const [movimentos, setMovimentos] = useState<IMovimento[]>([])
  const { credenciados } = useAuth();
  const [ credenciadoSelecionadoDescricao, setCredenciado ] = useState<string>('')
  const [item, setItem] = useState<any>()
  const [exibirModal, setExibirModal] = useState<boolean>(false);
  const [movimentoSelecionado, setMovimento] = useState<IMovimento | undefined>();

  useEffect(() => {
    carregarComboBox();
  }, [])
  
  // async function buscarMovimentos(idCredenciado: string) {
    // try {
     
    
     
      
    // } catch (error) {
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Não foi possivel carregar os Promotores',
    //     text: 'Atualize a pagina, caso o erro continue entre em contato com o administrador.',
    //     showConfirmButton: true,
    //   })
    // } finally {
    //   setLoadingInicial(false)
    // }
  // }

  const colunas = [
    {
      name: 'Descrição',
      selector: (row: IMovimento) => row['descricao'],
      sortable: true,
      wrap: true,
    },
    {
      name: 'Valor',
      selector: (row: IMovimento) => row['valor'].toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}),
      sortable: true,
      wrap: true,
    },
    {
      name: 'Data',
      selector: (row: IMovimento) => row['dataFormatada'],
      sortable: false,
      wrap: true,
    },
    {
      name: 'Cartão',
      selector: (row: IMovimento) => row['ultimosDigitosCartao'],
      sortable: true,
      wrap: true,
    },
    {
      name: 'Status',
      selector: (row: IMovimento) => row['status'],
      sortable: true,
      wrap: true,
    },
    {
      name: 'Tipo',
      selector: (row: IMovimento) => row['tipo'],
      sortable: true,
      wrap: true,
    },
    {
      name: 'Referencia',
      selector: (row: IMovimento) => row['referencia'],
      sortable: true,
      wrap: true,
    },
    {
      name: 'Ações',
      cell: (movimento: IMovimento) => {
        return (
          <div>
            <button
                title="Detalhes"
                className='btn btn-sm btn-icon btn-secondary my-1'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
                onClick={() => {
                  setMovimento(movimento);
                  setExibirModal(true);
                }}
            >
                <i style={{fontSize: 24}} className="bi bi-info-circle-fill"></i>
            </button>
              {/* <BotaoAcaoGrid />
              <DropdownAcoesGrid DropdownAcoesGridItens={
                [{
                  title: 'Detalhes', onClick: () => {
                    setMovimento(movimento);
                    setExibirModal(true);
                  }
                }]
              } /> */}
          </div>
        )
      },
    },
  ]
  function onChangeComboBox(event: React.ChangeEvent<HTMLSelectElement>) {
    let id = event.target.value;
    setItem(id)
    setCredenciado(credenciados?.find(c => c.id === id)?.descricao || '');
  }

  function carregarComboBox(){
    credenciados?.map((p) => itens.push({ value: p.id, text: p.descricao }));
    setItens(itens);
    setLoadingInicial(false);
    if(credenciados != undefined)
    {
      setItem(credenciados[0].id)
      setCredenciado(credenciados[0].descricao)
      formikFiltro.submitForm()
    }
  }

  const valoresIniciaisFiltro = {
    idCredenciado: '',
    referencia: '',
    dataInicial: '',
    // dataFinal: '',
    tipo: '',
    status: '',
    valor: ''
  } as any;

  function limparForm(){
    formikFiltro.resetForm();
    formikFiltro.submitForm();
  }

  const formikFiltro = useFormik({
    initialValues: valoresIniciaisFiltro,
    onSubmit: async ({referencia, dataInicial, tipo, status, valor}) => {
      try {
        if (item === ""){
          setMovimentos([])
          return;
        }

        setLoadingInicial(true)
        const { data } = await CredenciadoService.obterMovimentos(item)
        
        let temp = data.filter(c => 
          (!dataInicial || c.dataFormatada >= dataInicial.toLocaleDateString('pt-br')) &&
          (!referencia || c.referencia.toLowerCase().includes(referencia.toLowerCase())) &&
          (!status || c.status == status) &&
          (!tipo || c.tipo == tipo) &&
          (!valor || c.valor == valor));

        setMovimentos(temp)
      
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Não foi possivel carregar os Movimentos',
          text: 'Atualize a pagina, caso o erro continue entre em contato com o administrador.',
          showConfirmButton: true,
        })
      } finally {
        setLoadingInicial(false)
      } 
    }
  });

  function toggleModal(): void {
    if (exibirModal) {
      formikFiltro.submitForm()
    }
    setExibirModal(!exibirModal);

    if (exibirModal) {
      setTimeout(() => {
        setMovimento(undefined)
      }, 300)
    }
  }
  return (
  <>
   <MovimentosDetalhesModal toggleModal={toggleModal} exibirModal={exibirModal} movimento={movimentoSelecionado} titulo='Movimento Detalhe' />
    <HeaderContent title={'Movimentos ' + credenciadoSelecionadoDescricao}>
        <BotaoFiltro />
        <ModalFiltros>
          <div className='mb-3'>
            <label className='form-label fw-bold'>Credenciados</label>
            <ComboBox className='form-select' items={itens} onChange={onChangeComboBox} value={item} />
          </div>

          <div className='mb-3'>
            <label className='form-label fw-bold'>Referência</label>
            <input {...formikFiltro.getFieldProps('referencia')} placeholder="referencia" type="text" className='form-control' id="form-referencia" />
          </div>
          <div className='mb-3'>
            <label className='form-label fw-bold'>Data Inicial</label>
            <InputDatePicker selected={formikFiltro.values.dataInicial} onChange={async (date: any) => {
                              if (!date) {
                                  await formikFiltro.setFieldValue('dataInicial', undefined);
                              } else {
                                  await formikFiltro.setFieldValue('dataInicial', date);
                              }

                              await formikFiltro.setFieldTouched('dataInicial');

                          }} className='form-control' id="form-dataInicial" />
          </div>
          <div className="mb-3">
            <InputMonetario 
                    handlechange={async (e: ChangeEvent<HTMLSelectElement>, value: number, maskedValue: string) => {
                        e.target.focus()
                    await formikFiltro.setFieldValue(e.target.name, value);
                    await formikFiltro.setFieldTouched(e.target.name);
                }} name="valor" value={formikFiltro.values.valor} placeholder="R$ 9,99" className={clsx(
                    'form-control'
                )} id="form-valor" />
                {formikFiltro.touched.valor && formikFiltro.errors.valor && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formikFiltro.errors.valor}</span>
                        </div>
                    </div>
                )}
          </div>
         
          <div className="mb-3">
            <label className='form-label fw-bold'>Tipo</label>
            <select
              id="form-filtro-tipo" className='form-select'
              {...formikFiltro.getFieldProps('tipo')}
              onChange={async e => await formikFiltro.setFieldValue('tipo', e.target.value)}  >

              <option value="">Todos</option>
              <option value='Debito'>Débito</option>
              <option value='Credito'>Crédito</option>
            </select>
          </div>

          <div className="mb-3">
            <label className='form-label fw-bold'>Status</label>
            <select
              id="form-filtro-status" className='form-select '
              {...formikFiltro.getFieldProps('status')}
              onChange={async e => await formikFiltro.setFieldValue('status', e.target.value)}  >

              <option value="">Todos</option>
              <option value='Pendente'>Pendente</option>
              <option value='Confirmado'>Confirmado</option>
              <option value='Cancelado'>Cancelado</option>
              <option value='AguardandoPagamento'>Aguardando Pagamento</option>
              <option value='Estornado'>Estornado</option>
            </select>
          </div>
        
          <div className='d-flex justify-content-end'>
            <button  onClick={() => limparForm()} type='reset' className='btn btn-sm btn-white btn-active-light-primary me-2' data-kt-menu-dismiss='true'>
              Limpar
            </button>

            <button onClick={() => formikFiltro.submitForm()} type='submit' className='btn btn-sm btn-primary' data-kt-menu-dismiss='true'>
              Aplicar
            </button>
          </div>
        </ModalFiltros>
    </HeaderContent>
    {loadingInicial ? (
        <Spinner animation='border' />
      ) : (
        <div>
          <GridPadrao colunas={colunas} tipo='Movimentos' itens={movimentos} />
        </div>
      )}
  
  </>
  )
  }