import React, { useState } from "react"
import { Modal } from "react-bootstrap-v5";
import { IAdminCredenciado } from "../../../interfaces/responses/administrador/IAdminCredenciado";
import GridPadrao from "../../components/GridPadrao";
import Swal from "sweetalert2";
import { IAdminCliente } from "../../../interfaces/responses/administrador/IAdminCliente";

interface ClienteModalDetalheProps {
    toggleModal: () => void,
    exibirModal: boolean,
    cliente: IAdminCliente,
}

const CredenciadoModalDetalhe = ({ toggleModal, exibirModal, cliente }: ClienteModalDetalheProps) => {
    const [isLoading, setLoading] = useState<boolean>(false)

    const colunasCliente = [
        {
          name: 'CNPJ',
          selector: (row: IAdminCliente) => row['cnpj']?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5"),
          sortable: false,
          wrap: true,
        },
        {
          name: 'Nome',
          selector: (row: IAdminCliente) => row['nome'],
          sortable: false,
          wrap: true,
        },
        {
          name: 'Nome Fantasia',
          selector: (row: IAdminCliente) => row['nomeFantasia'],
          sortable: false,
          wrap: true,
        },
        {
            name: 'Codigo',
            selector: (row: IAdminCliente) => row['codigo'],
            sortable: false,
            wrap: true,
        },
        {
            name: 'Grupo',
            selector: (row: IAdminCliente) => row['nomeGrupoEmpresarial'],
            sortable: false,
            wrap: true,
        },
        {
            name: 'Nome Impresso Cartão',
            selector: (row: IAdminCliente) => row['nomeImpressoCartao'],
            sortable: false,
            wrap: true,
            grow: 2
        },
        {
            name: 'Produto',
            selector: (row: IAdminCliente) => row['nomeProduto'],
            sortable: false,
            wrap: true,
        },
        {
            name: 'Ativo',
            selector: (row: IAdminCliente) => row['ativo'] ? 'SIM' : 'NÃO',
            sortable: false,
            wrap: true,
        },
      ]

    const colunasEndereco = [
        {
            name: 'Cep',
            selector: (row: IAdminCliente) => row['cep'],
            sortable: false,
            wrap: true,
        },
        {
            name: 'Logradouro',
            selector: (row: IAdminCliente) => row['logradouro'],
            sortable: false,
            wrap: true,
        },
        {
            name: 'Número',
            selector: (row: IAdminCliente) => row['numero'],
            sortable: false,
            wrap: true,
        },
        {
            name: 'Complemento',
            selector: (row: IAdminCliente) => row['complemento'],
            sortable: false,
            wrap: true,
        },
        {
            name: 'Bairro',
            selector: (row: IAdminCliente) => row['bairro'],
            sortable: false,
            wrap: true,
        },
        {
            name: 'Cidade',
            selector: (row: IAdminCliente) => row['cidade'],
            sortable: false,
            wrap: true,
        },
        {
            name: 'UF',
            selector: (row: IAdminCliente) => row['uf'],
            sortable: false,
            wrap: true,
        }
    ]

    function copiar(data: string){
        navigator.clipboard.writeText(data)
        Swal.fire({
                position: "center",
                color: '#FFFFFF',
                background: '#009EF7',
                toast: true,
                html: "<strong>C O P I A D O !</strong>",
                width: '15em',
                showConfirmButton: false,
                timer: 2000
            })
    }

    return <>
        <Modal size='xl' centered={true} show={exibirModal} onHide={toggleModal}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title titulo-modal">Cliente detalhes</h5>
                    <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                        <i className="fas fa-times" style={{ color: '#5E6397' }}></i>
                    </div>
                </div>
                <div className="modal-body">
                    <h4>Cliente</h4>
                    <div>
                        <GridPadrao paginar={false} colunas={colunasCliente} tipo='Funcionário' itens={[cliente]} />
                    </div>
                    <hr />
                    <h4>Endereço</h4>
                    <div>
                        <GridPadrao paginar={false} colunas={colunasEndereco} tipo='Funcionário' itens={[cliente]} />
                    </div>
                    <hr />
                    {/* <h4>Api Key</h4>
                    <div className="col-12" style={{wordWrap: "break-word"}}>
                        <p>{credenciado.publicKey}</p>
                        <button className="btn btn-primary" onClick={() => copiar(credenciado.publicKey)}>Copiar</button>
                    </div> */}
                </div>
                <div className="modal-footer" style={{ margin: '0 auto' }}>
                    <button  onClick={() => toggleModal()} type="button" className="btn btn-secondary me-5"> VOLTAR </button>
                </div>
            </div>
        </Modal>
    </>
}

export default CredenciadoModalDetalhe;